<script setup>
import { ref, computed } from 'vue'
import cloneDeep from 'lodash.clonedeep'

import { useExcavationStore } from '@/stores/excavation'
import { useAuthStore } from '@/stores/auth'

import SoilsListItem from './soils-list-item.vue'
import SoilEditor from '../soil-editor/soil-editor.vue'

const props = defineProps({
  excavation: {
    type: Object,
    default: () => null
  }
})

const emits = defineEmits(['add-handler'])

const excavationStore = useExcavationStore()
const authStore = useAuthStore()

const loading = ref(false)
const activeModal = ref(false)
const activeSoil = ref(null)

const disabled = computed(() => {
  const geologist = props.excavation?.geologist

  return !authStore.hasDataPermissions(geologist)
})

const samplesList = computed(() => {
  return excavationStore.samplesList || []
})

const groundwaterList = computed(() => {
  return excavationStore.groundwaterList || []
})

const soilsList = computed(() => {
  const clone = cloneDeep(excavationStore.soilsList)

  return (
    clone
      ?.sort((a, b) => {
        if (a.foot_d > b.foot_d) return 1
        if (a.foot_d < b.foot_d) return -1
        return 0
      })
      ?.map((s, i) => {
        s.index = i + 1
        s.prevDepth = clone[i - 1]?.foot_d || 0
        s.samples = samplesList.value.filter((e) => {
          return e.selection_from_d >= s.prevDepth && e.selection_from_d < s.foot_d
        })
        s.groundwater = groundwaterList.value.filter((e) => {
          return e.level_d >= s.prevDepth && e.level_d < s.foot_d
        })
        return s
      }) || []
  )
})

const editSoil = (soil) => {
  if (disabled.value) return
  activeSoil.value = soil
  activeModal.value = !activeModal.value
}

const toggleEditor = () => {
  activeModal.value = !activeModal.value

  if (!activeSoil.value) {
    activeSoil.value = null
  }
}
</script>

<template>
  <div v-loading="loading" class="soils-list">
    <div v-if="!disabled" class="soils-list__btn-wrapper">
      <s-button icon="fa-plus" type="success-plain" @click="emits('add-handler', 'soils')">
        Добавить слой
      </s-button>
    </div>
    <div class="soils-list-content">
      <soils-list-item
        v-for="item in soilsList"
        :key="item.id"
        :item="item"
        @click="editSoil(item)"
      />
    </div>
    <soil-editor
      v-if="activeSoil && activeModal"
      :excavation="excavation"
      :soil="activeSoil"
      :is-visible="activeModal"
      :soils-list="soilsList"
      @toggle="toggleEditor"
    />
  </div>
</template>

<style lang="scss">
.soils-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__btn-wrapper {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--card-bg);

    & .s-button {
      width: 100%;
    }
  }

  &-content {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
