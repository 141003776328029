<script setup>
import { computed } from 'vue'
import { useMq } from 'vue3-mq'
import { useMainStore } from '@/stores/main'
import { getImageSrc } from '@/utils/common'

const mq = useMq()

const mainStore = useMainStore()

const currentTheme = computed(() => {
  return mainStore.theme.split('-')[0]
})
const closeModal = () => {
  mainStore.toggleInfoModal()
}
</script>

<template>
  <s-modal
    :title="'Информация о системе'"
    :show="mainStore.isShowInfoModal"
    :fullscreen="mq.current !== 'lg'"
    @close="closeModal"
  >
    <div style="padding-right: 0.5rem">
      <div class="soilbox-info-modal__logo">
        <img
          :src="getImageSrc(`assets/images/logo/soilbox_logotype_${currentTheme}_default.png`)"
          alt=""
        />
      </div>
      <div class="soilbox-info-modal__content">
        <s-text>
          <span class="soilbox-info-modal__app">Soilbox</span> – интеллектуальная система сбора,
          обработки и хранения первичных полевых данных инженерно-геологических изысканий.
        </s-text>

        <div class="soilbox-info-modal__block">
          <s-text>
            Основная цель Soilbox – автоматизация задач сбора и обработки данных при полевых
            работах, в рамках которой обеспечивается:
          </s-text>
          <ul role="list">
            <li>
              полный контроль данных от скважины до отчета, в том числе в субподрядных организациях;
            </li>
            <li>сокращение времени обработки первичных данных и выпуска отчета в 2-3 раза;</li>
            <li>полная прозрачность полевых работ;</li>
            <li>передача данных в едином обменном формате JSON.</li>
          </ul>
        </div>
        <div class="soilbox-info-modal__block">
          <s-text>
            В основе Soilbox лежит концепция облачного решения, с помощью которого осуществляется
            следующее:
          </s-text>
          <ul>
            <li>данные из поля попадают в облачное хранилище;</li>
            <li>сотрудники в офисе имеют мгновенный доступ к облаку;</li>
            <li>выгрузка отчетной документации и аналитики онлайн.</li>
          </ul>
        </div>
        <s-text v-if="mainStore.version">
          Текущая версия приложения: <b>{{ mainStore.version }}</b
          >.
        </s-text>
        <div>
          <s-text v-if="mainStore.version">
            Лицензия: <b>{{ mainStore.license }}</b
            >.
          </s-text>
          <s-text>
            Максимальное количество пользователей: <b>{{ mainStore.usersCount }}</b
            >.
          </s-text>
        </div>
        <s-text type="secondary">© ООО "СОИЛБОКС", 2025</s-text>
        <a class="soilbox-info-modal__link" href="https://soilbox.app/" target="_blank">
          <s-icon color="var(--primary)" name="up-right-from-square" />
          Перейти на сайт Soilbox
        </a>
      </div>
    </div>
    <template #footer>
      <div>
        <s-button type="default" stretch @click="closeModal"> Понятно</s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss" scoped>
.soilbox-info-modal {
  &__app {
    font-weight: 700;
  }

  &__content {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    & img {
      width: 200px;
    }
  }

  &__block {
    color: var(--caption);
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    gap: 4px;
    font-weight: 400;

    & ul {
      list-style-type: disc !important;
      padding-left: 32px !important;

      & li {
        list-style-type: disc !important;
      }
    }
  }

  &__link {
    display: flex;
    gap: 8px;
    text-decoration: none;
    color: var(--primary);
    align-items: center;
  }
}
</style>
