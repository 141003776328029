<script setup>
import { ref } from 'vue'
import { useMapStore } from '@/stores/map'
import { saveDataToLS } from '@/utils/browser'

import MapButton from './map-button.vue'

const mapStore = useMapStore()

const show = ref(false)

const settingsList = [
  {
    id: 'objects',
    title: 'Отображаемые объекты',
    settings: [
      { id: 'clusters', label: 'Кластеры' },
      { id: 'processes', label: 'ИГ процессы' }
      // { id: 'planFactLocation', label: 'Показывать план. / факт.' }
    ]
  }
]

const changeHandler = () => {
  saveDataToLS('mapSettings', mapStore.settings)
}
</script>

<template>
  <div class="map-settings">
    <div v-tooltip="'Настройки'">
      <s-pop-tip placement="left-start" v-model="show" width="220" trigger="click">
        <map-button icon="gear" :active="show" size="lg" />

        <template #content>
          <div class="map-settings-modal">
            <div class="map-settings-modal__header">
              <s-title type="small">Настройки карты</s-title>
            </div>
            <div class="map-settings-modal__content">
              <ul class="map-settings-modal__groups">
                <li class="map-settings-modal__group" v-for="group in settingsList" :key="group.id">
                  <div class="map-settings-modal__group-title">
                    <s-text>{{ group.title }}</s-text>
                  </div>
                  <ul class="map-settings-modal__group-items">
                    <li
                      class="map-settings-modal__group-item"
                      v-for="item in group.settings"
                      :key="item.id"
                    >
                      <s-switch
                        v-model="mapStore.settings[item.id]"
                        :label="item.label"
                        @change="changeHandler"
                      />
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </template>
      </s-pop-tip>
    </div>
  </div>
</template>

<style lang="scss">
.map-settings {
  position: absolute;
  top: 16px;
  right: 72px;

  & svg {
    width: 22px;
    height: 22px;
  }
}

.map-settings-modal {
  &__header {
    margin-bottom: 16px;
  }

  &__groups {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__group {
    &-title {
      margin-bottom: 16px;
    }

    &-items {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }
  }
}
</style>
