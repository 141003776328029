<script setup>
import { computed } from 'vue'
import { useMainStore } from '@/stores/main'
import { getImageSrc } from '@/utils/common'

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const mainStore = useMainStore()

const firstWordOfFormula = computed(() => {
  return props.data.formula.split(' ')[0]
})

const restOfFormula = computed(() => {
  return props.data.formula.split(' ').slice(1).join(' ')
})

const imagePath = computed(() => {
  const theme = mainStore.theme === 'dark-theme' ? `dark` : `light`
  return getImageSrc(`assets/images/wiki-tip/${props.data.image}_${theme}.svg`)
})
</script>

<template>
  <div class="wiki-tip-content">
    <s-title type="small">{{ data.title }}</s-title>
    <s-text>{{ data.description }}</s-text>
    <s-text v-if="data.formula">
      <span class="wiki-tip-content__formula">{{ firstWordOfFormula }}</span>
      {{ restOfFormula }}
    </s-text>
    <div v-if="data.image" class="wiki-tip-content__image-wrapper">
      <img class="wiki-tip-content__image" :src="imagePath" alt="" />
    </div>
    <s-text v-if="data.source" class="info">
      {{ data.source }}
    </s-text>
  </div>
</template>

<style lang="scss" scoped>
.wiki-tip-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: auto;
  max-height: 660px;

  &__formula {
    font-weight: 700;
  }

  &__image-wrapper {
    display: flex;
    justify-content: center;
  }

  &__image {
    border-radius: 8px;
    border: 1px solid var(--main-bg);
    max-width: 100%;

    @include desktop {
      height: 288px;
      width: auto;
    }

    @include phones {
      width: 100%;
    }
  }
}
</style>
