<script setup>
import { ref, computed, watch } from 'vue'
import { useMq } from 'vue3-mq'

import { useExcavationStore } from '@/stores/excavation'
import { useAuthStore } from '@/stores/auth'

import { layerTabs } from './config'
import { checkExcavationDepth } from '@/utils/requests/excavation'

import ExcavationStart from '../excavation-start/excavation-start.vue'
import CreateGuide from './create-guide/create-guide.vue'
import SoilsList from './soils-list/soils-list.vue'
import SamplesList from '../excavation-samples/samples-list.vue'
import SamplesEditor from '../excavation-samples/samples-editor.vue'
import GroundwaterList from '../excavation-groundwater/groundwater-list.vue'
import GroundwaterEditor from '../excavation-groundwater/groundwater-editor.vue'
import CoreboxesList from '../excavation-coreboxes/coreboxes-list.vue'
import CoreboxesEditor from '../excavation-coreboxes/coreboxes-editor.vue'

const props = defineProps({
  excavation: {
    type: Object,
    required: true
  },
  object: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['update-handler'])

const mq = useMq()
const excavationStore = useExcavationStore()
const authStore = useAuthStore()

const activeTab = ref(1)
const startModal = ref(false)
const guideModal = ref(false)
const editorVisible = ref(false)
const activeModal = ref(null)
const activeObject = ref(null)

const disabled = computed(() => {
  const geologist = props.excavation?.geologist

  return !authStore.hasDataPermissions(geologist)
})

const notStarted = computed(() => {
  return props.excavation.status === 1 || !props.excavation.status
})

const soilsList = computed(() => {
  return excavationStore.soilsList || []
})

watch(
  () => soilsList.value,
  (newValue) => {
    checkExcavationDepth(newValue, props.excavation)
  },
  { deep: true }
)

const addHandler = (value) => {
  switch (value) {
    case 'soils':
      guideModal.value = true
      break
    default:
      activeModal.value = value
      toggleEditor()
      break
  }
}

const toggleEditor = () => {
  editorVisible.value = !editorVisible.value

  if (!editorVisible.value) {
    activeObject.value = null
    activeModal.value = null
  }
}

const editHandler = ({ model, value }) => {
  activeObject.value = value
  activeModal.value = model
  editorVisible.value = !editorVisible.value
}

const componentsMap = {
  1: SoilsList,
  2: SamplesList,
  3: GroundwaterList,
  4: CoreboxesList
}

const componentsMapEditors = {
  samples: SamplesEditor,
  groundwater: GroundwaterEditor,
  coreboxes: CoreboxesEditor
}

const changeTab = (id) => {
  activeTab.value = id
}

const currentActiveComp = computed(() => {
  return componentsMap[activeTab.value]
})

const currentActiveEditorComp = computed(() => {
  return componentsMapEditors[activeModal.value]
})

const wellContentInfo = computed(() => {
  const availableData = []

  if (excavationStore?.soilsList?.length > 0) {
    availableData.push('слои')
  }
  if (excavationStore?.samplesList?.length > 0) {
    availableData.push('пробы')
  }
  if (excavationStore?.groundwaterList?.length > 0) {
    availableData.push('УГВ')
  }
  if (excavationStore?.coreboxesList?.length > 0) {
    availableData.push('керн')
  }

  if (availableData.length > 0) {
    return `В скважине имеются добавленные данные: ${availableData.join(', ')}. Для просмотра этих данных измените статус скважины`
  }

  return ''
})
</script>

<template>
  <div class="excavation-layers" streched>
    <s-tabs
      :tabs="layerTabs"
      v-if="!notStarted"
      :value="activeTab"
      indent
      :small="mq.current !== 'lg'"
      @click="changeTab"
    >
      <s-tab
        v-for="tab in layerTabs"
        :id="tab.value"
        :key="tab.id"
        :name="tab.title"
        :active="activeTab"
      />
    </s-tabs>

    <component
      :is="currentActiveComp"
      v-if="!notStarted"
      :excavation="excavation"
      @add-handler="addHandler($event)"
      @edit-handler="editHandler"
    />
    <s-button v-else-if="!disabled" type="success-plain" @click="startModal = !startModal">
      Начать бурение
    </s-button>
    <s-alert v-if="notStarted && wellContentInfo" icon="circle-info" type="primary">
      <s-text>{{ wellContentInfo }}</s-text>
    </s-alert>
    <excavation-start
      v-if="startModal"
      :is-visible="startModal"
      :excavation="excavation"
      @toggle="startModal = !startModal"
      @update-handler="emits('update-handler')"
    />
    <create-guide
      v-if="guideModal"
      :is-visible="guideModal"
      :excavation="excavation"
      @toggle="guideModal = !guideModal"
    />
    <component
      :is="currentActiveEditorComp"
      v-if="excavation && currentActiveEditorComp"
      :excavation="excavation"
      :is-visible="editorVisible"
      :editor-name="activeModal"
      :active-object="activeObject"
      :soils-list="soilsList"
      @toggle="toggleEditor"
    />
  </div>
</template>

<style lang="scss">
.excavation-layers {
  display: grid;
  grid-gap: 1rem;
}
</style>
