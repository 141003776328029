<script setup>
import { getLayerInfoFromData } from '@/utils/soils'
import { orderBy } from 'natural-orderby'

import SampleType from '@/modules/app/data/excavation/components/excavation-samples/components/sample-type.vue'
import GroundwaterType from '@/modules/app/data/excavation/components/excavation-groundwater/components/groundwater-type.vue'

const props = defineProps({
  item: {
    type: Object,
    required: true
  }
})

const getDescription = () => {
  return getLayerInfoFromData(props.item)
}

const sortItems = (items, type) => {
  const sortMap = {
    samples: 'selection_from_d',
    groundwater: 'level_d'
  }

  return orderBy(items, [(v) => v[sortMap[type]]], ['asc'])
}
</script>

<template>
  <div
    v-if="item"
    class="soils-list-item"
    :class="{
      samples: item.samples.length,
      water: item.groundwater.length
    }"
  >
    <div class="soils-list-item__row">
      <s-text style="white-space: nowrap">
        {{ item.index }}
      </s-text>
      <s-text class="soils-list-item--subinfo" type="info">
        {{ item.prevDepth?.toFixed(1) }}
      </s-text>
      <s-text type="info">
        {{ item.foot_d?.toFixed(1) }}
      </s-text>
      <s-text>
        {{ getDescription() }}
      </s-text>
    </div>
    <div v-if="item.samples.length || item.groundwater.length" class="soils-list-item__types">
      <div v-if="item.samples.length" class="soils-list-item__sample">
        <sample-type
          v-for="sample in sortItems(item.samples, 'samples')"
          :key="sample.id"
          :sample="sample"
          type="simple"
        />
      </div>
      <div v-if="item.groundwater.length" class="soils-list-item__water">
        <groundwater-type
          v-for="groundwater in sortItems(item.groundwater, 'groundwater')"
          :key="groundwater.id"
          :groundwater="groundwater"
          type="simple"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.soils-list-item {
  background-color: var(--bg);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  cursor: pointer;
  padding: 0.75rem;
  display: grid;
  grid-template-columns: auto;
  justify-content: space-between;
  grid-gap: 1rem;
  align-items: center;

  &.samples {
    grid-template-columns: auto;
  }

  & .info {
    white-space: nowrap;
  }

  &.water {
    grid-template-columns: auto;
  }

  &.water.samples {
    grid-template-columns: auto;
  }

  &__sample {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__types {
    grid-column: 3;
    display: flex;
    gap: 40px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1rem 3rem 3rem auto;
    grid-gap: 1rem;
    align-items: center;
  }

  &__samples {
    display: grid;
    grid-gap: 0.5rem;
  }

  &__water {
    display: grid;
    grid-gap: 0.5rem;
  }
}

@include phones {
  .soils-list-item {
    grid-template-columns: 1rem 2.5rem auto !important;
    grid-auto-rows: minmax(auto, auto);
    align-items: start !important;
    justify-content: start;

    &__types {
      grid-column: 3;
      display: flex;
      gap: 40px;
    }

    &__sample {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__water {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }

    &__row {
      grid-column: 1 / 4 !important;
      grid-template-columns: 1rem 3rem auto;
      grid-row: 1 !important;
    }

    &--subinfo {
      display: none !important;
    }
  }
}
</style>
