import cloneDeep from 'lodash.clonedeep'
import router from '@/router'
import db from '@/libs/db'

import { useObjectsStore } from '@/stores/objects'
import { useServicesStore } from '@/stores/services'
import { useMainStore } from '@/stores/main'
import { useRequests } from '@/composables'

import { deleteItem } from '@/utils/requests'
import appGlobalProperties from '@/main'

export const editObjectHelper = async (clone) => {
  const objectsStore = useObjectsStore()
  const mainStore = useMainStore()
  const { patchRequest } = useRequests()

  try {
    const data = cloneDeep(clone)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      const serverData = cloneDeep(data)
      serverData.id = data.server_id

      delete serverData.server_id

      await patchRequest(`objects/${data.server_id}/`, serverData)

      if (Object.keys(objectsStore.changedSelectFields).length > 0) {
        setServiceEmployeesFields()
        objectsStore.setField('changedSelectFields', {})
      }
    }

    await db.updateObject('objects', data)
    objectsStore.setField('activeObject', data)

    const title = 'Редактирование'
    const message = `Проект "${data.title_short}" успешно отредактирован`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'success'
    })
  } catch (error) {
    const title = 'Создание'
    const message = `Ошибка при редактировании проекта ${error}`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'error'
    })
  }
}

export const loadObject = async (clone) => {
  const objectsStore = useObjectsStore()
  const { getRequest } = useRequests()

  try {
    const data = cloneDeep(clone)
    const object = await getRequest(`objects/${data.server_id}/`)

    if (!object) return

    const cloneData = cloneDeep(object)
    cloneData.server_id = cloneData.id
    delete cloneData.id

    await db.updateObject('objects', cloneData)

    objectsStore.setField('activeObject', cloneData)
  } catch (e) {
    throw new Error(e)
  }
}

const relatedServiceFields = {
  geologists: 'users',
  bore_machines: 'bore_machines',
  bore_masters: 'bore_masters'
}

const setServiceEmployeesFields = () => {
  const servicesStore = useServicesStore()
  const objectsStore = useObjectsStore()

  for (const [key, value] of Object.entries(objectsStore.changedSelectFields)) {
    const items = servicesStore[relatedServiceFields[key]].filter((field) =>
      value.includes(field.id)
    )
    servicesStore.setService([`object_${relatedServiceFields[key]}`, items])
  }
}

export const deleteObjectHelper = async (id) => {
  const url = `objects/${id}/`

  await deleteItem(url, 'objects', goBack)
}

const goBack = () => {
  router.push('/app/data/objects/list')
}
