import rDate from 'ritm-date'

export const parseDate = (date, format = 'DD.MM.YYYY') => (date ? rDate(date).format(format) : '-')
export const parseDatetime = (date) => (date ? rDate(date).format('DD.MM.YYYY HH:mm') : '-')

export const getFormattedFromToDate = (dateFrom, dateTo, type) => {
  const format = 'DD.MM.YY'
  dateFrom = parseDate(dateFrom, format)

  if (type === 101) {
    dateTo = parseDate(dateTo, format)
    return `${dateFrom} / ${dateTo}`
  }

  return dateFrom
}

export const getObjectDatesLabel = (date_start, date_end, isObjectItem) => {
  if (!date_start && !date_end) {
    if (isObjectItem) {
      return '- / -'
    }
    return 'Сроки не указаны'
  }

  let startLabel
  let endLabel

  let noDateValue

  if (isObjectItem) {
    noDateValue = '-'
  } else {
    noDateValue = 'Не указано'
  }

  startLabel = date_start ? rDate(date_start).format('DD.MM.YY') : noDateValue
  endLabel = date_end ? rDate(date_end).format('DD.MM.YY') : noDateValue

  return `${startLabel} - ${endLabel}`
}
