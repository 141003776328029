import { useMapStore } from '@/stores/map'
import { useObjectsStore } from '@/stores/objects'
import { useExcavationStore } from '@/stores/excavation'
import { useReconStore } from '@/stores/recon'

import { getGeojson } from '@/utils/map/common'
import { flyToFeatures } from '@/utils/map/fly'

export const centerMapOnActiveFeatures = (type, mapgl, dataType = 'all') => {
  if (!type) return

  const mapStore = useMapStore()
  const objectsStore = useObjectsStore()
  const excavationStore = useExcavationStore()
  const reconStore = useReconStore()

  let data

  switch (type) {
    case 'objects': {
      const { objectsList } = objectsStore

      if (!objectsList || !objectsList.length) return

      data = getGeojson(
        objectsList.map((p) => ({
          ...p,
          x: p.centroid_lat || 0,
          y: p.centroid_lon || 0
        }))
      )
      break
    }
    case 'data-points': {
      const active = excavationStore.active || reconStore.active
      const { excavationsList, reconsList } = objectsStore
      const excavs = excavationsList || []
      const recons = reconsList || []

      if (active) {
        data = getGeojson([
          {
            x: active.lat || active.lat_plan,
            y: active.lon || active.lon_plan
          }
        ])
      } else {
        let list

        switch (dataType) {
          case 'excavations':
            list = excavs
            break
          case 'recon':
            list = recons
            break
          default:
            list = [...excavs, ...recons]
        }

        data = getGeojson(
          list.map((data) => ({
            ...data,
            x: data.lat || data.lat_plan,
            y: data.lon || data.lon_plan
          }))
        )
      }
    }
  }

  if (data) {
    flyToFeatures(mapgl, data)
  }

  mapStore.setCenterMapOnActive(false)
}
