<script setup>
import { ref, computed, watch, inject, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import cloneDeep from 'lodash.clonedeep'

import { useObjectsStore } from '@/stores/objects.js'
import { useEditorStore } from '@/stores/work-planning/editor.js'
import { useMapStore } from '@/stores/map.js'

import { loadImages } from '@/utils/map/images'
import { saveDataToLS, getDataFromLS } from '@/utils/browser'
import { throttle } from '../../helpers/index.js'
import { ViewController, RequestController } from './controllers'

import MapEditorList from './map-editor-list.vue'

const props = defineProps({
  mapgl: {
    type: Object,
    required: true
  }
})

const editorStore = useEditorStore()
const objectsStore = useObjectsStore()
const mapStore = useMapStore()
const route = useRoute()
const $confirm = inject('$confirm')

const viewController = ref(null)
const requestController = ref(null)

const isLoading = ref(false)
const alertText = 'Используйте режим создания для добавления новых данных'
const isShowAlert = ref(true)

const steps = ref({
  0: {
    iconColor: 'var(--success)',
    textColorType: 'success',
    text: 'Включить режим создания'
  },
  1: {
    iconColor: 'var(--white-text)',
    textColorType: 'text',
    text: 'Выключить режим создания',
    type: 'error'
  },
  2: {
    iconColor: 'var(--white-text)',
    textColorType: 'text',
    text: 'Завершить и сохранить',
    type: 'success'
  }
})

const getCurrentStep = computed(() => {
  return steps.value[editorStore.currentStep]
})

watch(
  () => editorStore.recentChangesPoints,
  () => {
    viewController.value?.updateRecentPoints()
  },
  { deep: true }
)

watch(
  () => editorStore.mapEditorPopupOpen,
  (newValue) => {
    if (!newValue) {
      editorStore.currentPopup.remove()
      editorStore.currentPopup = null
      viewController.value.updateRecentPoints()
      viewController.value.updatePoints()
    }
  }
)

watch(
  () => mapStore.activeTool,
  (newValue, oldValue) => {
    if (oldValue === 'map-editor' && !newValue) {
      editorStore.setCurrentStep(0)
      editorStore.setCreateMode(false)
    }
  }
)

watch(
  () => editorStore.mapEditorPopupOpen,
  (newValue) => {
    if (!newValue) {
      addMouseMoveHandler()
      viewController.value.addDistanceAssets()
    }
  }
)

watch(
  () => objectsStore?.excavationsList,
  (newValue) => {
    const clone = cloneDeep(newValue)
    editorStore.setClonePoints(clone)
    viewController.value.updatePoints()
  }
)

const clickHandler = async () => {
  if (mapStore.activeTool && mapStore.activeTool !== 'map-editor') return
  mapStore.setActiveTool('map-editor')

  if (editorStore.currentStep === 2) {
    try {
      isLoading.value = true
      await requestController.value.sendExcavations()
      editorStore.setCurrentStep(0)
      viewController.value.updateRecentPoints()
      viewController.value.updatePoints()
      mapStore.setActiveTool(null)
      editorStore.setCreateMode(false)
      return
    } catch (e) {
      throw new Error('Произошла ошибка при сохранении выработок')
    } finally {
      isLoading.value = false
    }
  } else {
    editorStore.setCurrentStep(editorStore.currentStep + 1)
  }

  editorStore.toggleCreateMode()
}

const deleteExcavation = async (point, listId) => {
  if (listId === 'recent') {
    editorStore.removePoint(point.point_id, listId)

    if (editorStore?.recentChangesPoints.length === 0) {
      viewController.value.resetEditor()
    }
  } else {
    try {
      isLoading.value = true
      await requestController.value.deleteExcavation(point)
    } catch (e) {
      console.log(e)
    } finally {
      isLoading.value = false
    }
  }
}

watch(
  () => editorStore.createModeOn,
  (newValue) => {
    if (newValue) {
      addMouseMoveHandler()
    } else {
      viewController.value.clearDistanceAssets()
    }
  }
)

watch(
  () => editorStore.isShowDistanceTip,
  (newValue) => {
    if (newValue) {
      addMouseMoveHandler()
    } else {
      props.mapgl.off('mousemove', throttledFnMouseMove)
      viewController.value.clearDistanceAssets()
    }
  }
)

const addMouseMoveHandler = () => {
  if (!editorStore.isShowDistanceTip) return
  props.mapgl.on('mousemove', throttledFnMouseMove)
}

let throttledFnMouseMove = null

const cancelEditor = () => {
  const title = 'Выход из режима создания'
  const message =
    'У вас есть несохраненные данные. Вы уверены, что хотите выйти из режима создания?'

  $confirm(message, title, {
    confirmButtonText: 'Выйти',
    cancelButtonText: 'Отменить',
    confirmButtonClass: 'error'
  }).then(async () => {
    viewController.value.resetEditor()
  })
}

const reloadMapAssets = async () => {
  viewController.value.addMapAssets()

  const isShowDistanceTip = getDataFromLS('isShowDistanceTip')

  if (isShowDistanceTip) {
    addMouseMoveHandler()
    editorStore.setIsShowDistanceTip(isShowDistanceTip)
  }

  viewController.value.updatePoints()
}

const filterText = ref('')

const SSearchChangeHandler = (value) => {
  if (value === null) {
    filterText.value = ''
    return
  }

  filterText.value = value
}

const closeAlert = () => {
  isShowAlert.value = false
  saveDataToLS('isShowedAlert', true)
}

const isEmptyPoints = computed(() => {
  if (objectsStore?.excavationsList?.length || editorStore?.recentChangesPoints?.length) {
    return false
  }

  return true
})

onMounted(async () => {
  requestController.value = new RequestController(route)
  viewController.value = new ViewController(
    props.mapgl,
    throttledFnMouseMove,
    requestController.value.editExcavation.bind(requestController.value)
  )

  throttledFnMouseMove = throttle(viewController.value?.calculateCursorDistance, 25)

  const isShowedAlert = getDataFromLS('isShowedAlert')

  if (isShowedAlert) {
    isShowAlert.value = false
  }

  isLoading.value = true
  viewController.value.updatePoints()

  props.mapgl.on('style.load', async () => {
    await loadImages(props.mapgl)
    reloadMapAssets()
    isLoading.value = false
  })

  reloadMapAssets()
  isLoading.value = false
})
</script>

<template>
  <div class="map-editor" :class="{ activeEditor: editorStore.currentStep !== 0 }">
    <div class="map-editor__container" v-loading="isLoading">
      <s-button
        :iconColor="getCurrentStep?.iconColor"
        :textColorType="getCurrentStep?.textColorType"
        icon="plus"
        :type="getCurrentStep?.type"
        @click="clickHandler"
        >{{ getCurrentStep?.text }}</s-button
      >
      <s-button
        v-if="editorStore.currentStep !== 0 && editorStore.recentChangesPoints.length"
        @click="cancelEditor"
        >Отменить все изменения</s-button
      >
      <s-alert
        v-if="isShowAlert"
        @close-alert="closeAlert"
        textSmall
        closable
        icon="info-circle"
        type="primary"
        :title="alertText"
      />
      <div v-if="!isEmptyPoints" class="map-editor__list-filter">
        <s-search-block :filter="filterText" @change="SSearchChangeHandler" />
      </div>
      <s-text v-if="isEmptyPoints"> Список точек пуст </s-text>
      <map-editor-list @delete="deleteExcavation" :filterText="filterText" v-else />
    </div>
  </div>
</template>

<style lang="scss">
.editor__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  inset: 0;
  background: black;
  opacity: 0.3;
  z-index: 1;
}

.map-editor {
  &__container {
    gap: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: calc(100vh - 256px);
    overflow: hidden;
  }

  &__list-filter {
    width: 100%;

    & .s-input__input {
      outline: none;
    }
  }

  & .s-button {
    width: 100%;
    flex-shrink: 0;
  }

  &.active {
    background: var(--primary);
  }
}
</style>
