<script setup>
import { computed } from 'vue'

import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  warning: {
    type: Boolean,
    default: false
  },
  activeId: {
    type: [Number, String],
    default: null
  },
  vuelidate: {
    type: Object,
    required: false
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['change'])

const hasError = computed(() => {
  if (props.soilInterval.to <= props.soilInterval.from) {
    return true
  }

  if (props.vuelidate?.[props.field.title]?.$error) {
    return true
  }

  return false
})

const getInfo = () => {
  const from = Number(props.soilInterval.from) ? Number(props.soilInterval.from)?.toFixed(1) : '0.0'
  const to = Number(props.soilInterval?.to) ? Number(props.soilInterval.to)?.toFixed(1) : '0.0'
  const depth = `${from} - ${to} м`
  const power = (
    Number(props.soilInterval.to)?.toFixed(1) - Number(props.soilInterval.from)?.toFixed(1)
  )?.toFixed(1)

  return `Слой от ${depth}, мощность слоя - ${power}`
}

const changeActiveCollapse = (id) => {
  emits('change', id)
}
</script>

<template>
  <s-collapse-item
    @change="changeActiveCollapse"
    :active="activeId"
    :id="field.id"
    :title="'Глубина залегания'"
  >
    <template v-slot:header-stats>
      <s-icon
        v-if="hasError"
        name="circle-exclamation"
        size="sm"
        color="var(--error)"
        class="header-stats__icon"
      />
      <s-text :type="hasError ? 'error' : source.foot_d ? 'success' : 'default'">
        {{ source.foot_d ? '1 / 1' : '0 / 1' }}
      </s-text>
    </template>
    <div class="soil-depth">
      <s-input-renderer :vuelidate="vuelidate" :field="field" :source="source" />
      <s-text class="soil-depth__text" :type="hasError ? 'error' : 'default'">
        {{ getInfo() }}
      </s-text>
    </div>
  </s-collapse-item>
</template>

<style lang="scss">
.soil-depth {
  display: grid;
  grid-gap: 1rem;
  row-gap: 0.5rem;

  &__text {
    font-size: 0.8em !important;
  }
}
</style>
