<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { objectDesktopNavigationLinks } from './config/index.js'

import { useObjectsStore } from '@/stores/objects.js'
import { useAuthStore } from '@/stores/auth.js'

const props = defineProps({
  navWidth: {
    type: String,
    required: false
  }
})

const route = useRoute()
const router = useRouter()
const objectsStore = useObjectsStore()
const authStore = useAuthStore()

const animationsEnd = () => {
  objectsStore.setField('isCollapseAnimationEnd', true)

  setTimeout(() => {
    objectsStore.setField('isCollapseAnimationEnd', false)
  }, 0)
}

const desktopNav = ref(null)

onMounted(() => {
  if (!desktopNav.value) return
  desktopNav.value.addEventListener('animationend', animationsEnd)

  if (route.name === 'object-data') {
    isDataPage.value = true
  }

  if (route.query.type === 'excavations') {
    activeLink.value = 'excavations'
  } else if (route.query.type === 'recon') {
    activeLink.value = 'recon'
  } else {
    activeLink.value = 'all'
  }
})

onBeforeUnmount(() => {
  if (!desktopNav.value) return
  objectsStore.setField('isCollapseAnimationEnd', false)
  objectsStore.setField('isCollapsed', false)
  desktopNav.value.removeEventListener('animationend', animationsEnd)
})

watch(
  () => route.name,
  (newValue) => {
    if (newValue === 'object-data') {
      isDataPage.value = true
    } else {
      isDataPage.value = false
    }
  }
)

watch(
  () => route.query.type,
  (newValue) => {
    activeLink.value = newValue
  }
)

const isDataPage = ref(false)

const clickSubmenuLink = (id) => {
  activeLink.value = id
  router.push({ name: 'object-data', query: { ...route.query, type: id } })
}

const activeLink = ref(null)

const isAdmin = computed(() => {
  return authStore?.user?.is_admin
})

const checkIsAdmin = (link) => {
  if (!link?.admin) {
    return true
  } else {
    return isAdmin.value
  }
}
</script>

<template>
  <ul
    ref="desktopNav"
    class="object-desktop-navigation"
    :class="{
      collapsed: objectsStore.isCollapsed
    }"
    :style="{ animationFillMode: objectsStore.isCollapsed ? 'forwards' : 'none' }"
  >
    <template :key="link.routeName" v-for="link in objectDesktopNavigationLinks">
      <li v-if="checkIsAdmin(link)" class="object-desktop-navigation__link-wrapper">
        <div v-if="link.beta" class="object-desktop-navigation__beta">
          <s-text small> BETA </s-text>
        </div>
        <router-link
          v-if="objectsStore.isCollapsed"
          class="object-desktop-navigation__link"
          :class="{
            'router-link-exact-active': route.name === link.routeName
          }"
          :to="{ name: link.routeName, query: { type: link.queryType } }"
          exact
          v-tooltip.right="link.tooltipText"
        >
          <s-dropdown
            triggerEvent="hover"
            stick-to="right"
            v-if="link.submenu"
            list
            :max-width="256"
          >
            <s-icon :name="link.icon" />
            <template #dropdown>
              <s-dropdown-item
                :active="activeLink"
                v-for="item in link.submenu"
                :id="item.queryType"
                :key="item.queryType"
                :type="item.type"
                :icon="item.icon"
                :label="item.title"
                :divided="item.divided"
                :disabled="item.disabled"
                :close-by-click="item?.closeByClick"
                @click="clickSubmenuLink"
              />
            </template>
          </s-dropdown>
          <s-icon v-else :name="link.icon" />
        </router-link>
        <router-link
          v-else
          class="object-desktop-navigation__link"
          :class="{
            'router-link-exact-active': route.name === link.routeName
          }"
          :to="{ name: link.routeName, query: { type: link.queryType } }"
          exact
        >
          <s-icon :name="link.icon" />
          <Transition name="fade">
            <s-text
              :class="{
                active: route.name === link.routeName
              }"
              >{{ link.title }}</s-text
            >
          </Transition>
        </router-link>
        <Transition name="submenu-fade">
          <ul
            class="object-desktop-navigation__submenu"
            :class="{ 'hide-submenu': objectsStore.isCollapsed || !isDataPage }"
            v-if="link.submenu"
          >
            <li
              :class="{
                active:
                  (!route.query.type && item.queryType === 'all') ||
                  item.queryType === route.query.type,
                'hide-submenu-item': objectsStore.isCollapsed
              }"
              v-for="item in link.submenu"
              :key="item.title"
            >
              <router-link
                :to="{ name: link.routeName, query: { ...route.query, type: item.queryType } }"
                exact
              >
                <s-text>{{ item.title }}</s-text>
              </router-link>
            </li>
          </ul>
        </Transition>
      </li>
    </template>
  </ul>
</template>

<style lang="scss">
.dark-theme {
  --navigation-bg-color: var(--main-bg-transp);
}

.light-theme {
  --navigation-bg-color: var(--card-bg);
}

.object-desktop-navigation.collapsed {
  animation-name: collapsePanel;
}

.object-desktop-navigation:not(.collapsed) {
  animation-name: expandPanel;
}

@keyframes expandPanel {
  from {
    width: 88px;
  }
  to {
    width: v-bind('props.navWidth');
  }
}

@keyframes collapsePanel {
  from {
    width: v-bind('props.navWidth');
  }
  to {
    width: 88px;
  }
}

.s-dropdown__body {
  & .s-dropdown-item {
    &:hover:not(.disabled) {
      background: rgba(39, 145, 237, 0.15) !important;
      border-radius: 0 !important;

      & .s-text {
        color: var(--primary) !important;
      }
    }
  }
}

.object-desktop-navigation {
  white-space: nowrap;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  width: v-bind('props.navWidth');
  background: var(--navigation-bg-color);
  animation-duration: 0.4s;
  border-right: 1px solid var(--section-border-color);
  height: 100%;
  animation-fill-mode: forwards;

  & > li {
    margin-bottom: 8px;
    padding-left: 24px;

    & a {
      position: relative;
    }

    & svg {
      justify-self: center;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__beta {
    padding: 0 6px;
    border-radius: 8px;
    background: var(--gradient);
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 8px;
    z-index: 1;

    .s-text.small {
      font-size: 10px !important;
    }
  }

  &__link {
    display: grid;
    padding: 12px 0;
    gap: 12px;
    align-items: center;
    text-decoration: none;
    grid-template-columns: 36px auto;
    height: 48px;

    &-wrapper {
      position: relative;
    }

    & .s-dropdown {
      justify-content: center;
    }

    &.router-link-active {
      &:first-child:after {
        position: absolute;
        top: 50%;
        left: -24px;
        transform: translateY(-50%);
        width: 4px;
        height: 40px;
        border-radius: 0px 4px 4px 0px;
        content: '';
        background: var(--primary);
      }

      & .s-text {
        color: var(--primary);
      }
    }

    & svg {
      align-self: flex-end;
      font-size: 24px;
    }
  }

  & li.active {
    & .s-text {
      color: var(--primary);
    }
  }

  & .router-link-active {
    color: var(--primary) !important;

    & svg {
      color: var(--primary) !important;
    }
  }

  &__submenu {
    padding-left: 24px;
    margin-top: 12px;
    margin-bottom: 12px;
    margin-left: 17px;
    border-left: 1px solid var(--bg);
    max-height: 500px;
    overflow: hidden;
    transition: all 0.4s ease-out;

    & a {
      text-decoration: none;
      padding: 10px 16px;
      display: block;
      opacity: 1;
      transition: opacity 0.2s linear;
    }

    & .active {
      background: rgba(39, 145, 237, 0.15);
      border-radius: 8px;
      color: var(--primary);
    }

    &.hide-submenu {
      max-height: 0;
      transition: all 0.4s ease-out;
      margin-top: 0;
      margin-bottom: 0;
      transition-delay: 0.3s;

      & .hide-submenu-item {
        opacity: 0;
        transition: opacity 0.4s ease-out;
      }
    }
  }
}
</style>
