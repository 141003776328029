<script setup>
import { computed } from 'vue'
import apexchart from 'vue3-apexcharts'

import { useMainStore } from '@/stores/main'
import { roundValue } from '@/utils/numbers'

const props = defineProps({
  type: {
    type: String,
    default: 'area'
  },
  options: {
    type: Object,
    required: true
  }
})

const mainStore = useMainStore()

const parseValue = (value) => {
  if (value === 0) return 0
  if (value < 0) return 0

  return value ? `${roundValue(value, 0)} п.м` : '-'
}

const chartOptions = computed(() => {
  const theme = mainStore.theme === 'dark-theme' ? 'dark' : 'light'

  return {
    series: props.options.series,
    chart: {
      type: 'area',
      sparkline: {
        enabled: true
      },
      height: 160,
      toolbar: {
        show: false
      },
      parentHeightOffset: 0,
      stacked: false
    },
    legend: {
      show: false
    },
    toolbar: {
      show: false
    },
    stroke: {
      curve: 'straight'
    },
    dataLabels: {
      enabled: false
    },
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: '11px',
        fontFamily: undefined
      },
      theme,
      y: [
        {
          formatter: parseValue
        },
        {
          formatter: parseValue
        }
      ]
    },
    colors: ['#2792ed', '#55b77e'],
    xaxis: {
      labels: {
        show: false
      },
      axisTicks: {
        show: false
      },
      categories: props.options.labels
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    grid: {
      show: true,
      yaxis: {
        lines: {
          show: true
        }
      },
      xaxis: {
        lines: {
          show: true
        }
      },
      borderColor: '#90A4AE20'
    }
  }
})
</script>

<template>
  <div class="chart-component">
    <apexchart :options="chartOptions" :series="options.series" :height="144" />
  </div>
</template>

<style lang="scss">
.apexcharts-tooltip {
  background: var(--card-bg) !important;
  border: 1px solid var(--main-bg);
  color: var(--text) !important;
  border-radius: 8px;
  box-shadow: 0 0 8px -4px #000 !important;

  &-title {
    background: var(--main-bg) !important;
    border-bottom: 1px solid var(--main-bg) !important;
    font-weight: bold;
  }
}
</style>
