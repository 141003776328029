<script setup>
import { usePrintStore } from '@/stores/print'

import ObjectItem from '@/modules/app/data/objects/list/components/object-item.vue'
import ProductionChart from '@/modules/app/data/objects/object/modules/dashboard-page/components/production-chart/production-chart.vue'
import ObjectStats from '@/modules/app/data/objects/object/modules/dashboard-page/components/data-widget/data-widget.vue'

const printStore = usePrintStore()
</script>

<template>
  <div class="dp-object-data">
    <div class="dp-object-data__block dp-object-data__block--main">
      <object-item is-print-page :object="printStore.dashboard.active" />
      <production-chart
        v-if="printStore.dashboard.chartsData"
        :data="printStore.dashboard.chartsData"
      />
    </div>
    <div class="dp-object-data__block">
      <object-stats type="excavations" :stats="printStore.dashboard.excavStatsData" />
      <object-stats type="recon" :stats="printStore.dashboard.reconStatsData" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dp-object-data {
  display: flex;
  gap: 24px;

  & .production-chart {
    width: auto !important;
    flex-shrink: 1;
    border: none;
    padding: 0;
  }

  & .data-widget--card {
    width: auto;
    border: none;
    padding: 0;
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    &:first-child {
      width: 55%;
    }

    &:last-child {
      flex: 1;
    }
  }

  &__chart-mock {
    background-color: var(--bg);
    border-radius: 8px;
    border: 1px solid var(--main-bg);
    height: 96px;
    margin-top: 16px;
  }
}
</style>
