import { useServicesStore } from '@/stores/services'

export const soilTypes = () => {
  const servicesStore = useServicesStore()

  return servicesStore?.soil?.soil_types
}

export const soilType = (source) => {
  return soilTypes()?.find((e) => e.id === source?.soil_type)
}
