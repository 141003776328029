<script setup>
import { computed, ref } from 'vue'
import { useRitmDate } from '@/composables'
import InclusionModal from './modals/inclusions-modal.vue'
import SecondaryItem from './common/soil-secondary-item.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const rDate = useRitmDate()

const isVisible = ref(false)
const initSource = ref(props.source)
const activeInclusion = ref(null)

const inclusions = computed(() => {
  return props.source.inclusions || []
})

const toggleModal = () => {
  activeInclusion.value = null
  isVisible.value = !isVisible.value
}

const openInclusion = (item) => {
  activeInclusion.value = item
  isVisible.value = !isVisible.value
}

const addHandler = (item) => {
  if (!props.source.inclusions?.length) {
    initSource.value.inclusions = []
  }

  const exist = !!initSource.value.inclusions?.find((e) => e.id === item.id)
  const lastId = initSource.value.inclusions?.reduce((a, c) => {
    if (c.id > a) return c.id
    return a
  }, 0)

  if (exist) {
    initSource.value.inclusions = initSource.value.inclusions.map((e) => {
      if (e.id === item.id) {
        return item
      }
      return e
    })
  } else {
    const offline_id = rDate(new Date()).format('iso')

    initSource.value.inclusions.push({
      id: lastId + 1,
      offline_id: offline_id + lastId,
      ...item
    })
  }

  emits('trigger-changed', true)
}
const removeHandler = (item) => {
  if (!item.id) return

  const index = initSource.value.inclusions?.findIndex((e) => e.id === item.id)

  if (index < 0) return

  initSource.value.inclusions.splice(index, 1)
}
</script>

<template>
  <div class="create-guide-soil-inclusions">
    <secondary-item
      v-for="item in inclusions"
      :key="item.id"
      :item="item"
      :source="source"
      type="inclusion"
      @click="openInclusion(item)"
    >
      {{ item.d0 }}
    </secondary-item>
    <s-button @click="toggleModal">Добавить включение</s-button>
    <inclusion-modal
      :is-visible="isVisible"
      :soil-interval="soilInterval"
      :inclusion="activeInclusion"
      @toggle="toggleModal"
      @add-handler="addHandler"
      @remove-handler="removeHandler"
      :is-edit="false"
    />
  </div>
</template>

<style>
.create-guide-soil-inclusions {
  display: grid;
  grid-gap: 1.5rem;
}
</style>
