<script setup>
import { computed, onMounted, ref } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { useMq } from 'vue3-mq'
import { useServicesStore } from '@/stores/services'

const props = defineProps({
  source: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const mq = useMq()
const servicesStore = useServicesStore()

const initSource = ref(props.source)
const showComment = ref(false)

const colors_main = computed(() => {
  const colors = cloneDeep(servicesStore?.soil?.colors_main || [])

  return (
    colors.map((e) => {
      e.title = e.title + e.end1
      return e
    }) || []
  )
})

const colors_optional = computed(() => {
  return servicesStore?.soil?.colors_optional || []
})

onMounted(() => {
  if (initSource.value.color_main) {
    emits('trigger-changed')
  }

  showComment.value = !!initSource.value.color_comments
})

const hndlSelect = (val) => {
  emits('trigger-changed')
  initSource.value.color_main = val

  if (!val) {
    initSource.value.color_optional = null
  }
}

const changeSelect = (value) => {
  initSource.value.color_optional = value
}
</script>

<template>
  <div class="soil-color-select">
    <s-title type="small">Цвет</s-title>
    <div class="soil-color-select-selection">
      <s-select
        v-if="initSource.color_main"
        :value="initSource.color_optional"
        placeholder="Дополнение"
        clearable
        :options="colors_optional"
        :searchable="mq.current === 'lg'"
        :filterable="mq.current === 'lg'"
        @change="changeSelect"
      >
      </s-select>
      <s-select
        :value="initSource.color_main"
        :options="colors_main"
        clearable
        :searchable="mq.current === 'lg'"
        :filterable="mq.current === 'lg'"
        placeholder="Выберите основной цвет"
        @change="hndlSelect"
      >
      </s-select>
    </div>
    <s-button
      v-if="!showComment"
      class="input-renderer__comment-btn"
      link
      @click="showComment = true"
    >
      Дополнить описание
    </s-button>
    <s-input
      v-if="showComment"
      v-model="initSource.color_comments"
      label="Комментарий к цвету"
      type="textarea"
    />
  </div>
</template>

<style lang="scss">
.soil-color-select {
  display: grid;
  grid-gap: 0.5rem;

  &-selection {
    display: flex;
    gap: 1rem;
  }
}
</style>
