<script setup>
import { computed } from 'vue'
import router from '@/router'

import { useAuthStore } from '@/stores/auth'
import { useSyncStore } from '@/stores/sync'

const authStore = useAuthStore()
const syncStore = useSyncStore()

const hasUnsyncedData = computed(() => {
  return syncStore.changesList.length
})

const logout = () => {
  if (hasUnsyncedData.value) {
    syncStore.setField('isShowUnsyncedModal', true)
    return
  }

  authStore.authLogout()
  router.push('/login')
}
</script>

<template>
  <section class="profile-loggout">
    <s-button stretch @click="logout"> Выйти из приложения </s-button>
  </section>
</template>

<style lang="scss"></style>
