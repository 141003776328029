<script setup>
import { computed } from 'vue'
import { useMainStore } from '@/stores/main'

import MobileImageLoading from './mobile-image-loading.vue'
import MobileNavbar from './mobile-navbar.vue'

const mainStore = useMainStore()

const isIOs = computed(() => {
  return mainStore.deviceOS === 'iOS'
})
</script>

<template>
  <div :class="`mobile-bar ${isIOs ? 'mobile-bar--ios' : ''}`">
    <mobile-image-loading />
    <mobile-navbar />
    <div v-if="!mainStore.isOnline || mainStore.noSyncMode" class="mobile-bar__offline">
      <span>{{ !mainStore.isOnline ? 'Оффлайн-режим' : 'Требуется синхронизация' }}</span>
    </div>
  </div>
</template>

<style lang="scss">
.mobile-bar {
  width: 100%;
  background-color: var(--main-bg);
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 100;

  &--ios {
    margin-bottom: 0.75rem;
  }

  &__offline {
    background-color: var(--secondary-bg);
    color: var(--plug);
    font-size: 10px;
    height: 14px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      padding-bottom: 2px;
    }
  }
}

.light-theme {
  .mobile-bar {
    border-top: 1px solid var(--main-bg);
    background-color: var(--card-bg);

    &__offline {
      color: var(--caption);
    }
  }
}
</style>
