import { useResourcesStore } from '@/stores/resources'
import { useMapStore } from '@/stores/map'
import { useRequests } from '@/composables'

import { getUuid } from '@/utils/browser'
import appGlobalProperties from '@/main'

export const saveVideos = async ({ server_id, files }) => {
  const mapStore = useMapStore()
  const resourcesStore = useResourcesStore()

  const [lon, lat] =
    mapStore.userLocation && Array.isArray(mapStore.userLocation)
      ? mapStore.userLocation
      : [null, null]

  const h = mapStore.userAbs ?? null

  await Promise.allSettled(
    files.map(async (video) => {
      const data = {
        server_id,
        video,
        uuid: getUuid(),
        video_coords: {
          lon,
          lat,
          h
        }
      }

      try {
        await saveVideoOnServer(data)
        resourcesStore.updateItem('resourcesLoading', {
          id: video.title,
          uploaded: true,
          type: 'video'
        })
      } catch (error) {
        appGlobalProperties.$notify({
          message: `${error}`,
          type: 'error'
        })
      }
    })
  )
}

export const saveVideoOnServer = async ({ video, uuid, server_id, video_coords }) => {
  try {
    const { postRequest } = useRequests()
    const { file, title } = video

    const data = {
      title,
      video: file,
      video_coords,
      date_front: new Date(),
      uuid
    }

    await postRequest(`excavations/${server_id}/videos/`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  } catch (e) {
    throw new Error('Произошла ошибка при загрузке видео')
  }
}

export const deleteVideos = async ({ files }) => {
  const { deleteRequest } = useRequests()

  try {
    await Promise.all(
      files.map(async ({ id }) => {
        await deleteRequest(`excavation-videos/${id}`)
      })
    )
  } catch (e) {
    throw new Error(e)
  }
}
