export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getSourceTitle = (source) => {
  const { title, number, author, year } = source

  let sourceTitle = number ? `${number}. ${title}` : title

  if (author) {
    sourceTitle += `, ${author}`
  }
  if (year) {
    sourceTitle += `, ${year}`
  }

  return sourceTitle
}

export const getDataPointNameByType = (type) => {
  switch (type) {
    case 101:
      return 'excavation'
    default:
      return 'recon'
  }
}
