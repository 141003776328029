<script setup>
import { computed } from 'vue'

import ExcavationIcon from './icons/excavation-icon.vue'
import ReconIcon from './icons/recon-icon.vue'

const props = defineProps({
  type: {
    type: Number,
    validator: (value) => [101, 201].includes(value),
    default: 201
  },
  size: {
    type: String,
    validator: (value) => ['default', 'small'].includes(value),
    default: 'default'
  },
  transparent: {
    type: Boolean,
    default: false
  },
  color: {
    type: String,
    validator: (value) => ['primary', 'text', 'caption'].includes(value),
    default: 'primary'
  }
})

const styleValue = computed(() => {
  const sizeValue = props.size === 'default' ? 32 : 24

  return {
    width: `${sizeValue}px`,
    height: `${sizeValue}px`,
    opacity: props.transparent ? 0.6 : 1
  }
})
</script>

<template>
  <div class="data-item-icon" :style="styleValue">
    <template v-if="type === 201">
      <excavation-icon :color="color" />
    </template>
    <template v-else>
      <recon-icon :color="color" />
    </template>
  </div>
</template>
