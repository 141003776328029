<script setup>
import { onMounted, ref, watch, computed, inject } from 'vue'
import { useMapStore } from '@/stores/map'

import { decimalDegreesToDMS, DMSToDecimalDegrees } from '@/utils/custom-dms-mask/helpers'

import MapLocationEditor from '@/modules/app/data/maps/map-location-editor.vue'
import gmsInput from './dms-input.vue'

const props = defineProps({
  title: {
    type: String,
    default: '-'
  },
  center: {
    type: Array,
    default: () => null
  },
  source: {
    type: Object,
    default: () => null
  }
})

const emits = defineEmits(['save'])

const $notify = inject('$notify')
const mapStore = useMapStore()

const initCenterDecimals = ref(props.center)

const updateCenter = (value) => {
  initCenterDecimals.value = value
  emits('save', value)
}

const tabs = [
  { id: 'map', name: 'На карте' },
  { id: 'manual', name: 'Вручную' }
]
const activeTab = ref('map')

onMounted(() => {
  emits('save', initCenterDecimals.value)

  dmsConfig.value = [
    {
      title: 'Широта',
      degreesMaxValue: 89,
      degreesMinValue: -89,
      id: 'lat',
      value: decimalDegreesToDMS(initCenterDecimals.value[1])
    },
    {
      title: 'Долгота',
      degreesMaxValue: 179,
      degreesMinValue: -179,
      id: 'lon',
      value: decimalDegreesToDMS(initCenterDecimals.value[0])
    }
  ]
})

const changeDecimalsHandler = () => {
  emits('save', initCenterDecimals.value)
}

const dmsConfig = ref([
  {
    title: 'Широта',
    degreesMaxValue: 89,
    degreesMinValue: -89,
    id: 'lat',
    value: initCenterDecimals.value[1]
  },
  {
    title: 'Долгота',
    degreesMaxValue: 179,
    degreesMinValue: -179,
    id: 'lon',
    value: initCenterDecimals.value[0]
  }
])

watch(
  initCenterDecimals,
  (newVal) => {
    dmsConfig.value = [
      {
        title: 'Широта',
        degreesMaxValue: 89,
        degreesMinValue: -89,
        id: 'lat',
        value: decimalDegreesToDMS(newVal[1])
      },
      {
        title: 'Долгота',
        degreesMaxValue: 179,
        degreesMinValue: -179,
        id: 'lon',
        value: decimalDegreesToDMS(newVal[0])
      }
    ]
  },
  { deep: true }
)

const dmsHandler = (coords, id) => {
  const { degrees, minutes, seconds } = coords

  switch (id) {
    case 'lat':
      initCenterDecimals.value[1] = DMSToDecimalDegrees(degrees, minutes, seconds)
      break
    case 'lon':
      initCenterDecimals.value[0] = DMSToDecimalDegrees(degrees, minutes, seconds)
  }

  emits('save', initCenterDecimals.value)
}

const initSource = ref(props.source)

const userLocation = computed(() => {
  return mapStore.userLocation || null
})

const userAbs = computed(() => {
  return mapStore.userAbs || null
})

const setCurrentLocation = () => {
  if (!userLocation.value) {
    $notify({
      message: 'Геопозиция не определена. Проверьте настройки GPS на устройстве',
      type: 'warning'
    })
  } else {
    const [lon, lat] = userLocation.value

    initSource.value.lon = lon
    initSource.value.lat = lat

    initCenterDecimals.value[0] = lon
    initCenterDecimals.value[1] = lat

    emits('save', initCenterDecimals.value)

    if (userAbs.value) {
      initSource.value.abs = userAbs.value
    }
  }
}
</script>

<template>
  <div class="start-location">
    <s-title type="small">{{ title }}</s-title>

    <s-tabs :tabs="tabs" :value="activeTab" @click="(id) => (activeTab = id)">
      <s-tab id="map" name="На карте" :active="activeTab">
        <map-location-editor
          :center="initCenterDecimals"
          :source="initSource"
          @update-location="updateCenter"
        />
      </s-tab>

      <s-tab id="manual" name="Вручную" :active="activeTab">
        <div class="start-location__coords">
          <s-button @click="setCurrentLocation">Выставить по GPS</s-button>
          <div class="start-location__inputs">
            <s-number-input
              v-model="initCenterDecimals[1]"
              label="Широта, дес. град."
              :min="-90"
              :max="90"
              @input="changeDecimalsHandler"
            />
            <s-number-input
              v-model="initCenterDecimals[0]"
              label="Долгота, дес. град."
              :min="-180"
              :max="180"
              @input="changeDecimalsHandler"
            />
          </div>
          <div class="start-location__inputs">
            <gms-input
              v-for="config in dmsConfig"
              :key="config.id"
              :decimalValue="config.value"
              :config="config"
              @change="dmsHandler"
            />
          </div>
        </div>
      </s-tab>
    </s-tabs>
  </div>
</template>

<style lang="scss" scoped>
.start-location {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 100%;
  align-self: stretch;

  &__coords {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .s-tabs {
    grid-template-rows: auto 1fr;

    & .s-tab {
      overflow: hidden;
    }
  }
}
</style>
