<script setup>
import { ref, watch } from 'vue'
import { parseDMS } from '@/utils/custom-dms-mask/helpers'

const props = defineProps({
  config: {
    type: Object,
    required: true
  },
  decimalValue: {
    type: [String, Number],
    default: null
  }
})

const emits = defineEmits(['change'])

const degrees = ref(0)
const minutes = ref(0)
const seconds = ref(0)

const changeDMSHandler = () => {
  emits(
    'change',
    { degrees: degrees.value, minutes: minutes.value, seconds: seconds.value },
    props.config.id
  )
}

watch(
  () => props.decimalValue,
  (newValue) => {
    const coords = parseDMS(newValue)
    const { degrees: d, minutes: m, seconds: s } = coords
    degrees.value = d
    minutes.value = m
    seconds.value = s
  }
)
</script>

<template>
  <div class="gms-inputs" v-if="config">
    <s-text>{{ config.title }}, град., мин., сек.</s-text>
    <div class="gms-inputs__wrapper">
      <div class="gms-inputs__input">
        <s-number-input
          v-model="degrees"
          :min="config.degreesMinValue"
          :max="config.degreesMaxValue"
          @input="changeDMSHandler"
        />
        <s-text>°</s-text>
      </div>
      <div class="gms-inputs__input">
        <s-number-input v-model="minutes" :min="-59" :max="59" @input="changeDMSHandler" />
        <s-text>'</s-text>
      </div>
      <div class="gms-inputs__input">
        <s-number-input v-model="seconds" :min="-59.999" :max="59.999" @input="changeDMSHandler" />
        <s-text>"</s-text>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.gms-inputs {
  display: grid;
  gap: 12px;

  &__wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }

  &__input {
    display: flex;
    gap: 8px;
    align-items: flex-start;

    & .s-text {
      min-width: 10px;
    }
  }
}
</style>
