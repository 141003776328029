<script setup>
import { ref, watch, nextTick, computed } from 'vue'

import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'

import { loadImage } from './helpers'

import SAttachmentsGallery from './s-attachments-gallery.vue'

const props = defineProps({
  source: {
    type: Object,
    default: () => null
  },
  sourceType: {
    type: String,
    validator: (value) => ['sample', 'corebox'].includes(value)
  },
  items: {
    type: Array,
    default: () => []
  },
  item: {
    type: Object,
    default: () => null
  },
  mode: {
    type: String,
    validator: (value) => ['single', 'multi'].includes(value),
    default: 'single'
  },
  thumbnails: {
    type: Boolean,
    default: false
  },
  initialSlide: {
    type: Number,
    default: 0
  },
  galleryMode: {
    type: Boolean,
    default: false
  },
  galleryModeItems: {
    type: Array,
    default: () => []
  }
})

const authStore = useAuthStore()
const mainStore = useMainStore()

const loading = ref(false)
const isVisible = ref(false)
const imageLink = ref(null)
const loadingGalleryImages = ref(false)
const initialSlideIndex = ref(props.initialSlide)

const images = ref([])
const imagesThumbs = ref([])

const blobImages = ref({})

watch(
  () => props.items,
  () => {
    nextTick(() => {
      init()
    })
  },
  {
    immediate: true,
    deep: true
  }
)

const init = async () => {
  imagesThumbs.value = await Promise.all(
    props.items.map(async (item) => {
      return await getThumbnail(item)
    })
  ).then((results) => results.filter((item) => item))

  if (imagesThumbs.value.length === 0) {
    imageLink.value = null
  }
}

const getThumbnail = async (item) => {
  let image
  if (!item) {
    image = null

    return
  }

  if (item.blob) {
    image = await URL.createObjectURL(item.blob)
  } else {
    if (!mainStore.isOnline) return

    try {
      loading.value = true
      image = null

      const res = await fetch(`${item?.thumbnail_url}?access_token=${authStore.userToken}`)
      const blob = await res.blob()

      image = await URL.createObjectURL(blob)
    } catch (e) {
      console.log(e)
    } finally {
      loading.value = false
    }
  }
  imageLink.value = image

  return image
}

const openGallery = async (imageIndex) => {
  if (!props.sourceType) {
    initialSlideIndex.value = imageIndex ? imageIndex : 0
  }

  let items
  if (props.galleryMode) {
    items = props.galleryModeItems
  } else {
    items = props.items
  }

  if (!mainStore.isOnline) {
    images.value = await Promise.all(items.map((item) => loadImage(item, blobImages))).then(
      (results) => results.filter((item) => item)
    )

    if (images.value.length) {
      isVisible.value = !isVisible.value
    }
    return
  }

  loadImagesInOrder(items)
}

const checkStatusAndImageLink = computed(() => {
  if (mainStore.isOnline && imageLink.value) {
    return true
  }

  if (!mainStore.isOnline && imageLink.value) {
    return true
  }

  return false
})

const loadImagesInOrder = async (items) => {
  isVisible.value = !isVisible.value

  try {
    loadingGalleryImages.value = true
    images.value = new Array(items.length).fill(null)

    const firstImage = await loadImage(items[initialSlideIndex.value], blobImages)
    if (firstImage) {
      images.value[initialSlideIndex.value] = firstImage
    }

    loadingGalleryImages.value = false

    for (let i = initialSlideIndex.value + 1; i < items.length; i++) {
      const image = await loadImage(items[i], blobImages)
      if (image) {
        images.value[i] = image
      }
    }

    for (let i = 0; i < initialSlideIndex.value; i++) {
      const image = await loadImage(items[i], blobImages)
      if (image) {
        images.value[i] = image
      }
    }
  } catch (e) {
    console.log(e)
    loadingGalleryImages.value = false
  }
}
</script>

<template>
  <div v-loading="loading" :class="`attachments-gallery attachments-gallery--${mode}`">
    <template v-if="checkStatusAndImageLink">
      <img
        v-if="mode === 'single'"
        class="attachments-gallery__item"
        :src="imageLink"
        alt="image"
        @click.stop="openGallery(0)"
      />
      <img
        v-else
        v-for="(item, index) in imagesThumbs"
        :key="item"
        class="attachments-gallery__item"
        :src="item"
        alt="image"
        @click.stop="openGallery(index)"
      />
    </template>

    <div class="attachments-gallery__icon-wrapper" v-else-if="!mainStore.isOnline">
      <s-icon name="eye-slash" color="var(--placeholder)" class="attachments-gallery__icon" />
    </div>
    <div class="attachments-gallery__icon-wrapper" v-else>
      <s-icon name="image" color="var(--placeholder)" class="attachments-gallery__icon" />
    </div>
  </div>

  <s-attachments-gallery
    :isVisible="isVisible"
    :initialSlide="initialSlideIndex"
    :thumbnails="thumbnails"
    :images="images"
    :source="source"
    :sourceType="sourceType"
    :thumbnailsSlidesPerView="10"
    @close="isVisible = false"
    :loading="loadingGalleryImages"
    galleryMode
    :galleryModeItems="galleryModeItems"
  />
</template>

<style lang="scss">
.attachments-gallery {
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
  min-width: 64px;
  min-height: 64px;
  flex-wrap: wrap;

  &.s-loading__mask {
    overflow: hidden;
    max-width: 64px;
    max-height: 64px;
  }

  &--single {
    width: 64px;
    background-color: var(--secondary-bg);
  }

  &__preview {
    min-height: 40vh;
    width: 100%;
    display: grid;
    overflow: hidden;
    align-content: center;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;

    &-wrapper {
      width: 100%;
      height: 100%;
      background-color: var(--secondary-bg);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      min-height: 40px;
      aspect-ratio: 1 / 1;
    }
  }

  &__item {
    height: 64px;
    width: 64px;
    object-fit: cover;
    border-radius: var(--border-radius);
    overflow: hidden;
    background-color: var(--secondary-bg);
  }

  &-modal {
    display: grid;
    height: 100%;
    grid-gap: 1rem;
    align-content: center;
    justify-items: center;

    &__info {
      display: flex;
      grid-gap: 1rem;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      flex-grow: 1;
    }

    &__thumbnails {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      width: 100%;
      justify-content: center;
    }
  }
}

.s-modal__body:has(.attachments-gallery-modal) {
  overflow-x: hidden;
  display: block;
}
</style>
