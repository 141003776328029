<script setup>
import { computed } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { menuList } from '@/configs/menu'

const authStore = useAuthStore()

const filteredActions = computed(() => {
  const desktopList = menuList.filter((i) => !i.mobileOnly)
  if (authStore.isAdmin) return desktopList

  return desktopList.filter((i) => !i.admin)
})
</script>

<template>
  <ul class="desktop-actions">
    <li v-for="item in filteredActions" :key="item.id" class="desktop-actions__item">
      <a
        v-if="item.type === 'link'"
        v-tooltip="item.label"
        class="desktop-actions__link"
        :href="item.link"
        target="_blank"
      >
        <s-icon :name="item.icon" size="lg" />
      </a>

      <router-link
        v-else
        v-tooltip="item.label"
        class="desktop-actions__link"
        :to="`/app/${item.id}`"
      >
        <s-icon :name="item.icon" size="lg" />
      </router-link>
    </li>
  </ul>
</template>

<style lang="scss">
.desktop-actions {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5rem;
  height: 100%;
  align-items: center;

  &__link {
    cursor: pointer;

    &.router-link-active {
      .s-icon {
        color: var(--primary) !important;
      }
    }

    & svg {
      font-size: 1.25rem;
    }
  }
}
</style>
