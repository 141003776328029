export const getDataFromLS = (name, data) =>
  localStorage.getItem(name) ? JSON.parse(localStorage.getItem(name)) : data

export const saveDataToLS = (name, data) => localStorage.setItem(name, JSON.stringify(data))

export const removeDataFromLS = (key) => {
  localStorage.removeItem(key)
}

export const toggleBodyTheme = (newTheme) => {
  newTheme = newTheme || 'dark-theme'
  const oldClass = newTheme === 'dark-theme' ? 'light-theme' : 'dark-theme'

  document.body.classList.remove(oldClass)
  document.body.classList.add(newTheme)
}

export const toggleBodyMq = (current, prev) => {
  current = current || 'lg'

  if (prev) {
    document.body.classList.remove(prev)
  }

  document.body.classList.add(current)
}

export function getOS() {
  let userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'macOS'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'],
    os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}

export const reloadWindow = () => {
  window.location.reload()
}

export const getUuid = () => {
  return self.crypto.randomUUID()
}
