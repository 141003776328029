<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useResourcesStore } from '@/stores/resources'

const resourcesStore = useResourcesStore()

const isShow = ref(false)

const resourceLoading = computed(() => {
  return resourcesStore.resourcesLoading
})

const fullLength = computed(() => {
  return resourceLoading.value.length || 0
})

const uploadedLength = computed(() => {
  return resourceLoading.value?.filter((l) => l.uploaded)?.length || 0
})

const description = computed(() => {
  return fullLength.value !== uploadedLength.value
    ? `Загрузка ${loadingType.value} на сервер... (${uploadedLength.value} / ${resourceLoading.value.length})`
    : `Загрузка ${loadingType.value} завершена`
})

const loadingType = computed(() => {
  const types = resourceLoading.value.map((item) => item.type)

  if (types.includes('video') && !types.includes('image')) {
    return 'видео'
  }

  if (types.includes('image') && !types.includes('video')) {
    return 'фото'
  }

  return 'фото и видеоматериалов'
})

const type = computed(() => {
  return fullLength.value === uploadedLength.value ? 'success' : 'loading'
})

watch(
  () => resourceLoading.value,
  (newValue) => {
    if (!newValue.length) return
    isShow.value = !!resourceLoading.value?.length

    if (fullLength.value === uploadedLength.value) {
      setTimeout(() => {
        isShow.value = false
        resourcesStore.setField('resourcesLoading', [])
      }, 512)
    }
  },
  {
    deep: true
  }
)

onMounted(() => {
  isShow.value = !!resourceLoading.value?.length
})
</script>

<template>
  <div v-if="isShow" :class="[`mobile-image-loading ${type}`]">
    {{ description }}
  </div>
</template>

<style lang="scss">
@keyframes move-it {
  0% {
    background-position: initial;
  }
  100% {
    background-position: 100px 0px;
  }
}
.mobile-image-loading {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: center;
  grid-gap: 0.5rem;
  width: 100%;
  height: 1.25rem;
  font-size: 0.75rem;
  line-height: 0.75rem;
  color: var(--primary);

  &.loading {
    background: repeating-linear-gradient(
      45deg,
      #2792ed30,
      #2792ed30 15%,
      #2792ed10 15%,
      #2792ed10 50%
    );
    background-size: 100px 100px;
    animation: move-it 5s linear infinite;
  }

  &.success {
    color: var(--success);
    background-color: var(--success--select);
  }
}
</style>
