import extent from 'turf-extent'
import bbox from '@turf/bbox'
import bboxPolygon from '@turf/bbox-polygon'

export const getPaddingSettings = () => {
  return {
    top: 48,
    bottom: 48,
    left: 48,
    right: 48
  }
}

export const flyToFeatures = (mapgl, data) => {
  const { length } = data.features

  if (!mapgl) return
  if (!length) return

  const geometry = bboxPolygon(bbox(data)).geometry
  const bounds = extent(geometry)
  const padding = getPaddingSettings()

  mapgl.setPadding({ left: 0, right: 0, top: 0, bottom: 0 })

  mapgl.fitBounds(bounds, {
    maxZoom: length === 1 ? 18 : 12,
    padding,
    maxDuration: 500
  })
}
