<script setup>
import { useMq } from 'vue3-mq'
import { useMainStore } from '@/stores/main'

import CenteredContainer from '@/components/centered-container.vue'
import ProfileInfo from './components/profile-info.vue'
import ProfileStats from './components/profile-stats.vue'
import ProfileLog from './components/profile-log.vue'
import ProfileSettings from './components/profile-settings.vue'
import ProfileLogout from './components/profile-logout.vue'

const mainStore = useMainStore()
const mq = useMq()
</script>

<template>
  <centered-container title="Личный профиль" :width="720">
    <div class="profile">
      <profile-info />
      <profile-stats v-if="mainStore.isOnline" />
      <profile-log v-if="mainStore.isOnline" />
      <profile-settings />
      <profile-logout v-if="mq.current !== 'lg'" />
    </div>
  </centered-container>
</template>

<style lang="scss">
.profile {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding-right: 0.5rem;
}

.lg {
  .profile {
    padding-right: 0.8rem;
  }
}
</style>
