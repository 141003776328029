<script setup>
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { getObjectPercent } from '@/utils/objects'
import { roundValue } from '@/utils/numbers'
import { getObjectDatesLabel } from '@/utils/dates'
import { objectStatuses } from '@/configs/info'

const props = defineProps({
  object: {
    type: Object,
    required: true
  },
  isPrintPage: {
    type: Boolean,
    default: false
  }
})

const router = useRouter()

const status = computed(() => {
  return objectStatuses[props.object.status]
})

const percent = computed(() => {
  const { stats_excavations_meters, stats_excavations_meters_plan } = props.object

  return getObjectPercent(stats_excavations_meters, stats_excavations_meters_plan)
})

const objectDates = computed(() => {
  const { date_start, date_end } = props.object

  return getObjectDatesLabel(date_start, date_end)
})

const tagColorsMap = {
  1: 'default',
  2: 'blue',
  3: 'green',
  4: 'yellow'
}

const getDate = () => {
  return getObjectDatesLabel(props.object?.date_start, props.object?.date_end, true)
}

const openObject = () => {
  if (props.isPrintPage) return

  const id = props.object?.server_id || `idb_${props.object?.id}`
  router.push({ path: `/app/data/objects/${id}`, query: { type: 'all' } })
}
</script>

<template>
  <div class="object-item" :class="{ 'print-page': isPrintPage }" @click="openObject">
    <div class="object-item__container">
      <div class="object-item__text">
        <div class="object-item__header">
          <div class="object-item__number">{{ object.num }}</div>
          <s-tag value="Архив" v-if="!!object.archived" />
        </div>
        <div v-if="!isPrintPage" class="object-item__title">
          {{ object.title_short }}
        </div>
        <div>
          <div class="object-item__info">
            <div class="object-item__info-status">
              <s-tag :value="status" :type="tagColorsMap[props.object.status]" />
            </div>
            <div class="object-item__info-date">
              <s-icon size="sm" name="calendar-days" />
              <s-text type="secondary">
                {{ getDate() }}
              </s-text>
            </div>
          </div>
        </div>
        <div v-if="isPrintPage" class="object-marker__dates">
          Сроки: <span> {{ objectDates }} </span>
        </div>
      </div>

      <div class="object-item__stats">
        <div class="object-item__progress">
          <div
            data-test="progress-inner"
            class="object-item__progress-inner"
            :style="`width: ${percent.width}px`"
          />
          <span>{{ percent.value }}%</span>
        </div>
        <div class="object-item__counts">
          <span
            >{{ roundValue(object.stats_excavations_count) || 0 }} /
            {{ roundValue(object.stats_excavations_count_plan) || 0 }}</span
          >
          скв.
        </div>
        <div class="object-item__counts">
          <span
            >{{ roundValue(object.stats_excavations_meters) || 0 }} /
            {{ roundValue(object.stats_excavations_meters_plan) || 0 }}</span
          >
          п.м.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.object-item {
  border-bottom: 1px solid var(--bg);
  border-radius: 0;
  width: 100%;
  cursor: pointer;
  padding: 8px 0;

  &:last-child {
    border-bottom: none;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__stats {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

  &__container {
    display: grid;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-radius: 8px;
    grid-template-columns: auto auto;

    &:hover {
      background-color: var(--primary--select);
    }

    &:active {
      background-color: var(--primary--active);
    }
  }

  &.print-page {
    border: 1px solid var(--main-bg);
    max-width: none;
    width: 100%;
    box-shadow: none;
    background: var(--bg);
    cursor: default;
    border-radius: var(--border-radius);

    & .object-item__container {
      &:hover {
        background-color: transparent;
      }
    }
  }

  &__text {
    margin-right: 16px;
    min-width: 0;

    @media (max-width: 400px) {
      margin-right: 8px;
    }
  }

  &__number {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: var(--info);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: var(--text);
    margin-top: 4px;
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 12px;

    &-status {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: var(--caption);
      text-align: center;

      span {
        font-weight: 600;
      }

      span.primary {
        color: var(--primary);
      }

      span.success {
        color: var(--success);
      }

      span.warning {
        color: var(--warning);
      }
    }

    @media (max-width: 400px) {
      flex-wrap: wrap;
      gap: 8px;
    }

    &-date {
      display: grid;
      grid-auto-flow: column;
      grid-gap: 0.5rem;
      justify-content: start;
      align-items: center;
      color: var(--caption);
    }
  }

  &__dates {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: var(--caption);
    margin-top: 4px;
  }

  &__progress {
    overflow: hidden;
    text-align: right;
    border-radius: 8px;
    background-color: var(--bg);
    position: relative;
    width: 80px;
    height: 24px;
    margin-bottom: 8px;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 12px;
      font-weight: 600;
      color: var(--text);
    }

    &-inner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      background-color: var(--primary);
    }
  }

  &__counts {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    text-align: right;
    color: var(--caption);

    span {
      font-weight: 600;
    }
  }
}

.light-theme {
  & .object-item {
    border-bottom: 1px solid var(--main-bg);

    &__progress {
      border: 1px solid var(--main-bg);
    }
  }
}
</style>
