<script setup>
import { ref, computed, watch, inject } from 'vue'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import { useMq } from 'vue3-mq'
import db from '@/libs/db'

import { useMapStore } from '@/stores/map'
import { useMainStore } from '@/stores/main'
import { useReconStore } from '@/stores/recon'
import { useRitmDate, useRequests } from '@/composables'

import { startSteps, basicFields, secondaryFields } from './config'
import { galleryFields } from '../../configs/data-card'
import { saveImages } from '@/utils/requests/images'
import { getDataChanges } from '@/utils/data-sync/data'

import ProgressComp from '@/modules/app/data/excavation/components/excavation-start/components/start-progress.vue'
import DataStartLocation from '@/components/data-start-location.vue'
import Inputs from '@/modules/app/data/excavation/components/excavation-start/components/start-inputs.vue'
import ImageComp from './components/start-image.vue'
import GeoProcesses from './components/geo-processes.vue'

const props = defineProps({
  recon: {
    type: Object,
    default: () => null
  },
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update-handler', 'toggle'])

const mq = useMq()
const rDate = useRitmDate()
const requests = useRequests()
const mapStore = useMapStore()
const reconStore = useReconStore()
const mainStore = useMainStore()
const $notify = inject('$notify')

const loading = ref(false)
const initSource = ref(props.recon)
const initSourceClone = ref(cloneDeep(props.recon))
const activeStep = ref(1)

const commonImages = ref([])
const journalImages = ref([])

const changed = ref(false)

const hasChanges = computed(() => {
  if (initSourceClone.value && initSource.value) {
    return !isEqual(initSourceClone.value, initSource.value)
  }
  return false
})

const userLocation = computed(() => {
  return mapStore.userLocation || null
})

const sourceCenter = computed(() => {
  const { lon: lonValue, lat: latValue } = initSourceClone.value

  if (lonValue && latValue) return [lonValue, latValue]

  const { lon_plan, lat_plan } = initSourceClone.value

  if (lon_plan && lat_plan) return [lon_plan, lat_plan]

  if (!userLocation.value) return [37.6165, 55.752]

  const [lon, lat] = userLocation.value

  return [lon, lat]
})

const buttonName = computed(() => {
  switch (activeStep.value) {
    case 1: {
      return 'Далее'
    }
    case 5:
      return 'Завершить'
    default:
      if (changed.value) {
        return 'Далее'
      }
      return 'Пропустить'
  }
})

const buttonType = computed(() => {
  switch (activeStep.value) {
    case 5:
      return 'success'
    default:
      return 'primary'
  }
})

const currentFields = computed(() => {
  switch (activeStep.value) {
    case 2:
      return basicFields
    case 4:
      return secondaryFields
    default:
      return []
  }
})

watch(
  () => initSourceClone.value,
  () => {
    changed.value = true
  },
  { deep: true }
)

const clickHandler = () => {
  switch (activeStep.value) {
    case 5:
      editRecon()
      break
    default:
      activeStep.value++
      changed.value = false
      resetScroll()
      break
  }
}

const modalRef = ref(null)

const resetScroll = () => {
  if (modalRef.value) {
    modalRef.value.scrollTop = 0
  }
}

const editRecon = async () => {
  const { putRequest } = requests
  loading.value = true

  try {
    initSourceClone.value.date_front = rDate(new Date()).format('iso')
    initSourceClone.value.status = 4
    const toServer = Object.assign({}, initSourceClone.value)
    const toIdb = Object.assign({}, initSourceClone.value)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      toServer.id = toServer.server_id
      delete toServer.object_id
      toServer.type = null
      await putRequest(`reconnaissance/${toServer.id}/`, toServer)
    }

    await db.updateObject('recons', toIdb)
    if ((!mainStore.isOnline || mainStore.noSyncMode) && props.recon.server_id) {
      const updatedItems = await db.updated.where('table').equals('recons').toArray()
      const isExist = !!updatedItems.find((e) => e.item_id === props.recon.server_id)
      if (!isExist) {
        await db.updated.put({
          table: 'recons',
          item_id: props.recon.server_id,
          date: new Date()
        })
        getDataChanges()
      }
    }

    const title = 'Редактирование'
    const message = `Точка наблюдения "${toIdb.title}" успешно отредактирована`
    $notify({
      title,
      message,
      type: 'success'
    })

    emits('update-handler')
    toggleModal()
  } catch (error) {
    const title = 'Редактирование'
    const message = `Ошибка при редактирование точки наблюдения ${error}`
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
    if (commonImages.value?.length) {
      await saveImagesLocal(commonImages.value, 'images')
      reconStore.setField('updateReconCommonImages', true)
    }

    if (journalImages.value?.length) {
      await saveImagesLocal(journalImages.value, 'journal')
      reconStore.setField('updateReconJournalImages', true)
    }
  }
}

const toggleModal = () => {
  emits('toggle')
}

const saveImagesLocal = async (images, type) => {
  const { server_id, id } = props.recon

  const data = {
    server_id,
    table: 'recons',
    item_id: id,
    files: images,
    image_type: type,
    source_id: server_id || `idb_${id}`
  }
  await saveImages(data)
}

const setMapCenter = (center) => {
  const [lon, lat] = center
  initSourceClone.value.lon = Number(lon?.toFixed(5))
  initSourceClone.value.lat = Number(lat?.toFixed(5))
}

const changeProcesses = (id) => {
  if (initSourceClone.value.processes.includes(id)) {
    initSourceClone.value.processes = initSourceClone.value.processes.filter((item) => item !== id)
    return
  }

  initSourceClone.value.processes.push(id)
}

const galleryDataMap = computed(() => {
  return {
    images: commonImages.value,
    journalImages: journalImages.value
  }
})
</script>

<template>
  <s-modal
    title="Описание ТН"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :confirm-condition="hasChanges || changed"
    confirm-on-cancel
    @close="toggleModal"
    :back-button="activeStep !== 1"
    centerTitle
    @back="() => activeStep--"
  >
    <div class="recon-start" ref="modalRef">
      <progress-comp :active="activeStep" :steps="Object.keys(startSteps)" />
      <data-start-location
        v-if="activeStep === 1"
        :title="startSteps[activeStep].title"
        :center="sourceCenter"
        :source="initSourceClone"
        @save="setMapCenter"
      />
      <inputs
        v-if="activeStep === 2"
        :title="startSteps[activeStep].title"
        :source="initSourceClone"
        :fields="currentFields"
        @field-was-changed="changed"
      />
      <geo-processes
        v-if="activeStep === 3"
        :title="startSteps[activeStep].title"
        :recon="initSourceClone"
        :fields="currentFields"
        :wiki-tip-data="startSteps[activeStep].wikiTipData"
        @change-geo-proc="changeProcesses"
        :activeTypes="initSourceClone.processes"
      />
      <inputs
        v-if="activeStep === 4"
        :title="startSteps[activeStep].title"
        :source="initSourceClone"
        :fields="currentFields"
      />
      <div class="recon-start__images" v-if="activeStep === 5">
        <s-title type="small">{{ startSteps[activeStep].title }}</s-title>
        <image-comp
          v-for="item in galleryFields"
          :key="item.id"
          :title="item.title"
          :loading="loading"
          :files="galleryDataMap[item.images]"
          :wiki-tip-data="item.wikiTipData"
        />
      </div>
    </div>
    <template #footer>
      <s-button :type="buttonType" :loading="loading" @click="clickHandler">
        {{ buttonName }}
      </s-button>
    </template>
  </s-modal>
</template>

<style lang="scss">
.recon-start {
  display: grid;
  grid-gap: 1rem;
  overflow: auto;
  align-content: start;
  grid-template-rows: auto 1fr;
  height: 100%;

  &__images {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
</style>
