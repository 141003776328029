<script setup>
import { ref, computed, onMounted } from 'vue'
import { useServicesStore } from '@/stores/services'
import { getSoilFilters, parseSoilsList } from '@/utils/soils'
import SoilSelect from './soil-select.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['set-soil-type'])

const servicesStore = useServicesStore()

const fields = ref([
  {
    id: 'soil_type',
    title: 'soil_type',
    type: 'select',
    service: 'soil_types',
    commentField: 'soil_type_comments',
    commentTitle: 'Комментарий к виду грунта'
  },
  {
    id: 'ice_type',
    title: 'ice_type',
    service: 'ice_types',
    type: 'select',
    text: 'Вид льда',
    condition: 'ice'
  },
  {
    id: 'alteration_techno_type',
    title: 'alteration_techno_type',
    service: 'alterations_techno_types',
    type: 'select',
    text: 'Вид техногенного грунта',
    condition: 'techno'
  }
])

const states = ref({
  techno: {
    id: 'techno',
    title: 'Техногенный',
    value: false
  },
  frozen: {
    id: 'frozen',
    title: 'Мерзлый',
    value: false
  }
})

const initSource = ref(props.source)
const activeType = ref(null)
const activeGroup = ref(null)
const soilsFilters = ref({
  title: 'Фильтры',
  filterTitle: 'Вид грунта',
  filterValues: []
})

const soilsList = computed(() => {
  return servicesStore?.soil?.soil_types
})

const parsedList = computed(() => {
  return parseSoilsList(soilsList.value, soilsFilters.value)
})

onMounted(() => {
  if (initSource.value.state === 2) {
    states.value.frozen.value = true
  }

  if (initSource.value.alteration_techno) {
    states.value.techno.value = true
  }

  soilsFilters.value.filterValues = getSoilFilters()
  typeChange(props.source.soil_type, true)
})

const typeChange = (val, init = false) => {
  const type = soilsList.value?.find((e) => e.id === val)
  const group = parsedList.value.find((g) => {
    return g.items.find((e) => e.id === val)
  })

  setType(type, group, init)
}

const setType = (type, group, init) => {
  initSource.value.soil_type = type?.id || null

  activeType.value = type
  activeGroup.value = group

  setSoilType(type, init)
}

const setSoilType = (type, init) => {
  if (!activeGroup.value) {
    emits('set-soil-type', { type: '', init })
  } else if (type?.title_short === 'ПРС') {
    if (states.value?.frozen?.value) {
      emits('set-soil-type', { type: 'frozenPrs', init })
    } else {
      emits('set-soil-type', { type: 'prs', init })
    }
  } else if (states.value?.frozen?.value) {
    if (activeGroup.value.id === 'sand') {
      emits('set-soil-type', { type: 'frozenSand', init })
    } else if (activeGroup.value.id === 'largeScale') {
      emits('set-soil-type', { type: 'frozenLargeScale', init })
    } else if (activeGroup.value.id === 'rock') {
      emits('set-soil-type', { type: 'frozenRock', init })
    } else if (activeGroup.value.id === 'ice') {
      emits('set-soil-type', { type: 'ice', init })
    } else if (activeGroup.value.id === 'techno') {
      emits('set-soil-type', { type: 'techno', init })
    } else {
      emits('set-soil-type', { type: 'frozen', init })
    }
  } else if (states.value?.techno?.value) {
    if (activeGroup.value.id === 'clay') {
      emits('set-soil-type', { type: 'technoClay', init })
    } else if (activeGroup.value.id === 'sand') {
      emits('set-soil-type', { type: 'technoSand', init })
    } else if (activeGroup.value.id === 'largeScale') {
      emits('set-soil-type', { type: 'technoLargeScale', init })
    } else if (activeGroup.value.id === 'rock') {
      emits('set-soil-type', { type: 'technoRock', init })
    } else {
      emits('set-soil-type', { type: '', init })
    }
  } else {
    emits('set-soil-type', { type: activeGroup.value?.id || '', init })
  }
}

const changeState = (stateValue, type) => {
  states.value[type].value = stateValue
  if (type === 'frozen') {
    initSource.value.state = states.value[type].value ? 2 : 1
  }
  if (type === 'techno') {
    initSource.value.alteration_techno = states.value[type]?.value ? 1 : null
  }

  typeChange(initSource.value.soil_type)
}

const checkFieldVisibility = (field) => {
  if (!field.condition) return true

  if (activeGroup.value && activeGroup.value?.id === 'ice' && field.condition === 'ice') {
    return true
  }

  if (
    field.condition === 'techno' &&
    ((activeGroup.value && activeGroup.value?.id === 'techno') ||
      initSource.value.alteration_techno)
  ) {
    return true
  }

  return false
}
</script>

<template>
  <div class="soil-type">
    <s-title type="small">Вид грунта</s-title>
    <div class="soil-type-state">
      <s-checkbox
        v-for="state in states"
        :key="state.id"
        border
        :checked="state.value"
        :label="state.title"
        @change="changeState($event, state.id)"
      />
    </div>
    <template v-for="field in fields" :key="field.id">
      <div class="soil-type-row" v-if="checkFieldVisibility(field)">
        <s-title v-if="field.text" type="small">{{ field.text }}</s-title>
        <soil-select :field="field" :source="source" />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.soil-type {
  display: grid;
  grid-gap: 1.5rem;

  &-state {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
  }
}
</style>
