<script setup>
import { computed } from 'vue'
import { capitalizeFirstLetter } from '@/utils/strings'
import { excavationStatuses } from '@/configs/info.js'

const props = defineProps({
  status: {
    type: [Number, null],
    default: 1
  }
})

const statusName = computed(() => {
  return excavationStatuses[props.status]
})

const statusValue = computed(() => {
  switch (props.status) {
    case 1:
      return ''
    case 2:
      return 'moved'
    case 3:
      return 'drilling'
    case 4:
      return 'closed'
    default:
      return ''
  }
})
</script>

<template>
  <div :class="[`data-status ${statusValue}`]">
    {{ capitalizeFirstLetter(statusName) }}
  </div>
</template>

<style lang="scss">
.data-status {
  display: grid;
  padding: 0.5rem;
  border-radius: var(--border-radius);
  background-color: var(--bg-lite);
  color: var(--text);
  font-size: 12px;
  min-width: 96px;
  justify-content: center;
  align-items: center;

  &.drilling {
    color: var(--primary);
    background-color: var(--primary--active);

    i {
      color: var(--primary);
    }
  }

  &.moved {
    color: var(--warning);
    background-color: var(--warning--active);

    i {
      color: var(--warning);
    }
  }

  &.closed {
    color: var(--success);
    background-color: var(--success--active);

    i {
      color: var(--success);
    }
  }
}

.light-theme {
  .data-status {
    &:not(.drilling, .moved, .closed) {
      border: 1px solid var(--main-bg);
      background-color: var(--bg);
    }

    &.drilling {
      border: 1px solid var(--primary--hover);
    }

    &.moved {
      border: 1px solid var(--warning--hover);
    }

    &.closed {
      border: 1px solid var(--success--hover);
    }
  }
}
</style>
