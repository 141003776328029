<script setup>
import { computed } from 'vue'
import DataStatus from './s-data-status.vue'

const props = defineProps({
  data: {
    type: Object,
    required: true
  },
  type: {
    type: Number,
    validator: (value) => [101, 201, null].includes(value),
    default: null
  }
})

const depthInfo = computed(() => {
  const { h, h_plan } = props.data
  return `${h || 0} / ${h_plan || 0} м`
})

const isExcavType = computed(() => {
  if (props.type === 101) {
    return true
  }
  return false
})
</script>

<template>
  <div class="data-item-subinfo">
    <div v-if="isExcavType" class="data-item-subinfo-block">
      {{ depthInfo }}
    </div>
    <data-status :status="data.status" />
  </div>
</template>

<style lang="scss">
.data-item-subinfo {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.5rem;

  &-block {
    display: grid;
    padding: 0.5rem 1rem;
    border-radius: var(--border-radius);
    background-color: var(--bg-lite);
    color: var(--text);
    font-size: 12px;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    order: 0;
  }
}

@media (max-width: 350px) {
  .data-item-subinfo {
    justify-self: end;
  }
}

@include tablets {
  .data-item-subinfo-block {
    order: 1;
  }
}

@include phones {
  .data-item-subinfo {
    grid-auto-flow: row;
  }
}

.light-theme {
  .data-item-subinfo-block {
    border: 1px solid var(--main-bg);
    background-color: var(--bg);
  }
}
</style>
