<script setup>
import { computed, ref, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useRoute } from 'vue-router'

import { useVuelidate } from '@vuelidate/core'
import { objectValidator } from './validator'

import { useObjectsStore } from '@/stores/objects'
import { useMainStore } from '@/stores/main'

import { ObjectEditorSteps } from './config'
import { editObjectHelper, deleteObjectHelper } from './helpers'

const objectsStore = useObjectsStore()
const mainStore = useMainStore()
const route = useRoute()

const props = defineProps({
  currentStep: {
    type: Number,
    required: true
  }
})

const isLoading = ref(false)

const activeComponent = computed(() => {
  return ObjectEditorSteps.find((step) => step.step === props.currentStep)
})

const object = computed(() => {
  return objectsStore.activeObject
})

const clone = ref(cloneDeep(object.value))

watch(
  () => objectsStore.activeObject,
  (newValue) => {
    if (newValue) {
      clone.value = cloneDeep(object.value)
    }
  },
  { deep: true }
)

const isLastStep = computed(() => {
  return props.currentStep === ObjectEditorSteps.length
})

const change = (template) => {
  clone.value = template
}

const rules = computed(() => {
  return {
    ...objectValidator()
  }
})

const v$ = useVuelidate(rules, clone)

const editObject = async () => {
  const isEqualObjects = isEqual(clone.value, object.value)
  if (isEqualObjects) {
    return
  }

  const result = await v$.value.$validate()

  if (!result) {
    return
  }

  isLoading.value = true
  await editObjectHelper(clone.value)
  isLoading.value = false
}

const deleteObject = () => {
  const objectID = route.params.id
  deleteObjectHelper(objectID)
}

const disabled = computed(() => {
  return !mainStore.isOnline || !!v$.value.$errors.length
})
</script>

<template>
  <div class="object-editor">
    <component
      :vuelidate="v$"
      :step="activeComponent"
      @changeSelect="change"
      :fields="activeComponent.fields"
      :template="clone"
      :is="activeComponent.component"
      :editor="true"
    />
    <div class="object-editor__btn">
      <s-button @click="deleteObject" :disabled="disabled" v-if="isLastStep" type="error" stretch>
        Удалить проект
      </s-button>
      <s-button
        :loading="isLoading"
        :disabled="disabled"
        @click="editObject"
        v-else
        stretch
        type="success"
      >
        {{ isLoading ? 'Изменение данных' : 'Сохранить изменения' }}
      </s-button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.object-editor {
  width: 556px;
  padding: 24px;
  border: 1px solid var(--bg);
  background: var(--card-bg);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow: auto;
  justify-content: space-between;

  &__btn {
    padding: 24px 0 0 0;
  }
}
</style>
