<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { SText, SIcon, SNumberInput, SInput } from '@n966/soilbox_ui'
import cloneDeep from 'lodash.clonedeep'

import { useEditorStore } from '@/stores/work-planning/editor'
import { useObjectsStore } from '@/stores/objects'

import { incrementTitleNumber } from '../../helpers'

const props = defineProps({
  id: {
    type: [String, Number],
    required: true
  },
  data: {
    type: Object,
    default: null
  }
})

const editorStore = useEditorStore()
const objectsStore = useObjectsStore()

const title = ref(null)
const h = ref(10)

const currentList = computed(() => {
  let list
  if (editorStore.createModeOn) {
    list = editorStore.recentChangesPoints
  } else {
    list = editorStore.cloneExcavationsList
  }

  return list
})

const setExcavData = () => {
  if (!h.value || !title.value) return

  currentList.value.forEach(async (item) => {
    if (item.point_id === props.id || item.id === props.id) {
      const originalItem = objectsStore.excavationsList.find((point) => point.id === item.id)

      if (originalItem) {
        item.oldTitle = originalItem.title || null
      }

      item.h_plan = h.value
      item.title = title.value

      if (props.data) {
        const existingIndex = editorStore.recentChangesPoints.findIndex(
          (point) => point.id === item.id
        )

        if (existingIndex !== -1) {
          editorStore.recentChangesPoints[existingIndex] = cloneDeep(item)
        } else {
          editorStore.pushChangedPoint(cloneDeep(item))
        }

        editorStore.setCurrentStep(2)
      }
    }
  })

  title.value = null
  h.value = 10
  editorStore.setMapEditorPopupOpen(false)
}

const setDataOnEnter = (event) => {
  if (event.key === 'Enter') {
    setExcavData()
  }
}

onMounted(() => {
  if (props.data) {
    h.value = props.data.h_plan
    title.value = props.data.title
  } else {
    let lastPoint

    if (editorStore.recentChangesPoints.length > 1) {
      lastPoint = editorStore.recentChangesPoints.at(-2)
    } else {
      lastPoint = editorStore.cloneExcavationsList.at(-1)
    }

    title.value = incrementTitleNumber(lastPoint?.title)
  }

  window.addEventListener('keydown', setDataOnEnter)
})

onUnmounted(() => {
  window.removeEventListener('keydown', setDataOnEnter)
})
</script>

<template>
  <div class="create-popup">
    <div class="create-popup__body">
      <div class="create-popup__input-container">
        <s-text>Номер</s-text>
        <s-input v-model="title" />
      </div>
      <div class="create-popup__input-container">
        <s-text>Глубина, м</s-text>
        <s-number-input v-model="h" />
      </div>
    </div>
    <div class="create-popup__footer" @click="setExcavData">
      <s-icon color="var(--success)" size="lg" name="fa-check" />
      <s-text type="success">{{ data ? 'Сохранить' : 'Готово' }}</s-text>
    </div>
  </div>
</template>

<style lang="scss">
.create-popup {
  background: var(--secondary-bg);
  max-width: 200px;
  z-index: 2;
  position: relative;

  &__body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__footer {
    cursor: pointer;
    border-top: 1px solid var(--bg);
    display: flex;
    justify-content: center;
    gap: 12px;
    align-items: center;
    padding: 10px 14px;

    & svg {
      font-size: 1.8em;
    }

    & .s-text {
      font-weight: 600;
    }
  }

  &__input-container {
    display: flex;
    align-items: center;

    & .s-text {
      min-width: 50%;
    }

    & .s-input__input {
      padding: 0;
      text-indent: 10px;
      outline: none;

      &:focus-visible {
        border-color: var(--primary);
      }
    }

    & .s-number-input__input {
      padding: 0;
      text-indent: 10px;
      outline: none;

      &:focus-visible {
        border-color: var(--primary);
      }
    }
  }
}

.s-map {
  & .mapboxgl-popup-content:has(.create-popup) {
    border-radius: 10px;
    padding: 0;
  }

  & .mapboxgl-popup:has(.create-popup) {
    border: 1px solid var(--bg);
    border-radius: 8px;
    overflow: hidden;
    z-index: 2;
  }
}
</style>
