<script setup>
import { computed, ref, reactive, onMounted, watch } from 'vue'
import { useMq } from 'vue3-mq'
import cloneDeep from 'lodash.clonedeep'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router'

import { useObjectsStore } from '@/stores/objects'
import { useAuthStore } from '@/stores/auth'
import { useExcavationStore } from '@/stores/excavation'

import { mappedGeologists, prepareGeologistFilter } from '@/utils/geologists'
import {
  initFiltersConfig,
  saveToLSAndStore,
  setQueryParams,
  filterData,
  filterAndSortList
} from '@/utils/filters'
import { filtersConfig } from '../../configs'

import objectDataTip from '../../components/object-data-tip.vue'
import unassignedGeologistsTip from '../../components/unassigned-geologists-tip.vue'
import ListComp from './components/excavations-list.vue'

const objectsStore = useObjectsStore()
const excavationStore = useExcavationStore()
const authStore = useAuthStore()
const mq = useMq()
const route = useRoute()
const router = useRouter()

onBeforeRouteLeave((to, from, next) => {
  saveToLSAndStore('lastQueryDataPageValue', from.query)
  next()
})

watch(
  () => route.query.type,
  (newValue) => {
    route.query.type = newValue
    const filterTypes = filtersLocal.filters.find((f) => f.id === 'type')
    filterTypes.filterValues.forEach((filter) => {
      if (newValue === 'all') {
        filter.value = true
        return
      }

      if (newValue === filter.id) {
        filter.value = true
      } else {
        filter.value = false
      }
    })

    saveToLSAndStore('lastQueryDataPageValue', route.query)
  }
)

const object = computed(() => {
  return objectsStore.activeObject || null
})

watch(
  () => object?.value?.actual_geologists,
  (newValue) => {
    if (newValue) {
      const geologists = mappedGeologists(newValue)
      prepareGeologistFilter(geologists, filtersLocal)
    }
  }
)

const filterText = ref('')
const activeComp = ref('list')
let filtersLocal = reactive(cloneDeep(filtersConfig))

const excavationsList = computed(() => {
  return objectsStore.excavationsList || []
})

const reconsList = computed(() => {
  return objectsStore.reconsList || []
})

const generalList = computed(() => {
  return [...excavationsList.value, ...reconsList.value]
})

const filteredList = computed(() => {
  const filterTextValue = filterText.value?.trim()?.toLowerCase()

  const { filters, sort } = filtersLocal

  let currentList
  if (route.query.type === 'recon') {
    currentList = reconsList.value
  } else if (route.query.type === 'excavations') {
    currentList = excavationsList.value
  } else {
    currentList = generalList.value
  }
  const filteredList = filterData(currentList, filters, true)

  const sortedList = filterAndSortList(filteredList, filterTextValue, sort, ['title', 'site'])

  return sortedList
})

const hasRights = computed(() => {
  const geologists = objectsStore?.activeObject?.geologists
  const userId = authStore?.user?.id

  return geologists?.includes(userId) || !geologists?.length
})

const toggleModal = (id) => {
  switch (id) {
    case 'excavation':
      {
        const temp = objectsStore.isShowExcavationEditorModal
        objectsStore.setField('isShowExcavationEditorModal', !temp)
      }
      break
    case 'recon': {
      const temp = objectsStore.isShowReconEditorModal
      objectsStore.setField('isShowReconEditorModal', !temp)
    }
  }
}

const filterChangeHandler = (field, id, value) => {
  setQueryParams(filtersLocal, route, router, field, id, value, 'lastQueryDataPageValue')
}

const filteredFilters = computed(() => {
  const isDesktopScreen = mq.current === 'lg'

  if (!filtersLocal) return

  return {
    ...filtersLocal,
    filters: filtersLocal.filters.filter((filter) => {
      return !isDesktopScreen || !filter.showOnlyOnMobile
    })
  }
})

onMounted(() => {
  initFiltersConfig(filtersLocal, route, 'lastQueryDataPageValue')

  const geologists = mappedGeologists(object?.value?.actual_geologists)
  prepareGeologistFilter(geologists, filtersLocal)
})

const dropdownItems = ref([
  {
    id: 'excavation',
    label: 'Создать скважину'
  },
  {
    id: 'recon',
    label: 'Создать точку наблюдения'
  }
])

const toggleExportModal = () => {
  excavationStore.setField('exportModal', !excavationStore.exportModal)
}
</script>

<template>
  <div class="data-page">
    <object-data-tip v-if="mq.current === 'lg'" :object="object" />
    <unassigned-geologists-tip v-if="mq.current === 'lg'" />
    <s-search-block
      :has-button="authStore.isAdmin || hasRights"
      :filter="filterText"
      placeholder="Поиск по выработкам"
      tooltip-text="Создать выработку"
      :filters="filteredFilters"
      @change="(e) => (filterText = e)"
      @click-handler="toggleModal"
      @change-field="filterChangeHandler"
      data-page
      :hasButton="true"
      :dropdownItems="dropdownItems"
    >
      <template v-slot:extra-actions v-if="mq.current !== 'lg'">
        <div class="s-search-block__actions">
          <s-button icon="file-export" type="default" @click="toggleExportModal" />
        </div>
      </template>
    </s-search-block>
    <list-comp
      v-if="activeComp === 'list'"
      :excavations="filteredList"
      :filtered="!!filterText?.trim()"
    />
  </div>
</template>

<style lang="scss">
.s-search-block {
  &__actions {
    flex-shrink: 0;
  }
}
.data-page {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 591px;
  max-width: 591px;
  max-height: 100%;

  @include tablets {
    min-width: 100%;
    height: 100%;
  }
}
</style>
