export const getGeojson = (array) => {
  return {
    type: 'FeatureCollection',
    features: array.map((item) => ({
      type: 'Feature',
      properties: {
        ...item
      },
      geometry: {
        type: 'Point',
        coordinates: [item.y, item.x]
      }
    }))
  }
}

export const isLngLatValid = (coords) => {
  const [lng, lat] = coords

  return isFinite(lng) && Math.abs(lng) <= 180 && isFinite(lat) && Math.abs(lat) <= 90
}
