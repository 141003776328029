import appGlobalProperties from '@/main'

export const dataURLtoBlob = (dataurl) => {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new Blob([u8arr], { type: mime })
}

export const blobToBase64 = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader()

    reader.onloadend = () => resolve(reader.result)
    reader.readAsDataURL(blob)
  })
}

export const checkIsWrongExtension = (acceptedExtension, extension) => {
  return acceptedExtension.every((acceptedType) => {
    const acceptedExtension = acceptedType.split('.').pop().toLowerCase()
    return acceptedExtension !== extension.toLowerCase()
  })
}

export const showErrorNotify = (message) => {
  appGlobalProperties.$notify({
    message,
    type: 'error',
    duration: 5000
  })
}

export const getValidFiles = (files, validMimeType) => {
  const result = files.filter((file) => {
    if (file.type.startsWith(`${validMimeType}/`)) {
      return file
    }

    const message = `Недопустимый формат файла. ${file.name}`
    showErrorNotify(message)
  })

  return result
}
