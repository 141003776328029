<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useResourcesStore } from '@/stores/resources'

const resourcesStore = useResourcesStore()

const isShow = ref(false)

const resourceLoading = computed(() => {
  return resourcesStore.resourcesLoading
})

const fullLength = computed(() => {
  return resourceLoading.value.length || 0
})

const uploadedLength = computed(() => {
  return resourceLoading.value?.filter((l) => l.uploaded)?.length || 0
})

const description = computed(() => {
  return fullLength.value !== uploadedLength.value
    ? `Загрузка ${loadingType.value} на сервер... (${uploadedLength.value} / ${resourceLoading.value.length})`
    : `Загрузка ${loadingType.value} завершена`
})

const loadingType = computed(() => {
  const types = resourceLoading.value.map((item) => item.type)

  if (types.includes('video') && !types.includes('image')) {
    return 'видео'
  }

  if (types.includes('image') && !types.includes('video')) {
    return 'фото'
  }

  return 'фото и видеоматериалов'
})

const type = computed(() => {
  return fullLength.value === uploadedLength.value ? 'success' : 'default'
})

watch(
  () => resourceLoading.value,
  (newValue) => {
    if (!newValue.length) return
    isShow.value = !!resourceLoading.value?.length

    if (fullLength.value === uploadedLength.value) {
      setTimeout(() => {
        isShow.value = false
        resourcesStore.setField('resourcesLoading', [])
      }, 512)
    }
  },
  {
    deep: true
  }
)

onMounted(() => {
  isShow.value = !!resourceLoading.value?.length
})
</script>

<template>
  <div v-if="isShow" class="desktop-bar-image-loading">
    <s-icon
      :name="fullLength ? 'fa-spinner' : 'fa-check'"
      :color="`var(--${type})`"
      :spin="!!fullLength"
    />
    <s-text :type="type">
      {{ description }}
    </s-text>
  </div>
</template>

<style>
.desktop-bar-image-loading {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 0.5rem;
}
</style>
