<script setup>
import { computed, inject } from 'vue'
import { useRoute } from 'vue-router'
import JsFileDownloader from 'js-file-downloader'

import { useAuthStore } from '@/stores/auth'
import { useObjectsStore } from '@/stores/objects'
import { useRequests, useSockets } from '@/composables'

import { BASE_URL } from '@/configs/urls'
import { get400ErrorText } from '@/utils/errors'

const route = useRoute()
const { postRequest } = useRequests()
const objectsStore = useObjectsStore()
const authStore = useAuthStore()
const $notify = inject('$notify')

const props = defineProps({
  currentType: {
    type: Object,
    required: true
  },
  currentOptions: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['back'])

const exporting = computed(() => {
  return objectsStore.exporting
})

const token = computed(() => {
  return authStore.userToken
})

const currentDataType = props.currentType.dataType

const selectedToExport = computed(() => {
  const { excavationsToExport, reconsToExport } = objectsStore

  if (currentDataType === 'excavations') {
    return excavationsToExport?.filter((e) => e.checked)
  }

  return reconsToExport?.filter((e) => e.checked)
})

const getSettings = () => {
  const { options } = props.currentType

  const { excavationsToExport, reconsToExport } = objectsStore
  let data

  if (currentDataType === 'excavations') {
    data = excavationsToExport
  } else {
    data = reconsToExport
  }

  let field = props.currentType.selectParam

  const selected = data.filter((e) => e.checked).map((e) => e.server_id)

  const params =
    options?.reduce((prev, el) => {
      prev[el] = props.currentOptions[el]

      return prev
    }, {}) || {}

  if (options?.includes('dates')) {
    params.date_start = props.currentOptions.date_start
    params.date_end = props.currentOptions.date_end
  }

  let settingsResult = {
    options: {
      ...params
    },
    build_in_template: props.currentType.build_in_template
  }

  if (field) {
    settingsResult[field] = selected
  }

  if (currentDataType === 'recons') {
    delete settingsResult.excavations_to_report
  }

  return settingsResult
}

const getUrl = () => {
  const objectId = route.params.id
  const { api } = props.currentType

  return `objects/${objectId}/${api}/exports/`
}

const exportData = async () => {
  try {
    objectsStore.setField('exporting', true)

    const url = getUrl()
    const data = getSettings()

    const task = await postRequest(url, data)

    startSocketConnection(task, downloadReport, errorHandler)
  } catch (error) {
    errorHandler(error)
  }
}

const { startSocketConnection } = useSockets()

const getResultUrl = (reportId) => {
  return `object/exports/${reportId}/result/`
}

const downloadReport = async (_, reportId) => {
  try {
    const { label, extension } = props.currentType
    const { isAdmin } = authStore
    const url = getResultUrl(reportId)

    await new JsFileDownloader({
      method: 'GET',
      headers: [{ name: 'Authorization', value: `Token ${token.value}` }],
      contentType: 'application/json',
      url: `${BASE_URL}/api${isAdmin ? '/admin' : ''}/${url}`,
      filename: `${label}.${extension}`,
      timeout: 400000,
      forceDesktopMode: true
    })

    const title = 'Экспорт данных'
    const message = 'Отчет успешно сформирован и выгружен'
    $notify({
      title,
      message,
      type: 'success'
    })
  } catch (error) {
    errorHandler({ message: 'Ошибка при скачивании готового отчета с сервера' })
  } finally {
    objectsStore.setField('exporting', false)
    emits('back')
  }
}

const errorHandler = (error) => {
  const title = 'Ошибка эспорта'
  let message = `Произошла ошибка при выгрузке отчета. `

  switch (error?.response?.status) {
    case 400: {
      const errorText = get400ErrorText(error)

      message += `Ошибка 400. ${errorText}`
      break
    }
    default:
      message += JSON.stringify(error)
  }

  $notify({
    title,
    message,
    type: 'error',
    duration: 3000
  })

  objectsStore.setField('exporting', false)
}

const disabled = computed(() => {
  return exporting.value || (props.currentType.selectList && !selectedToExport.value.length)
})
</script>

<template>
  <s-button type="success" stretch :disabled="disabled" @click="exportData">
    Экспортировать
  </s-button>
</template>
