<script setup>
import { ref, computed, watch, inject } from 'vue'
import isEqual from 'lodash.isequal'
import cloneDeep from 'lodash.clonedeep'
import { useMq } from 'vue3-mq'
import db from '@/libs/db'

import { useMapStore } from '@/stores/map'
import { useMainStore } from '@/stores/main'
import { useExcavationStore } from '@/stores/excavation'
import { useAuthStore } from '@/stores/auth'
import { useRitmDate, useRequests } from '@/composables'

import { startSteps, drillFields, natureFields } from './config'
import { getExcavationsChanges } from '@/utils/data-sync/excavation'
import { saveImages } from '@/utils/requests/images'

import ProgressComp from './components/start-progress.vue'
import DataStartLocation from '@/components/data-start-location.vue'
import Inputs from './components/start-inputs.vue'
import ImageComp from './components/start-image.vue'

const props = defineProps({
  excavation: {
    type: Object,
    default: () => null
  },
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update-handler', 'toggle'])

const mq = useMq()
const rDate = useRitmDate()
const requests = useRequests()
const mapStore = useMapStore()
const excavStore = useExcavationStore()
const mainStore = useMainStore()
const authStore = useAuthStore()
const $notify = inject('$notify')

const loading = ref(false)
const initSource = ref(props.excavation)
const initSourceClone = ref(cloneDeep(props.excavation))
const activeStep = ref(1)
const files = ref([])
const changed = ref(false)

const hasChanges = computed(() => {
  if (initSourceClone.value && initSource.value) {
    return isEqual(initSourceClone.value, initSource.value)
  }
  return false
})

const userLocation = computed(() => {
  return mapStore.userLocation || null
})

const sourceCenter = computed(() => {
  const { lon: lonValue, lat: latValue } = initSourceClone.value

  if (lonValue && latValue) return [lonValue, latValue]

  const { lon_plan, lat_plan } = initSourceClone.value

  if (lon_plan && lat_plan) return [lon_plan, lat_plan]

  if (!userLocation.value) return [37.6165, 55.752]

  const [lon, lat] = userLocation.value

  return [lon, lat]
})

const buttonName = computed(() => {
  switch (activeStep.value) {
    case 1:
      return 'Далее'
    case 4:
      return 'Завершить'
    default:
      if (changed.value) return 'Далее'
      return 'Пропустить'
  }
})

const buttonType = computed(() => {
  switch (activeStep.value) {
    case 4:
      return 'success'
    default:
      return 'primary'
  }
})

const currentFields = computed(() => {
  switch (activeStep.value) {
    case 2:
      return drillFields
    case 3:
      return natureFields
    default:
      return []
  }
})

watch(
  () => initSourceClone.value,
  () => {
    changed.value = true
  },
  { deep: true }
)

const clickHandler = () => {
  switch (activeStep.value) {
    case 4:
      editExcavation()
      break
    default:
      activeStep.value++
      changed.value = false
      break
  }
}

const editExcavation = async () => {
  const { putRequest } = requests
  loading.value = true

  try {
    initSourceClone.value.date_front = rDate(new Date()).format('iso')
    initSourceClone.value.status = 3
    if (!initSourceClone.value.geologist) {
      initSourceClone.value.geologist = authStore.userId
    }

    const toServer = Object.assign({}, initSourceClone.value)
    const toIdb = Object.assign({}, initSourceClone.value)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      toServer.id = toServer.server_id
      delete toServer.object_id
      toServer.type = null
      await putRequest(`excavations/${toServer.id}/`, toServer)
    }

    await db.updateObject('excavations', toIdb)
    if ((!mainStore.isOnline || mainStore.noSyncMode) && props.excavation.server_id) {
      const updatedItems = await db.updated.where('table').equals('excavations').toArray()
      const isExist = !!updatedItems.find((e) => e.item_id === props.excavation.server_id)
      if (!isExist) {
        await db.updated.put({
          table: 'excavations',
          item_id: props.excavation.server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }

    const title = 'Редактирование'
    const message = `Выработка "${toIdb.title}" успешно отредактирована`
    $notify({
      title,
      message,
      type: 'success'
    })

    emits('update-handler')
    toggleModal()
  } catch (error) {
    const title = 'Редактирование'
    const message = `Ошибка при редактировании выработки ${error}`
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false

    if (files.value?.length) {
      await saveImagesLocal()
      excavStore.setField('updateExcavationImages', true)
    }
  }
}

const toggleModal = () => {
  emits('toggle')
}

const saveImagesLocal = async () => {
  const { server_id, id } = props.excavation

  const data = {
    server_id,
    table: 'excavations',
    item_id: id,
    files: files.value,
    excavation_id: server_id || `idb_${id}`
  }
  await saveImages(data)
}

const setMapCenter = (center) => {
  const [lon, lat] = center

  initSourceClone.value.lon = Number(lon?.toFixed(5))
  initSourceClone.value.lat = Number(lat?.toFixed(5))
}
</script>

<template>
  <s-modal
    title="Подготовка скважины"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :confirm-condition="hasChanges || changed"
    confirm-on-cancel
    @close="toggleModal"
  >
    <div class="excavation-start">
      <progress-comp :active="activeStep" :steps="Object.keys(startSteps)" />
      <data-start-location
        v-if="activeStep === 1"
        :title="startSteps[activeStep].title"
        :center="sourceCenter"
        :source="initSourceClone"
        @save="setMapCenter"
      />
      <inputs
        v-if="activeStep === 2 || activeStep === 3"
        :title="startSteps[activeStep].title"
        :source="initSourceClone"
        :fields="currentFields"
      />
      <image-comp
        v-if="activeStep === 4"
        :title="startSteps[activeStep].title"
        :loading="loading"
        :files="files"
      />
    </div>
    <template #footer>
      <s-button :type="buttonType" :loading="loading" @click="clickHandler">
        {{ buttonName }}
      </s-button>
    </template>
  </s-modal>
</template>

<style lang="scss">
.excavation-start {
  display: grid;
  grid-gap: 1rem;
  overflow: auto;
  align-content: start;
  grid-template-rows: auto 1fr;
  height: 100%;
}
</style>
