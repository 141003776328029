export const getErrorFieldsString = (fields, vuelidate) => {
  const errorFields = []
  vuelidate.$errors.forEach((error) => {
    errorFields.push(error.$property)
  })

  let errorLabels = []

  errorFields.forEach((error) => {
    fields.forEach((field) => {
      if (field.title === error) {
        errorLabels.push(field.label)
      }
    })
  })

  return errorLabels.join(', ')
}
