<script setup>
import { computed, ref } from 'vue'
import { useServicesStore } from '@/stores/services'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const servicesStore = useServicesStore()

const options = ref({
  id: 'state_pfrost',
  title: 'state_pfrost',
  label: 'Укажите состояние грунта',
  clearable: true,
  type: 'select',
  service: 'states_pfrost',
  commentField: 'water_saturation_comments',
  commentTitle: 'Комментарий к состоянию / консистенции'
})

const soilTypes = computed(() => {
  return servicesStore?.soil?.soil_types
})

const soilType = computed(() => {
  return soilTypes.value?.find((e) => e.id === props.source?.soil_type)
})

const services = computed(() => {
  const service = servicesStore?.soil?.[props.field.service]

  return {
    [props.field.service]: service
  }
})

const serviceName = computed(() => {
  if (props.source?.soil_type >= 11201 && props.source?.soil_type <= 11205) {
    return 'sand'
  } else if (props.source?.soil_type >= 12102 && props.source?.soil_type <= 12103) {
    return 'clay'
  } else if (props.source?.soil_type === 12101) {
    return 'sandy_clay'
  } else {
    return 'other'
  }
})

const waterService = computed(() => {
  const soilId = soilType.value?.id
  const soilClass = soilType.value?.soil_class
  const soilGroup = soilType.value?.soil_group
  const soilTypeLocal = soilType.value?.soil_type

  const serviceNameLocal = `water_saturations_${serviceName.value}`

  const service = servicesStore?.soil?.[serviceNameLocal]
  const serviceData =
    service?.filter((s) => {
      if (!s.soil_type && !s.soil_group && !s.soil_class && !s.soil_id) {
        return true
      }
      const suits = s.soil_type
        ? s.soil_class === soilClass && s.soil_group === soilGroup && s.soil_type === soilTypeLocal
        : s.soil_group
          ? s.soil_class === soilClass && s.soil_group === soilGroup
          : s.soil_class
            ? s.soil_class === soilClass
            : false

      if (s.soil_id?.includes(';')) {
        const [from, to] = s.soil_id.split(';')

        const between = soilId >= Number(from) && soilId <= Number(to)

        return (between || suits) && s.id
      } else {
        return (s.soil_id?.includes(String(soilId)) || suits) && s.id
      }
    }) || []

  return {
    [serviceNameLocal]: serviceData
  }
})

const waterField = computed(() => {
  return {
    id: 'water_saturation',
    title: 'water_saturation',
    label: 'Укажите состояние при оттаивании',
    type: 'select',
    service: `water_saturations_${serviceName.value}`
  }
})
</script>

<template>
  <div class="soil-state-pfrost">
    <s-input-renderer :field="options" :source="source" :services="services" />
    <s-input-renderer
      v-if="source.state_pfrost"
      :field="waterField"
      :source="source"
      :services="waterService"
    />
  </div>
</template>

<style lang="scss" scoped>
.soil-state-pfrost {
  display: grid;
  grid-gap: 1rem;
}
</style>
