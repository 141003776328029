<script setup>
import { computed, onMounted, ref } from 'vue'

import { useServicesStore } from '@/stores/services'
import { getSoilFilters, parseSoilsList } from '@/utils/soils'
import { soilsFilters } from './config'

import ListTipComp from '@/components/list-tip.vue'
import SoilTypeItem from './common/soil-type-item.vue'
import SoilState from './common/soil-state-item.vue'
import SelectComp from './soil-select-comp.vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    default: () => {}
  }
})

const emits = defineEmits(['set-soil-type', 'set-soil-type-bak'])

const servicesStore = useServicesStore()

const listTip = {
  title: 'Ничего не найдено',
  description: 'По вашему запросу не удалось найти ни одной вида грунта',
  image: 'filter'
}

const filterText = ref('')
const states = ref({
  techno: {
    id: 'techno',
    title: 'Техногенный',
    value: false
  },
  frozen: {
    id: 'frozen',
    title: 'Мерзлый',
    value: false
  }
})

const initSource = ref(props.source)
const soilTypeCommentsConfig = ref({
  id: 'soil_type_comments',
  title: 'soil_type_comments',
  type: 'textarea',
  label: 'Комментарий к виду грунта',
  maxlength: 250
})
const soilsFiltersLocal = ref(soilsFilters)
const activeType = ref(null)
const activeGroup = ref(null)
const iceField = ref({
  id: 'ice_type',
  service: 'ice_types',
  type: 'select'
})
const technoField = ref({
  id: 'alteration_techno_type',
  service: 'alterations_techno_types',
  type: 'select'
})

const soilsList = computed(() => {
  const list = servicesStore?.soil?.soil_types
  const filter = filterText.value?.trim()?.toLowerCase()

  if (!filter) return list
  const fields = ['title', 'title_short']

  return (
    list?.filter((e) => {
      return fields?.some((f) => e[f]?.toLowerCase()?.includes(filter))
    }) || []
  )
})

const parsedList = computed(() => {
  const typesFilter = soilsFiltersLocal.value.filters.find((filter) => filter.id === 'types')

  return parseSoilsList(soilsList.value, typesFilter)?.filter((e) => e.items?.length)
})

const listEmpty = computed(() => {
  return !parsedList.value.length
})

onMounted(() => {
  const typesFilter = soilsFiltersLocal.value.filters.find((filter) => filter.id === 'types')

  typesFilter.filterValues = getSoilFilters()

  if (initSource.value.state === 2) {
    states.value.frozen.value = true
  }
  if (initSource.value.alteration_techno) {
    states.value.techno.value = true
  }

  if (initSource.value.soil_type) {
    const group = parsedList.value.find((g) =>
      g?.items?.find((t) => t.id === initSource.value.soil_type)
    )
    const type = group?.items?.find((t) => t.id === initSource.value.soil_type)
    setType(type, group)
  }
})

const setType = (type, group) => {
  initSource.value.soil_type = type?.id || null

  activeType.value = type
  activeGroup.value = group
  filterText.value = ''

  if (group?.id === 'ice') {
    initSource.value.state = 2
    states.value.frozen.value = true
  }

  if (group?.id === 'techno') {
    changeState(group.id)
  }

  setSoilType(type)
}

const changeState = (value) => {
  if (value === 'frozen' && activeGroup.value?.id === 'ice') return

  states.value[value].value = !states.value[value].value

  if (value === 'frozen') {
    initSource.value.state = states.value[value].value ? 2 : 1
  }
  if (value === 'techno') {
    initSource.value.alteration_techno = states.value[value]?.value ? 1 : null
  }
  setSoilType()
}

const setSoilType = (type) => {
  const group = parsedList.value.find((g) =>
    g?.items?.find((t) => t.id === initSource.value.soil_type)
  )
  const soilType = group?.items?.find((t) => t.id === initSource.value.soil_type)
  if (!activeGroup.value) {
    emits('set-soil-type', '')
  } else if (type?.title_short === 'ПРС' || soilType?.title_short === 'ПРС') {
    if (states.value?.frozen?.value) {
      emits('set-soil-type', 'frozenPrs')
    } else {
      emits('set-soil-type', 'prs')
    }
  } else if (states.value?.frozen?.value) {
    if (activeGroup.value.id === 'sand') {
      emits('set-soil-type', 'frozenSand')
    } else if (activeGroup.value.id === 'largeScale') {
      emits('set-soil-type', 'frozenLargeScale')
    } else if (activeGroup.value.id === 'rock') {
      emits('set-soil-type', 'frozenRock')
    } else if (activeGroup.value.id === 'ice') {
      emits('set-soil-type', 'ice')
    } else if (activeGroup.value.id === 'techno') {
      emits('set-soil-type', 'techno')
    } else {
      emits('set-soil-type', 'frozen')
    }
  } else if (states.value?.techno?.value) {
    if (activeGroup.value.id === 'clay') {
      emits('set-soil-type', 'technoClay')
    } else if (activeGroup.value.id === 'sand') {
      emits('set-soil-type', 'technoSand')
    } else if (activeGroup.value.id === 'largeScale') {
      emits('set-soil-type', 'technoLargeScale')
    } else if (activeGroup.value.id === 'rock') {
      emits('set-soil-type', 'technoRock')
    } else {
      emits('set-soil-type', 'techno')
    }
  } else {
    emits('set-soil-type', activeGroup.value?.id || '')
  }
  emits('set-soil-type-bak', type)
}
</script>

<template>
  <div class="create-guide-soil-type">
    <div class="create-guide-soil-type-state">
      <soil-state
        v-for="state in states"
        :key="state.id"
        :isFrozenDisabled="activeGroup?.id === 'ice'"
        :item="state"
        @change-state="changeState"
      />
    </div>
    <s-search-block
      v-if="!initSource.soil_type"
      :filter="filterText"
      placeholder="Поиск по виду грунта"
      :filters="soilsFiltersLocal"
      @change="(e) => (filterText = e)"
    />
    <soil-type-item
      v-if="initSource.soil_type && activeType"
      :item="activeType"
      active
      has-cancel
      @cancel="setType(null)"
    />
    <div v-if="!initSource.soil_type && !listEmpty" class="create-guide-soil-type-list">
      <div v-for="group in parsedList" :key="group.id" class="create-guide-soil-type-group">
        <s-text v-if="group.items.length">
          {{ group.title }}
        </s-text>
        <div v-if="group.items.length" class="create-guide-soil-type-content">
          <soil-type-item
            v-for="type in group.items"
            :key="type.id"
            :item="type"
            :frozen="states.frozen.value"
            :techno="states.techno.value"
            @click="setType(type, group)"
          />
        </div>
      </div>
    </div>
    <list-tip-comp v-if="listEmpty" :tip="listTip" />
    <select-comp
      v-if="activeGroup && activeGroup.id === 'ice'"
      title="Вид льда"
      :source="source"
      :soil-interval="soilInterval"
      :field="iceField"
    />
    <select-comp
      v-if="source.alteration_techno || (activeGroup && activeGroup.id === 'techno')"
      title="Вид техногенного грунта"
      :source="source"
      :soil-interval="soilInterval"
      :field="technoField"
    />
    <s-input-renderer
      v-if="initSource.soil_type"
      class="create-guide-soil-type__comments"
      :field="soilTypeCommentsConfig"
      :source="initSource"
    />
  </div>
</template>

<style lang="scss">
.create-guide-soil-type {
  display: grid;
  grid-gap: 1.5rem;

  &__comments {
    grid-column: 1 !important;
  }

  &-state {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);
  }

  &-list {
    display: grid;
    grid-gap: 1.5rem;
    align-content: start;
  }

  &-group {
    display: grid;
    grid-gap: 1rem;
  }

  &-content {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
  }

  > * {
    &.s-input {
      padding-top: 1rem;
    }
  }
}

@include phones {
  .create-guide-soil-type {
    &-content {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
