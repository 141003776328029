import { getAllChanges } from '@/utils/data-sync'
import db from '@/libs/db'
import appGlobalProperties from '@/main'
import { get400ErrorText } from '@/utils/errors'

export const syncErrorHandler = async (error) => {
  let message = `Произошла ошибка при синхронизации данных. ${error}`

  switch (error?.response?.status) {
    case 400: {
      const errorText = get400ErrorText(error)

      message += `. ${errorText}`
      break
    }
    case 403: {
      message += '. Отсутствуют права для редактирования. Обратитесь к руководителю проекта.'
      break
    }
    case 404: {
      message += `. Запрос – ${error.response.config.url}, метод - ${error.config.method}`
      break
    }
    case 409: {
      message += `. Повторите попытку синхронизации`
      const { response } = error
      const { data } = response
      await removeItemFromTable('created', data)
      await getAllChanges()
    }
  }

  appGlobalProperties.$notify({
    message,
    type: 'error'
  })
}

const removeItemFromTable = async (tableName, data) => {
  try {
    if (data?.images?.length || data.table === 'images') {
      const image = await db.images.get({ item_id: data.item_id })
      await db.created.where('item_id').equals(image.id).delete()
      await db.images.where('item_id').equals(data.item_id).delete()
    }

    await db[tableName]
      .where({
        table: data.table,
        item_id: data.item_id
      })
      .delete()
    await db.table(data.table).update(data.item_id, { server_id: data.id })
    getAllChanges()
  } catch (error) {
    console.error('Error: ' + error)
  }
}
