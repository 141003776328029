import { defineStore } from 'pinia'

export const useExcavationStore = defineStore('excavation', {
  state: () => {
    return {
      active: null,
      soilsList: null,
      updateList: false,
      inclusionsList: null,
      interlayersList: null,
      samplesList: null,
      samplesImages: null,
      updateSamples: false,
      updateSampleByID: null,
      updateExcavationImages: false,
      excavationImages: [],
      updateExcavationVideos: false,
      excavationVideos: [],
      groundwaterList: null,
      updateGroundwater: false,
      coreboxesList: null,
      updateCoreboxes: false,
      loadGeologists: false,
      exportModal: false
    }
  },
  actions: {
    setField(field, value) {
      if (field.includes('.')) {
        const subField = field.split('.')

        this[subField[0]][subField[1]] = value
      } else {
        this[field] = value
      }
    },
    excavationAddItem(field, value) {
      this[field].push(value)
    },
    excavationUpdateItem(field, value) {
      const index = this[field]?.findIndex((e) => e.id === value.id)

      if (index > -1) {
        this[field][index] = value
      }
    }
  }
})
