import mapboxgl from 'mapbox-gl'
import router from '@/router'
import { getObjectPercent } from '@/utils/objects'
import { roundValue } from '@/utils/numbers'
import { objectStatuses, objectStatusesColors } from '@/configs/info'

export const getObjectMarkerElement = (props) => {
  const {
    num,
    title_short,
    status,
    server_id,
    stats_excavations_count,
    stats_excavations_count_plan,
    stats_excavations_meters,
    stats_excavations_meters_plan
  } = props
  const el = document.createElement('div')
  const percent = getObjectPercent(stats_excavations_meters, stats_excavations_meters_plan)

  el.className = 'object-marker'
  el.innerHTML = `
      <div class="object-marker__container">
        <div class="object-marker__text">
          <div class="object-marker__number">
            ${num}
          </div>
          <div class="object-marker__title">
            ${title_short}
          </div>
          <div class="object-marker__status">
            Статус: <span class="${objectStatusesColors[status]}">${objectStatuses[status]}</span>
          </div>
        </div>

        <div class="object-marker__stats">
          <div class="object-marker__progress">
            <div class="object-marker__progress-inner" style="width: ${percent.width}px"></div>
            <span>${percent.value}%</span>
          </div>
          <div class="object-marker__counts">
            <span>
            ${roundValue(stats_excavations_count) || 0} / ${
              roundValue(stats_excavations_count_plan) || 0
            }
            </span> скв.
          </div>
          <div class="object-marker__counts">
            <span>
            ${roundValue(stats_excavations_meters) || 0} / ${
              roundValue(stats_excavations_meters_plan) || 0
            }
            </span> п.м.
          </div>
        </div>
      </div>
  `

  el.addEventListener('click', () => {
    router.push(`/app/data/objects/${server_id}`)
  })

  return el
}

export const updateObjectsMarkers = (mapgl, markers, layerId = 'data-objects') => {
  if (!mapgl.getSource(layerId)) return
  if (!mapgl.isSourceLoaded(layerId)) return

  const newMarkers = {}
  const features = mapgl.querySourceFeatures(layerId)
  const filtered = features.filter((p) => !p.properties.cluster)

  for (const feature of filtered) {
    const coords = feature.geometry.coordinates
    const props = feature.properties

    const { id } = props

    let marker = markers.all[id]

    if (!marker) {
      const element = getObjectMarkerElement(props)
      marker = markers.all[id] = new mapboxgl.Marker({
        element
      }).setLngLat(coords)
    }

    newMarkers[id] = marker

    if (markers.onScreen[id]) marker.addTo(mapgl)
  }

  for (const id in markers.onScreen) {
    if (!newMarkers[id]) markers.onScreen[id].remove()
  }
  markers.onScreen = newMarkers
}
