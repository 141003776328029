<script setup>
import { ref, watch, onMounted } from 'vue'
import { useMapStore } from '@/stores/map'
import { LocationController } from './controllers'

import MapButton from '../map-button.vue'

const props = defineProps({
  mapgl: {
    type: Object,
    required: true
  }
})

const mapStore = useMapStore()

const locationController = ref(null)

onMounted(() => {
  locationController.value = new LocationController(props.mapgl)

  props.mapgl.on('style.load', () => {
    setTimeout(() => {
      locationController.value.showLocation()
    }, 500)
  })
})

watch(
  () => mapStore.userLocation,
  () => {
    locationController.value.setLocationOnMap()
  }
)
</script>

<template>
  <div v-if="locationController" class="map-location">
    <div v-tooltip.left="'Мое местоположение'" class="map-location__icon-wrapper">
      <map-button
        icon="location-arrow"
        :loading="mapStore.locationLoading"
        @click="locationController.showLocation('with-fly-to')"
      />
    </div>
  </div>
</template>

<style lang="scss">
.map-location {
  position: absolute;
  right: 16px;
  top: calc(50% + 56px);

  & svg {
    width: 15px;
  }
}
</style>
