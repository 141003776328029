<script setup>
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    validator: (value) => ['primary', 'text', 'caption'].includes(value),
    default: 'primary'
  }
})

const colorValue = computed(() => `var(--${props.color})`)
</script>

<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path :fill="colorValue" d="M5.00586 2.80902L25.3878 13L5.00586 23.191L5.00586 2.80902Z" />
    <rect :fill="colorValue" x="0.505859" width="4" height="32" />
  </svg>
</template>
