<script setup>
import html2pdf from 'html2pdf.js'
import { computed, ref } from 'vue'

import { usePrintStore } from '@/stores/print'
import { useRitmDate } from '@/composables'

const printStore = usePrintStore()
const rDate = useRitmDate()

const downloadPDF = async () => {
  const { title_short } = printStore.dashboard.active
  const element = document.querySelector('#print-page')
  const filename = `${rDate(new Date()).format('YYYY-MM-DD')} ${title_short}.pdf`
  const opt = {
    margin: [0.5, 0.25],
    image: { type: 'jpeg', quality: 0.98 },
    jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' },
    pagebreak: { mode: 'avoid-all', before: '#dp-excavations' },
    filename,
    html2canvas: {
      scale: 3
    }
  }

  loading.value = true

  await html2pdf().from(element).set(opt).save()

  localStorage.removeItem('dashboardPrintData')

  window.close()
}

const showExcavations = computed({
  get() {
    return printStore.showExcavations
  },
  set(value) {
    printStore.setField('showExcavations', value)
  }
})

const loading = ref(false)
</script>

<template>
  <div v-loading="loading" class="dp-controls">
    <div class="dp-controls__settings">
      <s-switch v-model="showExcavations" label="Показать статистику по дням" />
    </div>
    <s-button type="primary" @click="downloadPDF"> Скачать PDF </s-button>
  </div>
</template>

<style lang="scss">
.dp-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
