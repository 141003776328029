<script setup>
import { ref, computed } from 'vue'
import { useMq } from 'vue3-mq'

import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'

import { parseDate } from '@/utils/dates'

import UserAvatar from '@/components/user-avatar.vue'
import ProfileEditor from './profile-editor/profile-editor.vue'

const authStore = useAuthStore()
const mainStore = useMainStore()
const mq = useMq()

const keys = [
  { prop: 'short_name', label: 'Сотрудник' },
  { prop: 'username', label: 'Логин' },
  { prop: 'email', label: 'Эл. почта' },
  { prop: 'company_name', label: 'Компания' },
  { prop: 'position_name', label: 'Должность' }
]

const editingModal = ref(false)
const toggleModal = (value) => {
  editingModal.value = value
}

const user = computed(() => {
  return authStore.user || {}
})
const avatarSize = computed(() => {
  return mq.current === 'lg' ? 80 : 64
})
</script>

<template>
  <section class="profile-info">
    <div class="profile-info__avatar">
      <user-avatar :user="user" :size="avatarSize" />
    </div>
    <div class="profile-info__content">
      <ul class="profile-info__list">
        <li v-for="key in keys" :key="key.prop" class="profile-info__row">
          <div class="profile-info__row-key">
            <s-text type="secondary"> {{ key.label }} </s-text>
          </div>
          <div class="profile-info__row-label">
            <s-text semibold>
              {{ user[key.prop] || '-' }}
            </s-text>
          </div>
        </li>
      </ul>
      <div v-if="mainStore.isOnline" class="profile-info__edit">
        <s-button @click="toggleModal(true)" stretch> Редактировать </s-button>
      </div>
      <div class="profile-info__meta-info">
        <s-text type="secondary" small> Зарегистрирован: {{ parseDate(user.date_joined) }} </s-text>
      </div>
    </div>

    <profile-editor :editing="editingModal" @close="toggleModal(false)" />
  </section>
</template>

<style lang="scss">
.profile-info {
  display: flex;
  gap: 1.5rem;

  &__row {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 0.5rem;
    }

    &-key {
      min-width: 96px;
    }

    &-label {
      margin-left: 8px;
    }
  }

  &__edit {
    margin-top: 1rem;
  }

  &__meta-info {
    margin-top: 1rem;
  }
}

.sm {
  .profile-info {
    flex-direction: column;
  }
}
</style>
