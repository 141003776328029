import { getDataPointNameByType } from '@/utils/strings'

export const getPlanPointConfig = (type) => ({
  type: 'symbol',
  layout: {
    'icon-image': `${getDataPointNameByType(type)}-satelite`,
    'icon-size': 0.75
  },
  paint: {
    'icon-opacity': 0.6
  }
})
