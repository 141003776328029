import { useMapStore } from '@/stores/map'
import { KalmanFilter } from './location-tools'

const LOCATION_ERROR_TEXT = 'Не удалось определить местоположение'
const TIMEOUT_VALUE = 1000

const kalmanLng = new KalmanFilter(1, 1)
const kalmanLat = new KalmanFilter(1, 1)

export const getLocationCoordinates = () => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Сервис геолокации не поддерживается'))
    }

    navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const { longitude, latitude, altitude } = coords

        const filteredLng = kalmanLng.filter(longitude)
        const filteredLat = kalmanLat.filter(latitude)

        const center = [filteredLng, filteredLat]

        resolve({
          center,
          altitude
        })
      },
      (error) => {
        console.log(error)
        reject(new Error(LOCATION_ERROR_TEXT))
      },
      {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 15000
      }
    )
  })
}

export const getUserLocation = async () => {
  const mapStore = useMapStore()

  try {
    mapStore.setLocationLoading(false)

    const { center, altitude } = await getLocationCoordinates()

    mapStore.setLocation(center, altitude)
    mapStore.setLocationLoading(false)
  } catch (error) {
    console.error(LOCATION_ERROR_TEXT)
    mapStore.setLocationLoading(false)
  }
}

export const watchUserLocation = (interval = TIMEOUT_VALUE) => {
  setInterval(() => {
    getUserLocation()
  }, interval)
}
