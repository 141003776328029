<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import { useMq } from 'vue3-mq'
import { useVuelidate } from '@vuelidate/core'

import { rangeValidatorForLayers } from '../../../validator'
import { useServicesStore } from '@/stores/services'
import { inclusionsTemplate } from '../../configs'

import SelectComp from '../soil-select-comp.vue'
import SearchSelectComp from '../soil-search-select-comp.vue'
import SoilInfo from '../soil-info.vue'
import ErrorValidatorText from '@/components/error-validator-text.vue'

const props = defineProps({
  title: {
    type: String,
    default: 'Новое включение'
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  inclusion: {
    type: Object,
    default: () => null
  },
  soilInterval: {
    type: Object,
    required: true,
    default: () => {}
  },
  isEdit: {
    type: Boolean,
    required: false
  },
  source: {
    type: Object,
    required: false
  }
})

const emits = defineEmits(['toggle', 'add-handler', 'remove-handler'])

const servicesStore = useServicesStore()
const mq = useMq()

const initTemplate = ref(null)
const amountField = ref({
  id: 'amount',
  type: 'select',
  service: 'inclusions_amounts'
})
const typeField = ref({
  id: 'type',
  type: 'select',
  service: 'inclusions_types'
})

const warningDepth = computed(() => {
  const { d0, d1 } = initTemplate.value

  if (!d0 && d0 !== 0) return null
  else if (d1 === d0)
    return {
      from: d0,
      to: d1
    }
  else return null
})

const warningTip = computed(() => {
  if (!initTemplate.value) return

  const { d0, d1 } = initTemplate.value

  return `Нельзя создать слой с нулевой мощностью (${d0} - ${d1} м)`
})

const disabled = computed(() => {
  if (!initTemplate.value) return

  const { d0, amount, type } = initTemplate.value

  return (!Number.isInteger(d0) && !d0) || !amount || !type
})

const inclusionTypes = computed(() => {
  return servicesStore.soil?.inclusions_types || []
})

const inclusionType = computed(() => {
  return inclusionTypes.value?.find((e) => e.id === initTemplate.value?.type)?.inclusion_type_1
})

const soilField = computed(() => {
  return {
    id: 'composition',
    type: 'select',
    service: 'soil_types',
    filter: { attr: `inclusion_type_${inclusionType.value}`, value: 1 }
  }
})

watch(
  () => props.isVisible,
  (newValue) => {
    if (!newValue) return
    init()
  }
)

const init = () => {
  if (props.inclusion) {
    initTemplate.value = cloneDeep(props.inclusion)

    if (!initTemplate.value.d0) {
      initTemplate.value.d0 = props.soilInterval.from
    }
    if (!initTemplate.value.d1) {
      initTemplate.value.d1 = props.soilInterval.to
    }
  } else {
    if (!props.isVisible) return

    inclusionsTemplate.d0 = props.soilInterval.from
    inclusionsTemplate.d1 = props.soilInterval.to
    initTemplate.value = cloneDeep(inclusionsTemplate)
  }
}

const toggleModal = () => {
  emits('toggle')
}

const saveHandler = async () => {
  const result = await v$.value.$validate()

  if (!result) {
    return
  }

  const clone = cloneDeep(initTemplate.value)
  const { d0, d1 } = initTemplate.value
  const { from, to } = props.soilInterval

  if (d0 === from) {
    clone.d0 = null
  }

  if (d1 === to) {
    clone.d1 = null
  }

  emits('add-handler', clone)
  toggleModal()
}

const removeHandler = () => {
  emits('remove-handler', initTemplate.value)
  toggleModal()
}

const rules = computed(() => {
  if (props.isEdit && !props?.source?.inclusions?.length) return {}

  return {
    ...rangeValidatorForLayers(
      props.isEdit ? 'Включения' : 'Включение',
      initTemplate.value,
      props.soilInterval,
      'inclusions'
    )
  }
})

const v$ = useVuelidate(rules, initTemplate)

const isWarning = ref(false)

watch(
  () => v$.value.$errors,
  (newValue) => {
    if (newValue.length === 0) {
      isWarning.value = false
      return
    }
    isWarning.value = true
  }
)

onMounted(() => {
  init()
})
</script>

<template>
  <s-modal
    :title="title"
    :show="isVisible"
    :min-height="460"
    :fullscreen="mq.current !== 'lg'"
    @close="toggleModal"
  >
    <div v-if="initTemplate" class="inclusions-editor">
      <div class="inclusions-editor-block__wrapper">
        <div class="inclusions-editor-block">
          <div class="inclusions-editor-block__input">
            <s-number-input
              v-model="initTemplate.d0"
              label="От, м"
              :no-blur="true"
              :no-between-error="true"
              placeholder="0.0"
              data-testid="inclusion-d0"
            />
            <error-validator-text v-if="v$?.d0?.$error" :errorText="v$?.d0.$errors[0].$message" />
          </div>
          <div class="inclusions-editor-block__input">
            <s-number-input
              v-model="initTemplate.d1"
              label="До, м"
              :no-blur="true"
              :no-between-error="true"
              placeholder="0.0"
              data-testid="inclusion-d1"
            />
            <error-validator-text v-if="v$?.d1?.$error" :errorText="v$?.d1.$errors[0].$message" />
          </div>
        </div>
        <soil-info
          v-if="warningDepth"
          :data="{}"
          type="error"
          :tip="warningTip"
          :soil-interval="warningDepth"
        />
      </div>
      <select-comp
        title="Количество"
        :source="initTemplate"
        :field="amountField"
        :soil-interval="soilInterval"
        :filter="false"
      />
      <select-comp
        v-if="initTemplate.amount"
        title="Размер"
        :source="initTemplate"
        :field="typeField"
        :soil-interval="soilInterval"
        :filter="false"
      />
      <search-select-comp
        v-if="initTemplate.type"
        title="Состав"
        :source="initTemplate"
        :field="soilField"
        :soil-interval="soilInterval"
        :filter="soilField.filter"
      />
      <s-input
        v-model="initTemplate.comments"
        type="textarea"
        label="Комментарий к включению"
        placeholder="Дополнительное описание"
      />
    </div>
    <template #footer>
      <div :class="['inclusions-editor-footer', { exist: inclusion }]">
        <s-button
          v-if="inclusion"
          icon="trash-can"
          simple
          icon-color="var(--error)"
          @click="removeHandler"
        />
        <s-button type="success" :disabled="disabled || isWarning" @click="saveHandler">
          {{ inclusion ? 'Сохранить' : 'Добавить' }}
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.inclusions-editor {
  display: grid;
  align-content: start;
  grid-gap: 2rem;
  height: 100%;
  overflow: auto;

  &-block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 1rem;

    &__wrapper {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    &__input {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }
  }

  &-footer {
    display: grid;

    &.exist {
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;
    }
  }
}
</style>
