import { ref } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { SOCKET_URL } from '@/configs/urls'

export function useSockets() {
  const authStore = useAuthStore()
  const { userToken } = authStore

  let sockets = ref({})

  const addSocket = (id, socket) => {
    sockets.value[id] = socket
  }

  const removeSocket = (id) => {
    delete sockets.value[id]
  }

  const startSocketConnection = async ({ id, task_id }, onSuccess, onError) => {
    const fullUrl = `wss://${SOCKET_URL}/status/${task_id}?token=${userToken}`
    const socket = new WebSocket(fullUrl)

    addSocket(id, socket)

    socket.addEventListener('message', async ({ data }) => {
      const parsedData = JSON.parse(data)
      const { task_status } = parsedData

      switch (task_status) {
        case 'SUCCESS': {
          if (onSuccess) {
            await onSuccess(parsedData, id)
          }

          removeSocket(id)
          socket.close()
          break
        }
        case 'FAILURE': {
          if (onError) {
            onError(parsedData)
          }
          socket.close()
        }
      }
    })

    socket.addEventListener('error', (e) => {
      onError(e)
      socket.close()
    })
  }

  const closeAllSockets = () => {
    Object.entries(sockets.value).forEach(([, socket]) => {
      if (socket.readyState === 1) {
        socket.close()
      }
    })

    sockets.value = {}
  }

  return {
    sockets,
    addSocket,
    removeSocket,
    startSocketConnection,
    closeAllSockets
  }
}
