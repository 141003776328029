import { useSyncStore } from '@/stores/sync'
import { useObjectsStore } from '@/stores/objects'
import { useMainStore } from '@/stores/main'
import router from '@/router'
import db from '@/libs/db'

export const goToRoute = async (path, route) => {
  const syncStore = useSyncStore()
  const mainStore = useMainStore()
  const objectsStore = useObjectsStore()
  if (syncStore.loading || path === route.fullPath) return

  objectsStore.setField('activeObject', null)
  const wasObject =
    route.fullPath.includes('object') ||
    route.fullPath.includes('excavation') ||
    route.fullPath.includes('recon')
  const wasBook = route.fullPath.includes('book')
  const field = wasObject ? 'lastObjectPath' : wasBook ? 'lastBookPath' : ''

  mainStore.setLastRoutePath([field, route.fullPath])

  if (path.includes('object')) {
    let routePath = !wasObject ? mainStore.lastRoutePaths.lastObjectPath || path : path

    if (routePath.includes('excavation?id=idb_')) {
      const idbId = routePath.split('idb_')[1]
      const excavItem = await db.excavations.get({ id: Number(idbId) })
      if (excavItem.server_id) {
        router.push({ name: 'excavation', query: { id: excavItem.server_id } })
      }
      return
    }

    if (path === '/app/data/objects' && route.fullPath === '/app/data/objects/list') return

    router.push(routePath)
  } else if (path.includes('books')) {
    if (path === '/app/books' && route.fullPath === '/app/books/list') return
    const routePath = !wasBook ? mainStore.lastRoutePaths.lastBookPath || path : path
    if (routePath === route.fullPath) return

    router.push(routePath)
  } else {
    router.push(path)
  }
}
