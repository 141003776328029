import { dynamicRangeValidator } from '@/validators'

const MIN_VALUE = 0
const MAX_VALUE = 12262

export const schliereValidator = (source) => ({
  ice_schliere_width_from: dynamicRangeValidator(
    'Ширина шлиров (От)',
    MIN_VALUE,
    source.ice_schliere_width_to || MAX_VALUE
  ),
  ice_schliere_width_to: dynamicRangeValidator(
    'Ширина шлиров (До)',
    source.ice_schliere_width_from < MIN_VALUE
      ? MIN_VALUE
      : source.ice_schliere_width_from || MIN_VALUE,
    MAX_VALUE
  ),
  ice_schliere_interval_from: dynamicRangeValidator(
    'Расстояние между шлирами (От)',
    MIN_VALUE,
    source.ice_schliere_interval_to || MAX_VALUE
  ),
  ice_schliere_interval_to: dynamicRangeValidator(
    'Расстояние между шлирами (До)',
    source.ice_schliere_interval_from < MIN_VALUE
      ? MIN_VALUE
      : source.ice_schliere_interval_from || MIN_VALUE,
    MAX_VALUE
  )
})

export const rangeValidatorForLayers = (fieldName, source, interval, layer) => {
  if (!source) return
  const maxValue = interval.to

  return {
    d0: dynamicRangeValidator(
      `${fieldName} (От)`,
      source.d0 < 0 ? MIN_VALUE : interval.from,
      source.d1,
      true,
      layer
    ),
    d1: dynamicRangeValidator(
      `${fieldName} (До)`,
      source.d0 < MIN_VALUE ? MIN_VALUE : source.d0 || MIN_VALUE,
      maxValue,
      true,
      layer
    )
  }
}
