import db from '@/libs/db'

import { useMainStore } from '@/stores/main'
import { useAuthStore } from '@/stores/auth'
import { useRequests } from '@/composables'

import { getExcavationsChanges } from '@/utils/data-sync/excavation'
import appGlobalProperties from '@/main'

export const editExcavation = async (excavation, loading, callback, filter) => {
  const { putRequest } = useRequests()
  const mainStore = useMainStore()
  const authStore = useAuthStore()

  loading = true

  try {
    excavation.date_front = new Date()

    if (!excavation.geologist) {
      excavation.geologist = authStore.userId
    }

    const toServer = Object.assign({}, excavation)
    const toIdb = Object.assign({}, excavation)

    if (mainStore.isOnline && !mainStore.noSyncMode) {
      toServer.id = toServer.server_id
      delete toServer.object_id
      toServer.type = null
      await putRequest(`excavations/${toServer.id}/`, toServer)
    }

    await db.updateObject('excavations', toIdb, filter)

    if ((!mainStore.isOnline || mainStore.noSyncMode) && excavation.server_id) {
      const updatedItems = await db.updated.where('table').equals('excavations').toArray()
      const isExist = !!updatedItems.find((e) => e.item_id === excavation.server_id)

      if (!isExist) {
        await db.updated.put({
          table: 'excavations',
          item_id: excavation.server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }

    const title = 'Редактирование'
    const message = `Выработка "${toIdb.title}" успешно отредактирована`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'success'
    })
    if (callback) {
      callback(true)
    }
  } catch (error) {
    const title = 'Редактирование'
    const message = `Ошибка при редактировании выработки ${error}`
    appGlobalProperties.$notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading = false
  }
}
