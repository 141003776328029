<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { goToRoute } from '@/utils/routes'
import { useSyncStore } from '@/stores/sync'

const route = useRoute()
const syncStore = useSyncStore()

const links = [
  { id: '/app/home', label: 'Главная', icon: 'house' },
  { id: '/app/data/objects', label: 'Проекты', icon: 'database' },
  { id: '/app/profile', label: 'Профиль', icon: 'user' },
  { id: '/app/menu', label: 'Меню', icon: 'bars' }
]

const unSyncedCount = computed(() => {
  const unSynced = [...syncStore.changesList, ...syncStore.dataChangesList] || []
  return unSynced.length || 0
})

const isActive = (path) => {
  if (path.includes('data')) {
    return route.fullPath?.includes('/data')
  }
  return route.fullPath?.includes(path)
}
</script>

<template>
  <ul v-loading="syncStore.loading" class="mobile-navbar__list">
    <li v-for="link in links" :key="link.id" class="mobile-navbar__item">
      <div
        :class="['mobile-navbar__link', { active: isActive(link.id) }]"
        @click="goToRoute(link.id, route)"
      >
        <template v-if="link.id === '/app/home'">
          <div v-badge="unSyncedCount">
            <s-icon :name="link.icon" size="lg" />
          </div>
        </template>
        <template v-else>
          <s-icon :name="link.icon" size="lg" />
        </template>
        <s-text type="secondary">{{ link.label }}</s-text>
      </div>
    </li>
  </ul>
</template>

<style lang="scss">
.mobile-navbar {
  &__list {
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }

  &__item {
    flex: 1;
    height: 100%;

    .s-text {
      margin-top: 2px;
    }
  }

  &__link {
    cursor: pointer;
    height: 100%;
    text-decoration: none;
    color: var(--text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 2px;

    &.active {
      .s-text,
      .s-icon {
        color: var(--primary) !important;
      }
    }
  }
}
</style>
