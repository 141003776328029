<script setup>
import { useMq } from 'vue3-mq'
import { onBeforeRouteLeave } from 'vue-router'

import { ObjectEditorSteps } from '../../../object-editor/config'

import DesktopInfo from './components/desktop-info.vue'
import MobileInfo from './components/mobile-info.vue'

const mq = useMq()

onBeforeRouteLeave(() => {
  ObjectEditorSteps.forEach((step) => (step.warning = false))
})
</script>

<template>
  <div class="info-page">
    <desktop-info v-if="mq.current === 'lg'" />
    <mobile-info v-else />
  </div>
</template>

<style lang="scss" scoped>
.info-page {
  display: flex;
  overflow: auto;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  max-height: calc(100vh - 180px);

  &__content {
    display: flex;
    gap: 160px;
  }
}
</style>
