<script setup>
import { computed, ref, watch } from 'vue'

import { useSyncStore } from '@/stores/sync'
import { useMainStore } from '@/stores/main'

import syncContentListItem from './sync-content-list-item.vue'

const mainStore = useMainStore()
const syncStore = useSyncStore()

const emits = defineEmits(['sync'])

const filterText = ref('')

const loading = computed(() => {
  return syncStore.loading
})

const changesList = computed(() => {
  return (
    syncStore.changesList?.map((e) => {
      if (!e.updates) {
        e.updates = []
      }

      if (e.action === 'deleted') return e
      if (e.isUpdated && e.updates?.findIndex((u) => u.id === 1) === -1) {
        e.updates.push({ id: 1, title: 'Базовая информация' })
      }
      if (e.soils?.length && e.updates?.findIndex((u) => u.id === 2) === -1) {
        e.updates.push({ id: 2, title: 'Описание слоев' })
      }
      if (
        (e.interlayers?.length || e.inclusions?.length) &&
        e.updates?.findIndex((u) => u.id === 3) === -1
      ) {
        e.updates.push({ id: 3, title: 'Прослои / Включение' })
      }
      if (e.groundwater?.length && e.updates?.findIndex((u) => u.id === 4) === -1) {
        e.updates.push({ id: 4, title: 'УГВ' })
      }
      if (
        (e.samples?.length || e.sampleImage?.length) &&
        e.updates?.findIndex((u) => u.id === 5) === -1
      ) {
        e.updates.push({ id: 5, title: 'Пробы' })
      }
      if (e.coreboxes?.length && e.updates?.findIndex((u) => u.id === 6) === -1) {
        e.updates.push({ id: 6, title: 'Фото керна' })
      }
      if (e.images?.length && e.updates?.findIndex((u) => u.id === 7) === -1) {
        e.updates.push({ id: 7, title: 'Фото' })
      }
      return e
    }) || []
  )
})

const dataChangesList = computed(() => {
  return (
    syncStore.dataChangesList?.map((e) => {
      if (!e.updates) {
        e.updates = []
      }

      if (e.action === 'deleted') return e

      if (e.isUpdated && e.updates.findIndex((u) => u.id === 1) === -1) {
        e.updates.push({ id: 1, title: 'Базовая информация' })
      }

      if (e.images?.length) {
        e.images.forEach((image) => {
          if (image.data && image.data.image_type === 'images') {
            if (e.updates.findIndex((u) => u.id === 2) === -1) {
              e.updates.push({ id: 2, title: 'Фото общего вида' })
            }
          } else if (image.data && image.data.image_type === 'journal') {
            if (e.updates.findIndex((u) => u.id === 7) === -1) {
              e.updates.push({ id: 7, title: 'Фото журнала' })
            }
          } else {
            if (e.updates.findIndex((u) => u.id === 7) === -1) {
              e.updates.push({ id: 7, title: 'Фото' })
            }
          }
        })
      }

      return e
    }) || []
  )
})

const initialChangesList = computed(() => {
  return syncStore.initialChangesList || []
})

const initialDataList = computed(() => {
  return syncStore.initialDataList || []
})

const percentage = computed(() => {
  return (syncStore.currentSyncCounter * 100) / totalChanges.value
})

const filteredList = computed(() => {
  const filterTextLocal = filterText.value?.trim()?.toLowerCase()
  if (!filterTextLocal) {
    return [...changesList.value, ...dataChangesList.value]
  }

  return [...changesList.value, ...dataChangesList.value].filter(
    (e) =>
      e.title?.toLowerCase().includes(filterTextLocal) ||
      e.object?.toLowerCase().includes(filterTextLocal)
  )
})

const progressText = computed(() => {
  return `Шаг: ${syncStore.currentSyncCounter} / ${totalChanges.value}`
})

const totalChanges = ref(initialChangesList.value.length + initialDataList.value.length)
const syncData = async () => {
  emits('sync')
}

const allDataSynced = computed(() => {
  return (
    !changesList.value.length &&
    !initialChangesList.value.length &&
    !dataChangesList.value.length &&
    !initialDataList.value.length
  )
})

watch(
  () => allDataSynced.value,
  (newV) => {
    if (newV) {
      syncStore.setField('currentSyncCounter', 0)
    }
  }
)
</script>

<template>
  <s-alert
    v-if="allDataSynced"
    title="Все материалы успешно синхронизированы"
    icon="check-circle"
    type="success"
  />
  <s-div v-else stretch>
    <div class="sync-content">
      <s-input v-model="filterText" placeholder="Поиск" />
      <ul class="sync-content-list">
        <sync-content-list-item v-for="item in filteredList" :key="item.id" :info="item" />
        <s-text v-if="!filteredList.length"> Нет выработок в соответствие с поиском </s-text>
      </ul>
      <div class="sync-content__footer">
        <s-progress :value="percentage">
          {{ progressText }}
        </s-progress>
        <s-button
          type="success"
          :disabled="!mainStore.isOnline"
          :loading="loading"
          stretch
          @click="syncData"
        >
          {{ loading ? 'Синхронизация' : 'Синхронизировать все' }}
        </s-button>
      </div>
    </div>
  </s-div>
</template>

<style lang="scss">
.sync-content {
  position: relative;
  align-content: start;
  display: grid;
  grid-gap: 1rem;
  overflow: hidden;
  height: calc(100vh - 280px) !important;
  grid-auto-rows: auto 1fr auto;
  width: 100%;

  &-list {
    overflow: auto;
    display: grid;
    align-content: start;
    grid-gap: 1rem;
    overflow: auto;
  }

  &__footer {
    display: grid;
    align-self: end;
    grid-gap: 1rem;
  }
}

@include phones {
  .sync-content {
    height: calc(100vh - 240px) !important;
  }
}
</style>
