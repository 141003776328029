export const getTitlesArrayFromIds = (idsArray, items, compareBy, field, secondFiield) => {
  return idsArray.map((itemFrom) => {
    const foundItem = items?.find((item) => {
      return item[compareBy] === itemFrom
    })

    if (!foundItem) {
      return null
    }

    return foundItem[field] || foundItem[secondFiield]
  })
}

export const getFormattedStringFromArray = (stringArray) => {
  if (!stringArray.length) return ''
  if (stringArray.length === 1) return stringArray[0]
  if (stringArray.length === 2) return `${stringArray[0]} и ${stringArray[1]}`
  if (stringArray.length === 3) return `${stringArray[0]}, ${stringArray[1]} и ${stringArray[2]}`

  if (stringArray.length > 3) {
    const withOutLast = stringArray.slice(0, -1)
    const lastElement = stringArray[stringArray.length - 1]

    return `${withOutLast.join(', ')} и ${lastElement}`
  }
}

export const getCommaSeparatedString = (stringArray) => {
  return stringArray.join(', ')
}
