import { useObjectsStore } from '@/stores/objects'
import { getGeojson } from '@/utils/map/common'
import { objectClustersConfig } from '@/utils/map/objects-clusters'
import { updateObjectsMarkers } from '@/utils/map/objects'
import { flyToFeatures } from '@/utils/map/fly'

export class ObjectsController {
  constructor(mapgl) {
    const objectsStore = useObjectsStore()

    this.mapgl = mapgl
    this.$store = objectsStore
    this.layerId = 'data-objects'
    this.markers = {
      all: {},
      onScreen: {}
    }

    this.renderHandler = this.renderHandler.bind(this)
  }

  showObjects(type = 'default') {
    const { objectsList } = this.$store

    if (!objectsList) return

    const data = getGeojson(
      objectsList.map((p) => ({
        ...p,
        x: p.centroid_lat || 0,
        y: p.centroid_lon || 0
      }))
    )

    const source = this.mapgl.getSource(this.layerId)

    if (source) {
      source.setData(data)
    } else {
      this.mapgl.addSource(this.layerId, {
        type: 'geojson',
        data,
        ...objectClustersConfig.source
      })

      this.mapgl.addLayer({
        id: `${this.layerId}-clusters`,
        source: this.layerId,
        ...objectClustersConfig.clusters
      })

      this.mapgl.addLayer({
        id: `${this.layerId}-clusters-count`,
        source: this.layerId,
        ...objectClustersConfig.clustersCount
      })

      this.mapgl.on('render', this.renderHandler)
    }

    if (type === 'from-style') return

    flyToFeatures(this.mapgl, data)
  }

  renderHandler() {
    updateObjectsMarkers(this.mapgl, this.markers)
  }

  removeObjects() {
    if (this.mapgl.getLayer(`${this.layerId}-clusters`)) {
      this.mapgl.removeLayer(`${this.layerId}-clusters`)
    }

    if (this.mapgl.getLayer(`${this.layerId}-clusters-count`)) {
      this.mapgl.removeLayer(`${this.layerId}-clusters-count`)
    }

    if (this.mapgl.getSource(this.layerId)) {
      this.mapgl.removeSource(this.layerId)
    }

    for (const id in this.markers.onScreen) {
      this.markers.onScreen[id].remove()
    }

    this.mapgl.off('render', this.renderHandler)
  }
}
