import { useMainStore } from '@/stores/main'
import { useAuthStore } from '@/stores/auth'

export const parseImage = async (image, quality = 1.0, maxSize = 20000000) => {
  if (!image.type.match(/image.*/)) {
    console.log(image.name, '- is not a file')
    return
  }

  return new Promise((resolve, reject) => {
    const img = new Image()
    const reader = new FileReader()

    reader.onload = (e) => {
      img.src = e.target.result
      img.onload = () => {
        const blob = resizeImage(img, image.type, quality, maxSize)
        resolve(blob)
      }
    }
    img.onerror = reject
    reader.readAsDataURL(image)
  })
}

const resizeImage = async (image, type, quality, maxSize) => {
  const maxWidth = 1920
  const maxHeight = 1080
  let canvas = document.createElement('canvas')

  canvas.width = image.width
  canvas.height = image.height

  const ctx = canvas.getContext('2d')

  ctx.save()
  ctx.drawImage(image, 0, 0)
  ctx.restore()

  const ratio = canvas.width / canvas.height
  let mWidth = Math.min(maxWidth, ratio * maxHeight)

  if (maxSize > 0 && maxSize < (canvas.width * canvas.height) / 1000000) {
    const mSize = Math.floor(Math.sqrt(maxSize * ratio) * 1000)

    mWidth = mWidth > 0 ? Math.min(mWidth, mSize) : mSize
  }
  if (mWidth <= 0) {
    mWidth = 1
    console.warning('ImageUploader: image size is too small')
  }

  if (canvas.width > mWidth) {
    canvas = scaleCanvas(canvas, mWidth)
  }

  const imageData = canvas.toDataURL(type, quality)

  const blobImage = await fetch(imageData)
    .then((res) => res.blob())
    .then((blob) => blob)

  return blobImage
}

const scaleCanvas = (canvas, maxWidth) => {
  const scaledCanvas = document.createElement('canvas')
  const scale = maxWidth / canvas.width

  scaledCanvas.width = canvas.width * scale
  scaledCanvas.height = canvas.height * scale

  const srcImgData = canvas.getContext('2d').getImageData(0, 0, canvas.width, canvas.height)
  const destImgData = scaledCanvas
    .getContext('2d')
    .createImageData(scaledCanvas.width, scaledCanvas.height)

  applyBilinearInterpolation(srcImgData, destImgData, scale)
  scaledCanvas.getContext('2d').putImageData(destImgData, 0, 0)

  return scaledCanvas
}

const applyBilinearInterpolation = (srcCanvasData, destCanvasData, scale) => {
  function inner(f00, f10, f01, f11, x, y) {
    const un_x = 1.0 - x
    const un_y = 1.0 - y
    return f00 * un_x * un_y + f10 * x * un_y + f01 * un_x * y + f11 * x * y
  }
  let i, j
  let iyv, iy0, iy1, ixv, ix0, ix1
  let idxD, idxS00, idxS10, idxS01, idxS11
  let dx, dy
  let r, g, b, a
  for (i = 0; i < destCanvasData.height; ++i) {
    iyv = i / scale
    iy0 = Math.floor(iyv)
    // Math.ceil can go over bounds
    iy1 = Math.ceil(iyv) > srcCanvasData.height - 1 ? srcCanvasData.height - 1 : Math.ceil(iyv)
    for (j = 0; j < destCanvasData.width; ++j) {
      ixv = j / scale
      ix0 = Math.floor(ixv)
      // Math.ceil can go over bounds
      ix1 = Math.ceil(ixv) > srcCanvasData.width - 1 ? srcCanvasData.width - 1 : Math.ceil(ixv)
      idxD = (j + destCanvasData.width * i) * 4
      // matrix to vector indices
      idxS00 = (ix0 + srcCanvasData.width * iy0) * 4
      idxS10 = (ix1 + srcCanvasData.width * iy0) * 4
      idxS01 = (ix0 + srcCanvasData.width * iy1) * 4
      idxS11 = (ix1 + srcCanvasData.width * iy1) * 4
      // overall coordinates to unit square
      dx = ixv - ix0
      dy = iyv - iy0
      // I let the r, g, b, a on purpose for debugging
      r = inner(
        srcCanvasData.data[idxS00],
        srcCanvasData.data[idxS10],
        srcCanvasData.data[idxS01],
        srcCanvasData.data[idxS11],
        dx,
        dy
      )
      destCanvasData.data[idxD] = r
      g = inner(
        srcCanvasData.data[idxS00 + 1],
        srcCanvasData.data[idxS10 + 1],
        srcCanvasData.data[idxS01 + 1],
        srcCanvasData.data[idxS11 + 1],
        dx,
        dy
      )
      destCanvasData.data[idxD + 1] = g
      b = inner(
        srcCanvasData.data[idxS00 + 2],
        srcCanvasData.data[idxS10 + 2],
        srcCanvasData.data[idxS01 + 2],
        srcCanvasData.data[idxS11 + 2],
        dx,
        dy
      )
      destCanvasData.data[idxD + 2] = b
      a = inner(
        srcCanvasData.data[idxS00 + 3],
        srcCanvasData.data[idxS10 + 3],
        srcCanvasData.data[idxS01 + 3],
        srcCanvasData.data[idxS11 + 3],
        dx,
        dy
      )
      destCanvasData.data[idxD + 3] = a
    }
  }
}

export const loadImage = async (image, blobImages) => {
  const mainStore = useMainStore()
  const authStore = useAuthStore()

  let item

  if (typeof image === 'string') {
    return image
  }

  if (image.blob || image.file) {
    item = URL.createObjectURL(image.blob || image.file)
  } else if (image.image_url && mainStore.isOnline) {
    try {
      item = null
      if (blobImages.value[image.id]) {
        item = URL.createObjectURL(blobImages.value[image.id])
      } else {
        const res = await fetch(`${image?.image_url}?access_token=${authStore.userToken}`)
        const blob = await res.blob()
        blobImages.value[image.id] = blob
        item = URL.createObjectURL(blob)
      }
      return item
    } catch (e) {
      console.log(e)
    }
  }
  return item
}
