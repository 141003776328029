<script setup>
import { computed, ref } from 'vue'

import { useObjectsStore } from '@/stores/objects'
import { useServicesStore } from '@/stores/services'
import { useMainStore } from '@/stores/main'

import { cardsConfig } from '../config'
import { objectStatuses, objectStatusesColors } from '@/configs/info.js'

import { getTitlesArrayFromIds } from '@/utils/arrays'
import { getCommaSeparatedString } from '@/utils/arrays'

import objectEditorMobile from '@/modules/app/data/objects/object-editor/object-editor-mobile.vue'

const objectsStore = useObjectsStore()
const serviceStore = useServicesStore()
const mainStore = useMainStore()

const getValueClass = (field) => {
  if (field === 'status') {
    return `${statusColor.value}-color`
  }
}

const statusColor = computed(() => {
  return objectStatusesColors[currentActiveObject.value?.status]
})

const status = computed(() => {
  return objectStatuses[currentActiveObject.value?.status]
})
const currentActiveObject = computed(() => {
  return objectsStore?.activeObject
})

const getValue = (field) => {
  switch (field) {
    case 'status': {
      return status
    }
    case 'archived':
      return currentActiveObject.value ? 'Да' : 'Нет'
    case 'geologistList': {
      const geologists = currentActiveObject.value.geologists
      const findBy = 'short_name'
      const arrayOfValues = getTitlesArrayFromIds(
        geologists,
        serviceStore.users,
        'id',
        findBy,
        'title'
      )

      return getCommaSeparatedString(arrayOfValues)
    }
    case 'boreMasters': {
      const boreMasters = currentActiveObject.value.bore_masters
      const findBy = 'title'
      const arrayOfValues = getTitlesArrayFromIds(
        boreMasters,
        serviceStore.bore_masters,
        'id',
        findBy
      )

      return getCommaSeparatedString(arrayOfValues)
    }
    case 'boreMachines': {
      const boreMasters = currentActiveObject.value.bore_machines
      const findBy = 'title'
      const arrayOfValues = getTitlesArrayFromIds(
        boreMasters,
        serviceStore.bore_machines,
        'id',
        findBy
      )

      return getCommaSeparatedString(arrayOfValues)
    }
    case 'client_title':
      return returnValueFromServices('client_title')
    case 'contractor_title':
      return returnValueFromServices('contractor_title')
    default:
      return currentActiveObject.value[field]
  }
}

const returnValueFromServices = (serviceTitle) => {
  if (serviceTitle === 'contractor_title') {
    const foundedContractor = serviceStore.contractors?.find(
      (contractor) => contractor.id === currentActiveObject.value.contractor
    )

    return foundedContractor?.title || ''
  }
  if (serviceTitle === 'client_title') {
    const foundedClient = serviceStore?.clients?.find((client) => {
      return client.id === currentActiveObject.value.client
    })

    return foundedClient?.title || ''
  }
}

const isShow = ref(false)

const toggleModal = () => {
  if (disabled.value) return
  isShow.value = !isShow.value
}

const disabled = computed(() => {
  return !mainStore.isOnline
})
</script>

<template>
  <s-button @click="toggleModal" v-if="!disabled" stretch>Редактировать</s-button>

  <div class="read-only-info" @click="toggleModal" v-if="currentActiveObject">
    <div
      v-for="(card, idx) in cardsConfig"
      :key="idx"
      class="read-only-info-card"
      @click="openModal"
    >
      <div v-if="card.header" class="read-only-info-card__header">
        <ul>
          <li v-for="header in card.header" :key="header.field" class="read-only-info-card__item">
            <s-text>
              {{ header.labelMobile }}
            </s-text>
            <s-title type="small">
              {{ getValue(header.field) }}
            </s-title>
          </li>
        </ul>
      </div>
      <div class="read-only-info-card__body">
        <ul>
          <li
            v-for="body in card.body"
            :key="body.field"
            class="read-only-info-card__item"
            :class="{ divider: body.divider }"
          >
            <s-text>
              {{ body.labelMobile ? body.labelMobile : body.label }}
            </s-text>
            <s-title type="small" :color-type="getValueClass(body.field)">
              {{ getValue(body.field) }}
            </s-title>
          </li>
        </ul>
      </div>
      <div v-if="card.footer" class="read-only-info__footer">
        <ul>
          <li v-for="footer in card.footer" :key="footer.field" class="read-only-info-card__item">
            <s-text>{{ footer.label }}</s-text>
            <s-title type="small">
              {{ getValue(footer.field) }}
            </s-title>
          </li>
        </ul>
      </div>
    </div>

    <object-editor-mobile
      v-if="isShow"
      :is-visible="isShow"
      :object="currentActiveObject"
      @toggle="toggleModal"
    />
  </div>
</template>

<style lang="scss">
.read-only-info {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &-card:not(:first-child) {
    & .card__body {
      padding: 16px;
    }
  }

  &-card {
    background: var(--bg);
    border: 1px solid var(--dark-text);
    border-radius: var(--border-radius);

    &__item {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      grid-template-columns: 160px 1fr;
      padding-bottom: 12px;
      padding: 8px 16px;

      &.divider {
        border-bottom: 1px solid var(--main-bg);
      }

      @include phones {
        grid-template-columns: 104px 1fr;
      }

      & h2 {
        font-weight: 600 !important;
      }
    }

    &__item:last-child {
      padding-bottom: 12px;
    }
  }

  &-card__header {
    border-bottom: 1px solid var(--main-bg);

    & .card__item {
      padding: 16px;
    }
  }
}

.light-theme {
  & .card {
    border: 1px solid var(--main-bg);

    &__basic {
      border-top: 1px solid var(--main-bg);
      border-bottom: 1px solid var(--main-bg);
    }
  }
}
</style>
