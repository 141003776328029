<script setup>
import { computed, onMounted, ref, inject } from 'vue'
import { useMq } from 'vue3-mq'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import db from '@/libs/db'

import { useExcavationStore } from '@/stores/excavation'
import { useMainStore } from '@/stores/main'
import { useRequests } from '@/composables'

import { groundwaterTemplate } from './config'
import { deleteObject } from '@/utils/requests'
import { getExcavationsChanges } from '@/utils/data-sync/excavation'
import { getUuid } from '@/utils/browser'

import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  excavation: {
    type: Object,
    required: true
  },
  editorName: {
    type: String,
    default: null
  },
  isVisible: {
    type: Boolean,
    default: false
  },
  activeObject: {
    type: Object,
    default: null
  },
  soilsList: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['toggle'])

const excavationStore = useExcavationStore()
const mainStore = useMainStore()
const { postRequest, putRequest } = useRequests()
const $notify = inject('$notify')
const mq = useMq()

const loading = ref(false)
const groundwaterTemplateLocal = ref(null)
const initSource = ref(null)

const appearField = ref({
  id: 'appear_date',
  title: 'appear_date',
  label: 'Дата измерения',
  format: 'dd.MM.yyyy HH:mm',
  excav_date_from: new Date(props.excavation.date_from),
  width: 'half',
  type: 'date',
  enableTimePicker: true,
  dateOptions: {
    min: null,
    max: 'today',
    condition: {
      field: 'fixed_date',
      value: 'less',
      lessThenCurrentDay: true
    }
  }
})
const fixedField = ref({
  id: 'fixed_date',
  title: 'fixed_date',
  format: 'dd.MM.yyyy HH:mm',
  label: 'Дата измерения',
  enableTimePicker: true,
  width: 'half',
  type: 'date',
  dateOptions: {
    min: null,
    max: null,
    condition: {
      field: 'appear_date',
      value: 'more'
    }
  }
})

const hasChanges = computed(() => {
  return !isEqual(initSource.value, groundwaterTemplateLocal.value)
})

const title = computed(() => {
  return props.activeObject ? 'Редактирование УГВ' : 'Создание УГВ'
})

const disabled = computed(() => {
  if (loading.value) return true
  return (
    (!groundwaterTemplateLocal.value.level_d && groundwaterTemplateLocal.value.level_d !== 0) ||
    !groundwaterTemplateLocal.value.appear_date
  )
})

const minValue = computed(() => {
  return 0
})

const maxValue = computed(() => {
  let max = 0

  props.soilsList?.forEach((e) => {
    max = e.foot_d > max ? e.foot_d : max
  })

  return max
})

const handleDelete = async () => {
  await deleteObject(groundwaterTemplateLocal.value, 'groundwater', updateGroundwater)
  emits('toggle')
}

const updateGroundwater = () => {
  excavationStore.setField('updateGroundwater', true)
}

const saveHandler = () => {
  if (!props.activeObject) {
    createHandler()
  } else {
    updateHandler()
  }
}

const createHandler = async () => {
  loading.value = true

  try {
    groundwaterTemplateLocal.value.date_front = new Date()
    groundwaterTemplateLocal.value.uuid = getUuid()
    const data = cloneDeep(groundwaterTemplateLocal.value)
    const idbData = cloneDeep(groundwaterTemplateLocal.value)
    const excavationId = props.excavation?.server_id || `idb_${props.excavation.id}`

    idbData.excavation_id = String(excavationId)
    if (mainStore.isOnline && !mainStore.noSyncMode && props.excavation?.server_id) {
      const response = await postRequest(`excavations/${excavationId}/groundwater/`, data)

      if (!response) return

      idbData.server_id = response?.id
    }

    const idbId = await db.addObject('groundwater', idbData, {
      field: 'excavation_id',
      value: excavationId
    })

    if (!mainStore.isOnline || mainStore.noSyncMode) {
      await db.created.add({
        table: 'groundwater',
        date: new Date(),
        item_id: idbId
      })
      getExcavationsChanges()
    }

    const title = 'Создание'
    const message = 'УГВ успешно сохранен'
    $notify({
      title,
      message,
      type: 'success'
    })
  } catch (e) {
    const title = 'Ошибка'
    const message = 'Произошла ошибка при создании УГВ'
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
    emits('toggle')
  }
}

const updateHandler = async () => {
  loading.value = true

  try {
    groundwaterTemplateLocal.value.date_front = new Date()
    const data = cloneDeep(groundwaterTemplateLocal.value)
    const idbData = cloneDeep(groundwaterTemplateLocal.value)
    const excavationId = props.excavation?.server_id || `idb_${props.excavation.id}`

    idbData.excavation_id = String(excavationId)
    if (mainStore.isOnline && !mainStore.noSyncMode && groundwaterTemplateLocal.value.server_id) {
      delete data.server_id
      delete data.excavation_id
      data.id = groundwaterTemplateLocal.value.server_id

      const response = await putRequest(
        `groundwater/${groundwaterTemplateLocal.value.server_id}/`,
        data
      )

      if (!response) return

      idbData.server_id = response?.id
    }

    await db.updateObject('groundwater', idbData, {
      field: 'excavation_id',
      value: excavationId
    })

    if ((!mainStore.isOnline || mainStore.noSyncMode) && props.excavation.server_id) {
      const updatedItems = await db.updated.where('table').equals('groundwater').toArray()
      const inUpdated = !!updatedItems.find((e) => e.item_id === idbData.id)
      const createdItems = await db.created.where('table').equals('groundwater').toArray()
      const inCreated = !!createdItems.find((e) => e.item_id === idbData.id)

      if (!inUpdated && !inCreated) {
        await db.updated.put({
          table: 'groundwater',
          item_id: idbData.server_id,
          date: new Date()
        })
        getExcavationsChanges()
      }
    }

    const title = 'Редактирование'
    const message = 'УГВ успешно изменен'
    $notify({
      title,
      message,
      type: 'success'
    })
  } catch (e) {
    const title = 'Ошибка'
    const message = 'Произошла ошибка при редактировании УГВ'
    $notify({
      title,
      message,
      type: 'error'
    })
  } finally {
    loading.value = false
    emits('toggle')
  }
}

const checkLevelD = computed(() => {
  return groundwaterTemplateLocal.value.level_d || groundwaterTemplateLocal.value.level_d === 0.0
})

onMounted(() => {
  if (props.activeObject) {
    groundwaterTemplateLocal.value = cloneDeep(props.activeObject)
  } else {
    groundwaterTemplateLocal.value = groundwaterTemplate()

    groundwaterTemplateLocal.value.appear_date = new Date()

    groundwaterTemplateLocal.value.fixed_date = new Date()
  }

  initSource.value = cloneDeep(groundwaterTemplateLocal.value)
})
</script>

<template>
  <s-modal
    v-if="groundwaterTemplateLocal"
    :title="title"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :min-height="460"
    :confirm-condition="hasChanges"
    confirm-on-cancel
    @close="emits('toggle')"
  >
    <div v-loading="loading" class="groundwater-editor">
      <div class="groundwater-editor-block">
        <s-title type="small"> Вскрытый уровень </s-title>
        <s-number-input
          v-model="groundwaterTemplateLocal.level_d"
          :min="minValue"
          :max="maxValue"
          label="Уровень воды, м"
          placeholder="2.0"
        />
        <s-input-renderer :field="appearField" :source="groundwaterTemplateLocal" />
      </div>
      <div v-if="checkLevelD" class="groundwater-editor-block">
        <s-title type="small"> Установившийся уровень </s-title>
        <s-number-input
          v-model="groundwaterTemplateLocal.fixed_d"
          :min="minValue"
          :max="maxValue"
          label="Уровень воды, м"
          placeholder="2.0"
        />
        <s-input-renderer :field="fixedField" :source="groundwaterTemplateLocal" />
        <s-input v-model="groundwaterTemplateLocal.comments" label="Комментарий" type="textarea" />
      </div>
    </div>
    <template #footer>
      <div :class="['groundwater-editor-footer', { exist: activeObject }]">
        <s-button
          v-if="activeObject"
          icon="trash-can"
          simple
          icon-color="var(--error)"
          @click="handleDelete"
        />
        <s-button type="success" :disabled="disabled" @click="saveHandler">
          {{ activeObject ? 'Сохранить' : 'Создать' }}
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.groundwater-editor {
  display: grid;
  align-content: start;
  grid-gap: 2rem;
  height: 100%;
  overflow: auto;

  &-block {
    display: grid;
    align-content: start;
    grid-gap: 1rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: 440px) {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }

    .s-title,
    .s-input {
      grid-column: span 2 / auto;
    }
  }

  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: start;
    grid-gap: 1rem;
  }

  &-footer {
    display: grid;

    &.exist {
      grid-template-columns: auto 1fr;
      grid-gap: 1rem;
    }
  }
}
</style>
