import { defineStore } from 'pinia'

export const useResourcesStore = defineStore('resources', {
  state: () => {
    return {
      resourcesLoading: []
    }
  },
  actions: {
    setField(field, value) {
      if (field.includes('.')) {
        const subField = field.split('.')

        this[subField[0]][subField[1]] = value
      } else {
        this[field] = value
      }
    },
    addItem(field, value) {
      this[field].push(value)
    },
    updateItem(field, value) {
      const index = this[field]?.findIndex((e) => e.id === value.id)

      if (index > -1) {
        this[field][index] = value
      }
    }
  }
})
