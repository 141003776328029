<script setup>
import { computed } from 'vue'
import { useServicesStore } from '@/stores/services'

import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

defineProps({
  title: {
    type: String,
    default: '-'
  },
  fields: {
    type: Array,
    required: true
  },
  source: {
    type: Object,
    required: true
  }
})

const servicesStore = useServicesStore()

const services = computed(() => {
  return {
    ...servicesStore?.excav,
    ...servicesStore
  }
})
</script>

<template>
  <div class="excavation-inputs">
    <div>
      <s-title type="small">{{ title }}</s-title>
      <wiki-tip v-if="fields.wikiTipData" :data="fields.wikiTipData">
        <s-icon name="fa-circle-question" :color="`var(--${fields.wikiTipData.color})`" />
      </wiki-tip>
    </div>
    <s-input-renderer
      v-for="field in fields"
      :key="field.id"
      :field="field"
      :source="source"
      :services="services"
    />
  </div>
</template>

<style>
.excavation-inputs {
  display: grid;
  align-content: start;
  grid-gap: 1rem;
}
</style>
