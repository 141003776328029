<script setup>
import { computed } from 'vue'
import { listTips } from '../config'

import ListTip from '@/components/list-tip.vue'
import ObjectItem from './object-item.vue'

const props = defineProps({
  objects: {
    type: Array,
    default: () => []
  },
  filtered: {
    type: Boolean,
    default: false
  }
})

const listTipComputed = computed(() => {
  if (!props.filtered) {
    return listTips.default
  } else {
    return listTips.filtered
  }
})
</script>

<template>
  <ul v-if="objects.length" class="objects-list">
    <object-item v-for="object in objects" :key="object.id" :object="object" />
  </ul>
  <list-tip v-else :tip="listTipComputed" />
</template>

<style lang="scss">
.objects-list {
  display: grid;
  align-content: start;
  overflow: auto;
  padding-right: 0.5rem;
}
</style>
