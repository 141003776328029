import { useObjectsStore } from '@/stores/objects'
import { orderBy } from 'natural-orderby'

export const getSortedMappedGeologists = () => {
  const objectsStore = useObjectsStore()

  const sortedUsers = orderBy(
    objectsStore?.activeObject?.actual_geologists,
    [(v) => v['is_active']],
    ['desc']
  )

  return sortedUsers?.map((geo) => {
    return {
      id: geo.id,
      title: geo.get_short_name ? geo.get_short_name : geo.username,
      disabled: !geo.is_active
    }
  })
}

export const mappedGeologists = (geologists = []) => {
  return geologists.map((geologist) => {
    return {
      id: geologist.id,
      short_name: geologist.get_short_name,
      username: geologist.username,
      is_active: geologist.is_active
    }
  })
}

export const prepareGeologistFilter = (geologists = [], filters) => {
  const objectGeologists = setDeletedTitleGeologists(geologists)
  const geologistFilter = filters.filters.find((f) => f.id === 'geologist')

  const noGeologist = {
    id: null,
    title: 'Без геолога',
    value: true
  }

  geologistFilter.filterValues = [...objectGeologists, noGeologist]
}

const setDeletedTitleGeologists = (geologists) => {
  return geologists.map((item) => {
    let title = item.short_name ? item.short_name : item.username
    const isDisabledText = !item.is_active ? title + ' (Удален)' : title

    return {
      id: item.id,
      title: isDisabledText,
      value: true,
      disabledText: !item.is_active
    }
  })
}
