import { orderBy } from 'natural-orderby'
import { saveDataToLS } from '@/utils/browser'
import { useObjectsStore } from '@/stores/objects'

export const initFiltersConfig = (filters, route, LSName) => {
  if (!route?.query) return

  const { type, status, sortorder, sortby, archived } = route.query

  const archivedFilter = getArchivedFilter(filters)

  switch (archived) {
    case 'false':
      archivedFilter.filterValues.forEach((f) => {
        f.value = f.id !== true
      })
      break

    case 'true':
      archivedFilter.filterValues.forEach((f) => {
        f.value = f.id === true
      })
      break

    case '':
      archivedFilter.filterValues.forEach((f) => {
        f.value = false
      })
  }

  if (type) {
    changeFilterValue(filters, 'type', type, true)
  }

  if (sortorder) {
    changeSortValue(filters, 'order', sortorder)
  }

  if (status || status === '') {
    const statusesArr = status.split(',')
    const IDsToNum = statusesArr.map((item) => +item)
    changeFilterStatuses(filters, IDsToNum)
  }

  if (sortby) {
    changeSortValue(filters, 'value', sortby)
  }

  saveToLSAndStore(LSName, route.query)
}

const changeSortValue = (filters, field, value) => {
  filters.sort[field] = value
}

const changeFilterValue = (filters, filterID, typeValue, value) => {
  const filterType = filters.filters.find((f) => f.id === filterID)

  if (!filterType) return

  filterType.filterValues.find((f) => {
    if (typeValue === 'all') {
      f.value = true
      return
    }

    if (f.id === typeValue) {
      f.value = value
    } else {
      f.value = !value
    }
  })
}

const changeFilterStatuses = (filters, activeStatusesIDs) => {
  const filterType = filters.filters.find((f) => f.id === 'status')

  if (!filterType) return

  filterType.filterValues.find((f) => {
    if (activeStatusesIDs.includes(f.id)) {
      f.value = true
    } else {
      f.value = false
    }
  })
}

export const saveToLSAndStore = (field, value) => {
  const objectsStore = useObjectsStore()

  saveDataToLS(field, value)
  objectsStore.setField(field, value)
}

export const setQueryParams = async (filters, route, router, field, id, value, LSName) => {
  if (field === 'geologist') return

  let activeStatuses

  if (field === 'status') {
    activeStatuses = getActiveStatusesIDs(filters)
    router.push({
      name: route.name,
      query: { ...route.query, status: activeStatuses.join(',') }
    })

    saveToLSAndStore(LSName, {
      ...route.query,
      status: activeStatuses.join(',')
    })
    return
  }

  if (field === 'type') {
    const filterTypes = filters.filters.find((f) => f.id === 'type')
    const isAll = filterTypes.filterValues.every((f) => f.value)

    if (isAll) {
      id = 'all'
    } else {
      const isAlreadyOffOneType = filterTypes.filterValues.some((f) => !f.value)

      if (isAlreadyOffOneType && !value) {
        const typesMap = {
          recon: 'excavations',
          excavations: 'recon'
        }

        filterTypes.filterValues.forEach((f) => {
          if (f.id === typesMap[id]) {
            f.value = true
          }
        })
      }

      const activeType = filterTypes.filterValues.find((f) => f.value)

      id = activeType.id
    }
  }

  if (field === 'archived') {
    const archivedFilter = getArchivedFilter(filters)

    const isAllSelected = archivedFilter.filterValues.every((f) => f.value)

    if (isAllSelected) {
      const newQuery = { ...route.query }
      delete newQuery.archived

      await router.push({ name: route.name, query: newQuery })

      saveToLSAndStore(LSName, newQuery)
      return
    }

    const isNoneSelected = archivedFilter.filterValues.every((f) => !f.value)

    if (isNoneSelected) {
      const newQuery = { ...route.query }
      newQuery.archived = ''

      await router.push({ name: route.name, query: newQuery })

      saveToLSAndStore(LSName, newQuery)
      return
    }

    if (id === true && value === false) {
      value = false
    } else {
      value = true
    }

    await router.push({ name: route.name, query: { ...route.query, [field]: value } })

    saveToLSAndStore(LSName, {
      ...route.query
    })

    return
  }

  await router.push({ name: route.name, query: { ...route.query, [field]: id } })

  saveToLSAndStore(LSName, {
    ...route.query
  })
}

const getArchivedFilter = (filters) => {
  return filters.filters.find((f) => f.id === 'archived')
}

const getActiveStatusesIDs = (filters) => {
  const statuses = filters.filters.find((f) => f.id === 'status')

  if (!statuses) return

  return statuses.filterValues.filter((f) => f.value).map((f) => f.id)
}

export const filterData = (list, filters, hasFilterType) => {
  if (!list.length) return []

  const sourcesTypesMap = {
    201: 'recon',
    101: 'excavations'
  }

  const filtersValues = filters.map((f) => ({
    id: f.id,
    ids: f.filterValues.filter((e) => e.value).map((e) => e.id)
  }))

  return list.filter((e) => {
    return filtersValues.every((f) => {
      if (hasFilterType && f.id === 'type') {
        const type = sourcesTypesMap[e.type]

        return type && f.ids.includes(type)
      }

      if (f.id === 'geologist') {
        const includeNull = f.ids.includes(null)
        if (includeNull && e[f.id] === null) return true
        if (!includeNull && e[f.id] === null) return false
      }

      if (f.id === 'is_active') {
        if (f.ids.includes('show')) {
          return true
        } else {
          return e.is_active === true
        }
      }

      return f.ids.includes(e[f.id])
    })
  })
}

export const filterAndSortList = (list = [], filterText = '', sortValue, filterFields = []) => {
  const { value, order } = sortValue
  const sortedList = orderBy(list, [(v) => v[value]], [order])

  return sortedList?.filter((e) => {
    return filterFields.some((field) => e[field]?.trim().toLowerCase().includes(filterText))
  })
}
