<script setup>
import { inject, reactive, computed } from 'vue'
import router from '@/router'
import { useVuelidate } from '@vuelidate/core'

import { passwordValidator } from '@/validators'

import { useAuthStore } from '@/stores/auth'
import { useRequests } from '@/composables'

import ErrorValidatorText from '@/components/error-validator-text.vue'

const emits = defineEmits(['toggle-loader'])

const { postRequest } = useRequests()
const authStore = useAuthStore()
const $notify = inject('$notify')

const form = reactive({
  password: '',
  confirmPassword: ''
})

const rules = computed(() => ({
  ...passwordValidator(form)
}))

const v$ = useVuelidate(rules, form)

const changePassword = async () => {
  const result = await v$.value.$validate()

  if (!result) {
    return
  }

  try {
    emits('toggle-loader', true)

    const url = 'users/profile/password/'
    const data = {
      password: form.password,
      password1: form.confirmPassword
    }

    await postRequest(url, data)

    await authStore.authLogout()
    router.push('/login')

    form.password = ''
    form.confirmPassword = ''

    $notify({
      title: 'Сохранено',
      message: 'Пароль успешно сохранен',
      type: 'success'
    })
  } catch (error) {
    console.log(error)

    const data = error?.response?.data

    if (data && Object.values(data).length) {
      $notify({
        title: 'Ошибка сохранения',
        message: Object.values(data).flat().join(' '),
        type: 'error'
      })
    }
  } finally {
    emits('toggle-loader', false)
  }
}
</script>

<template>
  <div class="profile-editor__block">
    <div class="profile-editor__block-title">
      <s-title type="small"> Изменение пароля </s-title>
    </div>
    <div class="profile-editor__inputs">
      <div class="profile-editor__row">
        <div class="profile-editor__input">
          <s-input v-model="form.password" type="password" label="Пароль" />
          <error-validator-text
            v-if="v$.password.$error"
            :errorText="v$.password.$errors[0].$message"
          />
        </div>
        <div class="profile-editor__input">
          <s-input v-model="form.confirmPassword" type="password" label="Подтвердите пароль" />
          <error-validator-text
            v-if="v$.confirmPassword.$error"
            :errorText="v$.confirmPassword.$errors[0].$message"
          />
        </div>
      </div>
    </div>

    <div class="profile-editor__block-button">
      <s-button type="primary" @click="changePassword"> Изменить пароль </s-button>
    </div>
  </div>
</template>

<style lang="scss"></style>
