<script setup>
import { ref, onMounted } from 'vue'
import { useRitmDate, useRequests } from '@/composables'
import { parseDatetime } from '@/utils/dates'
import { logLabels } from '../config'

import SimpleCard from '@/components/simple-card.vue'

const rDate = useRitmDate()
const { getRequest } = useRequests()

const start = new Date()
const end = new Date()
start.setDate(start.getDate() - 7)
end.setDate(end.getDate() + 1)

const dateRange = ref([start, end])
const updateFilter = (e) => {
  dateRange.value = e

  loadData()
}

const config = {
  deletion: 'Удалил',
  addition: 'Добавил',
  change: 'Изменил'
}

const loading = ref(false)
const changes = ref([])
const getLabel = (id) => {
  return logLabels[id] || id
}

const loadData = async () => {
  try {
    loading.value = true

    const [startDate, endDate] = dateRange.value
    const start = rDate(startDate).format('YYYY-MM-DD')
    const end = rDate(endDate).format('YYYY-MM-DD')
    const url = `userlog/?action_time_after=${start}&action_time_before=${end}`

    const data = await getRequest(url)
    changes.value = data.slice(0, 10)
  } catch (error) {
    console.log(error)
  } finally {
    loading.value = false
  }
}

onMounted(() => {
  loadData()
})
</script>

<template>
  <simple-card title="История" icon="clock-rotate-left">
    <div v-loading="loading" class="profile-log">
      <ul v-if="changes.length" class="profile-log__list">
        <li v-for="(item, i) in changes" :key="`${item.id}_${i}`" class="profile-log__item">
          <div class="profile-log__item-date">
            <s-text type="secondary">
              {{ parseDatetime(item.action_datetime_front || item.action_time) }}
            </s-text>
          </div>
          <div class="profile-log__item-text">
            <s-text>
              {{ config[item.action_flag_text] }}
              <strong>{{ getLabel(item.object) }}</strong>
            </s-text>
          </div>
        </li>
      </ul>
      <div v-else class="profile-log__nodata">
        <s-text type="secondary"> Список действий за указанный период пуст </s-text>
      </div>
    </div>

    <template #actions>
      <s-date :value="dateRange" @change="updateFilter" range :text-only="true" />
    </template>
  </simple-card>
</template>

<style lang="scss" scoped>
.profile-log {
  min-height: 4rem;
  display: flex;

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: 8px;
    }

    &-date {
      margin-right: 16px;
    }
  }

  &__nodata {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dp__main {
  min-width: 160px !important;
}
</style>
