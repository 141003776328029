<script setup>
import { ref, onMounted, watch } from 'vue'
import { useEditorStore } from '@/stores/work-planning/editor'
import { getDataFromLS, saveDataToLS } from '@/utils/browser'

import MapButton from '@/components/s-map/components/map-button.vue'

const editorStore = useEditorStore()

const showSettings = ref(false)
const distanceTip = ref(true)

watch(
  () => distanceTip.value,
  (newValue) => {
    toggleDistanceTip(newValue)
  }
)

const toggleDistanceTip = (value) => {
  distanceTip.value = value
  saveDataToLS('isShowDistanceTip', value)
  editorStore.setIsShowDistanceTip(value)
}

onMounted(() => {
  const isShow = getDataFromLS('isShowDistanceTip')

  if (!isShow && isShow === false) {
    toggleDistanceTip(false)
  } else {
    toggleDistanceTip(true)
  }
})
</script>

<template>
  <div class="map-editor__settings-button">
    <div v-tooltip="'Настройки редактора'">
      <s-pop-tip v-model="showSettings" placement="bottom" trigger="click">
        <map-button icon="gear" :active="showSettings" size="lg" />
        <template #content>
          <div class="map-editor__settings-header">
            <s-title type="small">Настройки карты</s-title>
          </div>
          <s-switch v-model="distanceTip" label="Подсказки-измерители" />
        </template>
      </s-pop-tip>
    </div>
  </div>
</template>

<style lang="scss">
.map-editor__settings-button {
  & svg {
    width: 22px;
    height: 22px;
  }
}
</style>
