import cloneDeep from 'lodash.clonedeep'
import debounce from 'lodash.debounce'

import db from '@/libs/db'
import { checkStorage, updateStorage } from '@/libs/db/helpers'

import { useEditorStore } from '@/stores/work-planning/editor'
import { useObjectsStore } from '@/stores/objects'
import { useRequests } from '@/composables'

import { deleteHandler } from '@/utils/requests'
import { getUuid } from '@/utils/browser'
import appGlobalProperties from '@/main'

export class RequestController {
  constructor(route) {
    this.route = route
    this.count = null
    this.notifyDebounced = debounce((title, message, type) => {
      appGlobalProperties.$notify({
        title,
        message,
        type
      })
    }, 500)
  }

  async sendExcavations() {
    const editorStore = useEditorStore()
    const clone = cloneDeep(editorStore.recentChangesPoints)
    this.count = clone.length

    await Promise.all(
      clone.map(async (point) => {
        if (point.point_id) {
          await this.createExcavation(point)
        } else {
          await this.editExcavation(point)
        }
      })
    )

    editorStore.clearChangedPoints()
  }

  async createExcavation(excavation) {
    const editorStore = useEditorStore()
    const { postRequest } = useRequests()

    const objectId = this.route?.params?.id
    const filter = { field: 'object_id', value: objectId }

    excavation.uuid = getUuid()

    try {
      let data = cloneDeep(excavation)
      data.uuid = getUuid()

      const response = await postRequest(`objects/${objectId}/excavations/`, data)
      if (!response) return
      data = response

      data.server_id = response?.id
      delete data.id
      data.object_id = objectId

      await db.addObject('excavations', data, filter)

      const index = editorStore.recentChangesPoints.findIndex(
        (item) => item.point_id === excavation.point_id
      )

      if (index !== -1) {
        editorStore.removePoint(excavation.point_id, 'recent')
      }

      const title = 'Создание'
      let message = `${this.count > 1 ? 'Выработки успешно созданы' : 'Выработка успешно создана'} `

      this.notifyDebounced(title, message, 'success')
    } catch (error) {
      const title = 'Создание'
      const message = `Ошибка при создании выработки ${error}`
      this.notifyDebounced(title, message, 'error')
    }
  }

  async editExcavation(excavation) {
    const { putRequest } = useRequests()
    const objectId = this.route?.params?.id

    try {
      const filter = { field: 'object_id', value: objectId }
      delete excavation.oldTitle

      await putRequest(`excavations/${excavation.server_id}/`, excavation)
      await db.updateObject('excavations', excavation, filter)

      const title = 'Редактирование'
      const message = `${this.count > 1 ? 'Выработки успешно отредактированы' : 'Выработка успешно отредактирована'} `
      this.notifyDebounced(title, message, 'success')
    } catch (e) {
      const title = 'Редактирование'
      const message = `Ошибка при редактировании выработки ${e}`
      this.notifyDebounced(title, message, 'error')
    }
  }

  async deleteExcavation(excavation) {
    await deleteHandler(excavation, 'excavations', this.getExcavations.bind(this))
  }

  async getExcavations() {
    const objectsStore = useObjectsStore()
    const { getRequest } = useRequests()
    const objectId = this.route?.params?.id

    const filter = { field: 'object_id', value: objectId }

    await db.updateStoreData('excavations', filter)

    const server = await getRequest(`objects/${objectId}/excavations/`)

    await checkStorage('excavations', objectsStore.excavationsList, server, filter)
    await updateStorage('excavations', objectsStore.excavationsList, server, filter)
  }
}
