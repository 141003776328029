<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router'

import { useObjectsStore } from '@/stores/objects'
import { useServicesStore } from '@/stores/services'

import { getFormattedStringFromArray } from '@/utils/arrays'

defineProps({
  excavationTitle: {
    type: String,
    default: null
  }
})

const objectsStore = useObjectsStore()
const servicesStore = useServicesStore()
const route = useRoute()

const errorsExcavsTitles = computed(() => {
  if (!excavationsList.value.length) return []

  const objectUsersIds = new Set(servicesStore.object_users?.map((item) => item.id))
  const errorsExcavsArray = []

  excavationsList.value.forEach((item) => {
    if (item.geologist && !objectUsersIds.has(item.geologist)) {
      errorsExcavsArray.push(item.title)
    }
  })

  return [...new Set(errorsExcavsArray)]
})

const excavationsList = computed(() => {
  const excavList = objectsStore.excavationsList
  if (!excavList) return []
  return excavList
})

const errorExcavString = computed(() => {
  const excavList = objectsStore.excavationsList
  if (!excavList) return

  if (!errorsExcavsTitles.value.length) {
    return null
  }

  return getFormattedStringFromArray(errorsExcavsTitles.value)
})

const isExcavPage = computed(() => {
  return route.name === 'excavation'
})

const geoTipText = ref(null)

const showMore = ref(false)

const alertStylesWithToggler = computed(() => {
  if (showMore.value) {
    return {
      'max-height': '320px',
      overflow: 'auto'
    }
  }

  return {
    'max-height': '100%'
  }
})

const isTextOverflowed = computed(() => {
  if (!geoTipText.value) return false

  const element = geoTipText.value.$el
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth
})
</script>

<template>
  <s-alert
    v-if="isExcavPage && errorsExcavsTitles.includes(excavationTitle)"
    class="unassigned-geologists-tip"
    icon="circle-info"
    type="primary"
  >
    <s-text> На текущую скважину назначен геолог, который не добавлен в проект. </s-text>
  </s-alert>
  <s-alert
    v-if="!isExcavPage && errorExcavString"
    class="unassigned-geologists-tip"
    icon="circle-info"
    type="primary"
    :styles="alertStylesWithToggler"
  >
    <div class="unassigned-geologists-tip__content">
      <div>
        <s-text
          ref="geoTipText"
          :style="{ '-webkit-line-clamp': showMore ? 'none' : 2 }"
          class="alert-slot__ellipsis-text"
        >
          В проекте есть скважины с геологами, которые не добавлены в проект – скв.
          {{ errorExcavString }}. Проверьте эти скважины в
          <router-link class="unassigned-geologists-tip__link" :to="'./sheets'">
            модуле Таблицы.
          </router-link>
        </s-text>
      </div>
    </div>
    <template v-if="isTextOverflowed" v-slot:toggler>
      <s-button
        class="alert-slot__toggler"
        :icon="showMore ? 'chevron-up' : 'chevron-down'"
        link
        @click.stop="showMore = !showMore"
      >
        {{ showMore ? 'Скрыть все' : 'Показать все' }}</s-button
      >
    </template>
  </s-alert>
</template>

<style lang="scss">
.alert-slot {
  &__ellipsis-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }

  &__toggler {
    margin-top: 4px;
  }
}

.unassigned-geologists-tip {
  max-width: 100%;

  &__link {
    color: var(--primary);
  }

  &__icon {
    margin-left: 4px;
  }
}
</style>
