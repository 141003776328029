<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue'

import { useObjectsStore } from '@/stores/objects'
import { useRitmDate } from '@/composables'

import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'

const props = defineProps({
  currentType: {
    type: Object,
    required: true
  },
  options: {
    type: Object,
    required: true
  },
  enabledOptions: {
    type: Array,
    default: () => []
  }
})

const emits = defineEmits(['on-change'])

const objectsStore = useObjectsStore()
const rDate = useRitmDate()

const activeObject = computed(() => {
  return objectsStore?.activeObject
})

const spelled = ref(props.options.spelled)
const stamp = ref(props.options.stamp)
const type = ref(props.options.type)
const build_in_template = ref(props.currentType.build_in_template)

const dates = reactive({
  date_start: activeObject.value?.actual_date_start,
  date_end: activeObject.value?.actual_date_end
})

const radioHndl = (value) => {
  type.value = value
  emits('on-change', ['type', value])
}

const hasSetting = (setting) => {
  return props.enabledOptions?.includes(setting)
}

const selectHandler = (value) => {
  build_in_template.value = value
  emits('on-change', ['build_in_template', value])
}

watch(
  () => dates,
  () => {
    const parsed_date_start = dateToISO(dates.date_start)
    const parsed_date_end = dateToISO(dates.date_end)

    emits('on-change', ['date_start', parsed_date_start])
    emits('on-change', ['date_end', parsed_date_end])
  },
  { deep: true }
)

const datesConfig = [
  {
    id: 'date_start',
    title: 'date_start',
    type: 'date',
    width: 'half',
    format: 'MM/dd/yyyy',
    label: 'Начало',
    dateOptions: {
      min: null,
      max: 'today',
      condition: {
        field: 'date_end',
        value: 'less'
      }
    }
  },
  {
    id: 'date_end',
    title: 'date_end',
    type: 'date',
    width: 'half',
    format: 'MM/dd/yyyy',
    label: 'Окончание',
    dateOptions: {
      min: null,
      max: null,
      condition: {
        field: 'date_start',
        value: 'more'
      }
    }
  }
]

const dateToISO = (date) => {
  if (!date) return
  return rDate(date).format('YYYY-MM-DD')
}

onMounted(() => {
  emits('on-change', ['date_start', dates.date_start])
  emits('on-change', ['date_end', dates.date_end])
})
</script>

<template>
  <div class="export-settings">
    <s-title type="small">Настройки экспорта</s-title>
    <ul class="export-settings__list">
      <li v-if="hasSetting('type')" class="export-settings__item">
        <s-text class="export-settings__item-label" type="secondary"> Тип отчета </s-text>
        <s-radio-group>
          <s-radio-button :active-label="type" @change="radioHndl" label="full">
            Полный
          </s-radio-button>
          <s-radio-button :active-label="type" @change="radioHndl" label="short">
            Сокращенный
          </s-radio-button>
        </s-radio-group>
      </li>
      <li v-if="hasSetting('stamp')" class="export-settings__item">
        <s-switch
          v-model="stamp"
          label="Цифровой штамп"
          @change="emits('on-change', ['stamp', stamp])"
        />
      </li>
      <li v-if="hasSetting('spelled')" class="export-settings__item">
        <s-switch
          v-model="spelled"
          label="Проверка правописания"
          @change="emits('on-change', ['spelled', spelled])"
        />
      </li>

      <li v-if="hasSetting('dates')" class="export-settings__item export-settings__item-dates">
        <s-input-renderer
          v-for="item in datesConfig"
          :key="item.title"
          :field="item"
          :source="dates"
        />
      </li>

      <li v-if="currentType?.templatesList?.length" class="export-settings__item">
        <s-select
          select-label="Шаблон отчета"
          :value="build_in_template"
          :options="currentType.templatesList"
          @change="selectHandler"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss">
.export-settings {
  &__list {
    margin-top: 16px;
  }

  &__item {
    &-dates {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 8px;
    }

    &:not(:first-child) {
      margin-top: 24px;
    }

    &-label {
      margin-bottom: 8px;
    }

    .s-radio-button {
      flex: 1;
    }
  }
}
</style>
