import db from '@/libs/db'
import { useRequests } from '@/composables'
import { useMainStore } from '@/stores/main'

const getLogData = async (excavation) => {
  const excavation_id = excavation?.item_id || ``

  if (excavation_id) {
    const logData = await db.log.where({ excavation_id: excavation_id }).toArray()
    return logData
  } else {
    const logData = await db.log.toArray()
    return logData
  }
}

export const syncLog = async (excavation) => {
  const mainStore = useMainStore()
  if (!mainStore.isOnline) return

  const { postRequest } = useRequests()

  try {
    const logData = await getLogData(excavation)
    const serverLog = logData?.filter((e) => Number.isInteger(e.excavation_id))

    await Promise.all(
      serverLog.map(async (e) => {
        await postRequest(`excavations/${e.excavation_id}/log/`, e)

        await db.log.delete(e.id)
      })
    )

    const idbLog = logData?.filter((e) => !Number.isInteger(e.excavation_id))

    await Promise.all(
      idbLog.map(async (e) => {
        const idbId = Number(e.excavation_id?.replace('idb_', ''))
        const excav = await db.excavations.where('id').equals(idbId).first()

        if (excav?.server_id) {
          await postRequest(`excavations/${excav.server_id}/log/`, e)

          await db.log.delete(e.id)
        }
      })
    )
  } catch (e) {
    console.log(e, 'syncLog')
    throw new Error('Ошибка при синхронизации логирования')
  }
}
