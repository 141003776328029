import {
  STitle,
  SButton,
  SText,
  SInput,
  SAlert,
  SIcon,
  STag,
  SPopTip,
  SLoading,
  STooltip,
  SBadge,
  SModal,
  SSelect,
  SCheckbox,
  SDiv,
  STab,
  STabs,
  SProgress,
  SDate,
  SNotify,
  SSwitch,
  SConfirm,
  SRadioGroup,
  SRadioButton,
  SUpload,
  SDropdown,
  SDropdownItem,
  SCollapse,
  SCollapseItem,
  SNumberInput,
  SBottomSheet,
  SSearchBlock
} from '@n966/soilbox_ui'
import '@n966/soilbox_ui/dist/style.css'

const components = [
  { name: 'SBottomSheet', type: 'component', component: SBottomSheet },
  { name: 'SSearchBlock', type: 'component', component: SSearchBlock },
  { name: 'SNumberInput', type: 'component', component: SNumberInput },
  { name: 'SCollapse', type: 'component', component: SCollapse },
  { name: 'SCollapseItem', type: 'component', component: SCollapseItem },
  { name: 'SDropdown', type: 'component', component: SDropdown },
  { name: 'SDropdownItem', type: 'component', component: SDropdownItem },
  { name: 'SUpload', type: 'component', component: SUpload },
  { name: 'SRadioGroup', type: 'component', component: SRadioGroup },
  { name: 'SRadioButton', type: 'component', component: SRadioButton },
  { name: 'SDate', type: 'component', component: SDate },
  { name: 'SProgress', type: 'component', component: SProgress },
  { name: 'STab', type: 'component', component: STab },
  { name: 'STabs', type: 'component', component: STabs },
  { name: 'SDiv', type: 'component', component: SDiv },
  { name: 'SCheckbox', type: 'component', component: SCheckbox },
  { name: 'SSelect', type: 'component', component: SSelect },
  { name: 'SIcon', type: 'component', component: SIcon },
  { name: 'SText', type: 'component', component: SText },
  { name: 'STitle', type: 'component', component: STitle },
  { name: 'SAlert', type: 'component', component: SAlert },
  { name: 'SInput', type: 'component', component: SInput },
  { name: 'SButton', type: 'component', component: SButton },
  { name: 'STag', type: 'component', component: STag },
  { name: 'SPopTip', type: 'component', component: SPopTip },
  { name: 'SModal', type: 'component', component: SModal },
  { name: 'SSwitch', type: 'component', component: SSwitch },

  { name: 'loading', type: 'directive', component: SLoading },
  { name: 'tooltip', type: 'directive', component: STooltip },
  { name: 'badge', type: 'directive', component: SBadge },

  { name: 'SNotify', type: 'plugin', component: SNotify },
  { name: 'SConfirm', type: 'plugin', component: SConfirm }
]

export default components
