<script setup>
import { computed, onMounted, ref } from 'vue'
import { useMq } from 'vue3-mq'
import { onBeforeRouteLeave } from 'vue-router'

import { useSyncStore } from '@/stores/sync'

import CenteredContainer from '@/components/centered-container.vue'
import RecentComponent from './components/home-recent.vue'
import SyncComponent from './components/sync/home-sync.vue'

const mq = useMq()
const syncStore = useSyncStore()

const tabs = [
  { id: 'recent', name: 'Недавнее', icon: mq.current !== 'sm' ? 'clock-rotate-left' : null },
  { id: 'sync', name: 'Cинхронизация', icon: mq.current !== 'sm' ? 'sync' : null }
]

const activeTab = ref('recent')

const syncChanges = computed(() => {
  return !!syncStore.changesList?.length || !!syncStore.dataChangesList?.length
})

const compMap = {
  recent: RecentComponent,
  sync: SyncComponent
}

const activeComp = computed(() => {
  return compMap[activeTab.value]
})

const changeTab = (id) => {
  activeTab.value = id
}

onMounted(() => {
  if (syncChanges.value) {
    activeTab.value = 'sync'
  }
})

onBeforeRouteLeave((_, from, next) => {
  syncStore.setField('internetSpeed', null)
  next()
})
</script>

<template>
  <centered-container :width="720">
    <div class="home-page">
      <s-tabs :tabs="tabs" @click="changeTab" :value="activeTab" even-width>
        <s-tab v-for="tab in tabs" :id="tab.id" :key="tab.id" :name="tab.name" :active="activeTab">
          <component v-if="tab.id === activeTab" :is="activeComp" />
        </s-tab>
      </s-tabs>
    </div>
  </centered-container>
</template>

<style lang="scss">
.home-page {
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
