<script setup>
import cloneDeep from 'lodash.clonedeep'
import { computed } from 'vue'

import { useExcavationStore } from '@/stores/excavation'
import { useAuthStore } from '@/stores/auth'

import GroundwaterItem from './components/groundwater-item.vue'

const props = defineProps({
  excavation: {
    type: Object,
    default: () => null
  }
})

const emits = defineEmits(['add-handler', 'edit-handler'])

const excavationStore = useExcavationStore()
const authStore = useAuthStore()

const disabled = computed(() => {
  const geologist = props.excavation?.geologist

  return !authStore.hasDataPermissions(geologist)
})

const groundwaterList = computed(() => {
  const clone = cloneDeep(excavationStore.groundwaterList)

  return (
    clone?.sort((a, b) => {
      if (a.level_d > b.level_d) return 1
      if (a.level_d < b.level_d) return -1
      return 0
    }) || []
  )
})

const clickHandler = (groundwater) => {
  if (disabled.value) return
  emits('edit-handler', { model: 'groundwater', value: groundwater })
}
</script>

<template>
  <div class="groundwater-list">
    <div v-if="!disabled" class="groundwater-list__btn-wrapper">
      <s-button type="success-plain" icon="fa-plus" @click="emits('add-handler', 'groundwater')">
        Добавить УГВ
      </s-button>
    </div>
    <div class="groundwater-list-content">
      <groundwater-item
        v-for="(groundwater, i) in groundwaterList"
        :key="groundwater.id"
        :no="i + 1"
        :groundwater="groundwater"
        @click="clickHandler(groundwater)"
      />
    </div>
  </div>
</template>

<style lang="scss">
.groundwater-list {
  display: grid;
  grid-gap: 1rem;

  &__btn-wrapper {
    position: sticky;
    top: 0;
    z-index: 10;
    background: var(--card-bg);

    & .s-button {
      width: 100%;
    }
  }

  &-content {
    display: grid;
    grid-gap: 1rem;
  }
}
</style>
