<script setup>
import { computed, ref } from 'vue'
import { useRitmDate } from '@/composables'
import InterlayerModal from './modals/interlayers-modal.vue'
import SecondaryItem from './common/soil-secondary-item.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  soilInterval: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const emits = defineEmits(['trigger-changed'])

const rDate = useRitmDate()

const isVisible = ref(false)
const initSource = ref(props.source)
const activeInterlayer = ref(null)

const interlayers = computed(() => {
  return props.source.interlayers || []
})

const toggleModal = () => {
  activeInterlayer.value = null
  isVisible.value = !isVisible.value
}

const openInterlayer = (item) => {
  activeInterlayer.value = item
  isVisible.value = !isVisible.value
}

const addHandler = (item) => {
  if (!props.source.interlayers?.length) {
    initSource.value.interlayers = []
  }

  const exist = !!initSource.value.interlayers?.find((e) => e.id === item.id)
  const lastId = initSource.value.interlayers?.reduce((a, c) => {
    if (c.id > a) return c.id
    return a
  }, 0)

  if (exist) {
    initSource.value.interlayers = initSource.value.interlayers.map((e) => {
      if (e.id === item.id) {
        return item
      }
      return e
    })
  } else {
    const offline_id = rDate(new Date()).format('iso')

    initSource.value.interlayers.push({
      id: lastId + 1,
      offline_id: offline_id + lastId,
      ...item
    })
  }

  emits('trigger-changed', true)
}

const removeHandler = (item) => {
  if (!item.id) return

  const index = initSource.value.interlayers?.findIndex((e) => e.id === item.id)

  if (index < 0) return

  initSource.value.interlayers.splice(index, 1)
}
</script>

<template>
  <div class="create-guide-soil-interlayers">
    <secondary-item
      v-for="item in interlayers"
      :key="item.id"
      :item="item"
      :source="source"
      type="interlayer"
      @click="openInterlayer(item)"
    >
      {{ item.d0 }}
    </secondary-item>
    <s-button @click="toggleModal">Добавить прослой</s-button>
    <interlayer-modal
      :is-visible="isVisible"
      :soil-interval="soilInterval"
      :interlayer="activeInterlayer"
      :frozen="field.frozen"
      @toggle="toggleModal"
      @add-handler="addHandler"
      @remove-handler="removeHandler"
      :is-edit="false"
    />
  </div>
</template>

<style>
.create-guide-soil-interlayers {
  display: grid;
  grid-gap: 1.5rem;
}
</style>
