import { getDataFromLS } from '@/utils/browser'

const restoreLastQueryParams = (to, _, next, LSName) => {
  const lastQueries = getDataFromLS(LSName)

  if (lastQueries && Object.keys(lastQueries).length) {
    const hasNewQueries = Object.keys(lastQueries).some((key) => to.query[key] !== lastQueries[key])

    if (hasNewQueries) {
      next({
        path: to.path,
        query: { ...to.query, ...lastQueries }
      })
    } else {
      next()
    }
  } else {
    next()
  }
}

export const beforeEnterWithQueryRestore = (LSName) => (to, from, next) => {
  restoreLastQueryParams(to, from, next, LSName)
}
