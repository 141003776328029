<script setup>
import { computed } from 'vue'
import { usePrintStore } from '@/stores/print'

const printStore = usePrintStore()

const calculateData = computed(() => {
  const result = {}
  const { filteredProductions } = printStore

  result.plan = filteredProductions[filteredProductions.length - 1]?.planned_production
  result.fact = filteredProductions[filteredProductions.length - 1]?.production

  if (result.plan < result.fact) {
    result.difference = `${Number(result.plan - result.fact).toFixed(2)}`
    result.isNegative = true
  }

  if (result.plan > result.fact) {
    result.difference = `+${Number(result.plan - result.fact).toFixed(2)}`
    result.isPositive = true
  }

  return result
})
</script>

<template>
  <div class="list-footer">
    <s-title>Итого</s-title>
    <s-title>{{ Number(calculateData.plan).toFixed(2) }}</s-title>
    <s-title>{{ Number(calculateData.fact).toFixed(2) }}</s-title>
    <s-title
      :class="{
        positive: calculateData.isPositive,
        negative: calculateData.isNegative
      }"
    >
      {{ calculateData.difference }}
    </s-title>
  </div>
</template>

<style lang="scss" scoped>
.list-footer {
  display: flex;
  align-items: center;
  padding: 8px 0;
  justify-content: end;

  & .s-title {
    display: flex;
    justify-content: center;
    width: 158px;
  }

  & .negative {
    color: var(--error) !important;
  }

  & .positive {
    color: var(--success) !important;
  }
}
</style>
