<script setup>
import { getInterlayerTitle, getInterlayerText } from '@/utils/soils/interlayers'
import { getInclusionTitle, getInclusionText } from '@/utils/soils/inclusions'
import { useServicesStore } from '@/stores/services'
import { computed } from 'vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  item: {
    type: Object,
    required: true,
    default: () => {}
  },
  type: {
    type: String,
    required: true,
    deafult: 'inclusion'
  }
})

const servicesStore = useServicesStore()

const services = computed(() => {
  return servicesStore.soil || null
})

const getTitle = () => {
  switch (props.type) {
    case 'inclusion': {
      const title = getInclusionTitle(props.item)

      return `Включение ${title}`
    }
    case 'interlayer': {
      const title = getInterlayerTitle(props.item)

      return `Прослой ${title}`
    }
    default:
      return '-'
  }
}

const getText = () => {
  switch (props.type) {
    case 'inclusion': {
      return getInclusionText(props.item, services.value)
    }
    case 'interlayer': {
      return getInterlayerText(props.item, services.value, props.source)
    }
    default:
      return '-'
  }
}
</script>

<template>
  <div class="soil-secondary-item">
    <s-title type="small">
      {{ getTitle() }}
    </s-title>
    <s-text>
      {{ getText() }}
    </s-text>
    <s-icon name="chevron-right" />
  </div>
</template>

<style lang="scss">
.soil-secondary-item {
  display: grid;
  grid-gap: 0.5rem;
  padding: 1rem 1.5rem 1rem 1rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  background-color: var(--bg);
  cursor: pointer;
  position: relative;
  overflow: hidden;

  .s-icon {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
