<script setup>
import { ref, computed } from 'vue'
import { RouterLink, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { useMainStore } from '@/stores/main'
import { useSyncStore } from '@/stores/sync'

const router = useRouter()
const mainStore = useMainStore()
const authStore = useAuthStore()
const syncStore = useSyncStore()

const isMenuOpened = ref(false)

const username = computed(() => {
  return authStore.user?.username || ''
})

const toggleTheme = () => {
  const value = mainStore.theme === 'dark-theme' ? 'light-theme' : 'dark-theme'

  mainStore.setTheme(value)
  closeMenu()
}

const showInfoModal = () => {
  mainStore.toggleInfoModal()
  closeMenu()
}

const closeMenu = () => {
  isMenuOpened.value = false
}

const showUpdatesModal = () => {
  mainStore.toggleUpdatesModal()
  closeMenu()
}

const hasUnsyncedData = computed(() => {
  return syncStore.changesList.length
})

const logOut = () => {
  closeMenu()
  if (hasUnsyncedData.value) {
    syncStore.setField('isShowUnsyncedModal', true)
    return
  }

  authStore.authLogout()
  router.push('/login')
}
</script>

<template>
  <div class="desktop-profile">
    <s-pop-tip placement="bottom" v-model="isMenuOpened" :padding="0" trigger="click">
      <div
        :class="`desktop-profile__username ${
          isMenuOpened ? 'desktop-profile__username--active' : ''
        }`"
      >
        <s-text>
          {{ username }}
        </s-text>
        <s-icon name="user" size="lg" />
        <s-icon name="caret-down" size="sm" />
      </div>
      <template #content>
        <ul class="desktop-profile-menu">
          <li class="desktop-profile-menu__item">
            <router-link to="/app/profile" @click="closeMenu">
              <s-text>
                <s-icon name="user" />
                <span>Профиль</span>
              </s-text>
            </router-link>
          </li>
          <li class="desktop-profile-menu__item desktop-profile-menu__item--theme">
            <button @click="toggleTheme">
              <s-text>
                <s-icon :name="mainStore.theme === 'dark-theme' ? 'sun' : 'moon'" />
                <span>{{ mainStore.theme === 'dark-theme' ? 'Светлая тема' : 'Темная тема' }}</span>
              </s-text>
            </button>
          </li>
          <li class="desktop-profile-menu__item">
            <button @click="showInfoModal">
              <s-text>
                <s-icon name="info-circle" />
                <span>О системе</span>
              </s-text>
            </button>
          </li>
          <li class="desktop-profile-menu__item">
            <button @click="showUpdatesModal">
              <s-text>
                <s-icon name="clock-rotate-left" />
                <span>Обновления</span>
              </s-text>
            </button>
          </li>
          <li class="desktop-profile-menu__item desktop-profile-menu__item--logout">
            <button @click="logOut">
              <s-text>
                <s-icon name="sign-out-alt" />
                <span>Выйти</span>
              </s-text>
            </button>
          </li>
        </ul>
      </template>
    </s-pop-tip>
  </div>
</template>

<style lang="scss">
.desktop-profile {
  display: flex;
  align-items: center;
  height: 100%;

  span {
    height: 100%;
  }

  &__username {
    cursor: pointer;
    height: 48px;
    display: flex;
    align-items: center;
    padding: 16px;

    .s-text.default {
      font-size: 16px;
      min-width: 50px;
    }

    &--active {
      background-color: var(--primary--active) !important;

      .s-text.default {
        color: var(--primary);
      }

      & svg {
        color: var(--primary) !important;
      }
    }

    &:hover {
      background-color: var(--primary--active);
    }
  }

  svg {
    margin-left: 8px;
  }

  &-menu {
    &__item {
      padding: 0 16px;
      &:first-child {
        padding-top: 16px;
      }

      &:last-child {
        padding-bottom: 16px;
      }
      .s-text {
        span {
          margin-left: 8px;
        }
      }

      a {
        text-decoration: none;
        padding: 8px 0;
        display: block;

        &:hover {
          .s-text.default {
            color: var(--primary);
          }

          .s-icon {
            color: var(--primary) !important;
          }
        }

        &.router-link-active {
          .s-text.default {
            color: var(--primary);
          }

          .s-icon {
            color: var(--primary) !important;
          }
        }
      }

      button {
        border: none;
        background: none;
        width: 100%;
        padding: 8px 0;
        cursor: pointer;
        text-align: left;

        &:hover {
          .s-text.default {
            color: var(--primary);
          }

          .s-icon {
            color: var(--primary) !important;
          }
        }
      }

      &--logout {
        border-top: 1px solid var(--bg);
      }
    }
  }
}

.light-theme {
  & .desktop-profile-menu__item--logout {
    border-top: 1px solid var(--main-bg);
  }
}
</style>
