export function getDeleteMessageItem(type) {
  return deleteMessagesConfig[type] || deleteMessagesConfig.default
}

export const deleteMessagesConfig = {
  default: {
    title: 'Удаление',
    message: 'Вы действительно хотите удалить объект?',
    success: 'Объект успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить объект'
  },
  objects: {
    title: 'Удаление проекта',
    message: 'Вы уверены, что хотите удалить данный проект? Это действие будет невозможно отменить',
    success: 'Проект успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить проект'
  },
  excavations: {
    title: 'Удаление выработки',
    message:
      'Вы уверены, что хотите удалить данную выработку? Это действие будет невозможно отменить',
    success: 'Выработка успешно удалена',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить выработку'
  },
  soils: {
    title: 'Удаление слоя',
    message:
      'Вы уверены, что хотите удалить выбранный слой? Это действие будет невозможно отменить',
    success: 'Слой успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить слой'
  },
  users: {
    title: 'Удаление',
    message: 'Вы действительно хотите удалить пользователя?',
    success: 'Пользователь успешно удален',
    errorTitle: 'Ошибка',
    error: 'Не удалось удалить пользователя'
  }
}
