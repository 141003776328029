<script setup>
import { ref, inject } from 'vue'
import { useRoute } from 'vue-router'
import { useMq } from 'vue3-mq'

import { useAuthStore } from '@/stores/auth'
import { BASE_URL } from '@/configs/urls'

import ImportTip from './import-tip.vue'
import FilesTemplates from './files-templates.vue'

defineProps({
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['close'])

const mq = useMq()
const $notify = inject('$notify')
const authStore = useAuthStore()
const route = useRoute()

const importActionURL = `${BASE_URL}/api/admin/objects/${route.params.id}`

const loading = ref(false)
const currentFile = ref(null)

const uploadFile = (file) => {
  const formData = new FormData()

  currentFile.value = file.name
  const uploadedFile = file
  formData.append('file', uploadedFile)

  let actionURL = importActionURL
  const extension = uploadedFile.name.split('.')[1]

  if (extension === 'csv') {
    actionURL += `/file/`
  } else {
    actionURL += `/excel/`
  }

  const headers = {
    Authorization: `Token ${authStore?.userToken}`,
    'Content-Disposition': encodeURI(`attachment; filename=${uploadedFile.name}`)
  }

  loading.value = true

  return fetch(actionURL, {
    method: 'POST',
    body: formData,
    headers
  })
}

const importSuccessHandler = () => {
  loading.value = false
  location.reload()
}

const toggleModal = () => {
  emits('close')
}

const importErrorHandler = (e) => {
  console.log('import error --- ', e)
  currentFile.value = null
  loading.value = false

  const title = 'Ошибка'
  const message = 'Не удалось импортировать данные'

  $notify({
    title,
    message,
    type: 'error'
  })
}
</script>

<template>
  <s-modal
    title="Импорт данных из файла"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    @close="toggleModal"
  >
    <div class="import-data" v-loading="loading">
      <div class="import-data__container">
        <s-text>Загрузка файла</s-text>
        <s-upload
          :on-success="importSuccessHandler"
          :on-error="importErrorHandler"
          accepted-file-types=".csv,.xlsx"
          :request="uploadFile"
        />
        <transition name="slide-fade">
          <div v-if="currentFile" class="uploaded-file">
            <s-icon name="file-lines" />
            <s-text>{{ currentFile }}</s-text>
          </div>
        </transition>
      </div>
      <import-tip />
      <files-templates />
    </div>
  </s-modal>
</template>

<style lang="scss">
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}

.import-data {
  display: grid;
  grid-gap: 1rem;
  align-content: start;
  overflow: hidden;
  height: 100%;

  & .uploaded-file {
    display: flex;
    align-items: center;
    gap: 8px;
    background: #384151;
    padding: 2px 4px;
    border-radius: 4px;
    color: #c0d1e0;
  }

  &__container {
    display: grid;
    grid-gap: 0.5rem;
    align-content: start;
  }
}
</style>
