import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import axios from 'axios'

import { getDataFromLS, removeDataFromLS, saveDataToLS } from '@/utils/browser'
import { errorParser } from '@/utils/errors'
import db from '@/libs/db'
import { BASE_URL } from '@/configs/urls.js'

export const useAuthStore = defineStore('auth', () => {
  const userToken = ref(getDataFromLS('userToken') || null)

  const isAuthenticated = computed(() => {
    return !!userToken.value
  })

  const user = ref(getDataFromLS('user') || null)
  const setUser = (profile) => {
    user.value = profile
  }

  const isAdmin = computed(() => {
    return user.value?.is_admin
  })

  const hasDataPermissions = (geologist) => {
    const userId = user.value?.id

    return isAdmin.value || geologist === userId || geologist === null
  }

  const userId = computed(() => {
    return user.value?.id
  })

  const authRequest = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/api-token-auth/`, data)
        .then(({ data }) => {
          const { token } = data

          saveDataToLS('userToken', token)
          axios.defaults.headers.common.Authorization = `Token ${token}`
          userToken.value = token
          resolve(token)
        })
        .catch((error) => {
          console.log('error --- ', error)
          removeDataFromLS('userToken')
          removeDataFromLS('user')
          reject(error)
        })
    })
  }

  const authLogout = () => {
    return new Promise((resolve) => {
      removeDataFromLS('userToken')
      removeDataFromLS('user')
      delete axios.defaults.headers.common.Authorization
      resolve()
      userToken.value = ''
      user.value = null
    })
  }

  const loadUserInfo = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${BASE_URL}/api/users/profile/`)
        .then(async ({ data }) => {
          user.value = data
          resolve(data)
          saveDataToLS('user', JSON.stringify(data))
          const response = await db.profile.toArray()

          if (!response.length) {
            await db.profile.add({
              profile: data
            })
          } else {
            await db.profile.put({
              id: response[0]?.id,
              profile: data
            })
          }
        })
        .catch((error) => {
          errorParser(reject, error)
        })
    })
  }

  return {
    user,
    userToken,
    isAuthenticated,
    isAdmin,
    userId,
    setUser,
    authRequest,
    authLogout,
    loadUserInfo,
    hasDataPermissions
  }
})
