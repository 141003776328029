import { helpers, maxLength, required } from '@vuelidate/validators'
import { useObjectsStore } from '@/stores/objects'

const TITLE_MAX_LENGTH = 500

export const objectValidator = () => {
  const objectsStore = useObjectsStore()

  return {
    title: {
      required: helpers.withMessage('"Название" обязательно для заполнения', required),
      maxLength: helpers.withMessage(
        `Убедитесь, что значение в поле "Название" не превышает ${TITLE_MAX_LENGTH} символов`,
        maxLength(TITLE_MAX_LENGTH)
      ),
      unique: helpers.withMessage('Проект с таким названием уже существует', (value) => {
        if (!value) return true

        const isExisted = objectsStore.objectsList.find(
          (item) => item?.title?.toLowerCase() === value?.toLowerCase()
        )

        if (isExisted && isExisted.server_id === objectsStore.activeObject.server_id) {
          return true
        }

        return !isExisted
      })
    },
    title_short: {
      required: helpers.withMessage('"Короткое название" обязательно для заполнения', required),
      unique: helpers.withMessage('Проект с таким коротким названием уже существует', (value) => {
        if (!value) return true

        const isExisted = objectsStore.objectsList.find(
          (item) => item?.title_short?.toLowerCase() === value?.toLowerCase()
        )

        if (isExisted && isExisted.server_id === objectsStore.activeObject.server_id) {
          return true
        }

        return !isExisted
      })
    },
    num: {
      required: helpers.withMessage('"Шифр" обязателен для заполнения', required),
      unique: helpers.withMessage('Проект с таким шифром уже существует', (value) => {
        if (!value) return true

        const isExisted = objectsStore.objectsList.find(
          (item) => item?.num?.toLowerCase() === value?.toLowerCase()
        )

        if (isExisted && isExisted.server_id === objectsStore.activeObject.server_id) {
          return true
        }

        return !isExisted
      })
    }
  }
}
