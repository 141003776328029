<script setup>
import { useMainStore } from '@/stores/main'
import { useMapStore } from '@/stores/map'
import { getImageSrc } from '@/utils/common'

const mainStore = useMainStore()
const mapStore = useMapStore()

const mapObjectsList = [
  { id: 'excavation', label: 'Скважины' },
  { id: 'recon', label: 'Точки наблюдения' },
  { id: 'processes', label: 'ИГ процессы' }
]
const mapObjectsStatuses = [
  { id: 'default', label: 'Не начата' },
  { id: 'orange', label: 'Вынесена' },
  { id: 'primary', label: 'Бурение / В работе' },
  { id: 'success', label: 'Закрыта' }
]

const getObjectStatusColor = (id) => {
  switch (id) {
    case 'primary':
      return 'var(--primary)'
    case 'orange':
      return 'var(--orange)'
    case 'success':
      return 'var(--success)'
    default: {
      const activeBaselayer = mapStore.getActiveBaselayerName('data-map')

      if (activeBaselayer === 'satelite') return '#fff'
      if (activeBaselayer === 'outdoors') return '#585F70'

      return 'var(--info)'
    }
  }
}

const getIconSrc = (type) => {
  return getImageSrc(`assets/icons/map/${type}-${mainStore.theme}.svg`)
}
</script>

<template>
  <div :class="`map-legend ${mapStore.legend ? 'map-legend--active' : ''}`">
    <button class="map-legend__toggle" @click="mapStore.toggleLegend(!mapStore.legend)">
      <s-title type="small">Легенда</s-title>
      <s-icon name="chevron-down" />
    </button>
    <div class="map-legend__body" v-if="mapStore.legend">
      <ul class="map-legend__groups">
        <li class="map-legend__group">
          <div class="map-legend__group-title">
            <s-text> Отображаемые объекты </s-text>
          </div>
          <div class="map-legend__group-content">
            <ul class="map-legend__group-items">
              <li class="map-legend__group-item" v-for="item in mapObjectsList" :key="item.id">
                <img class="map-legend__object-icon" :src="getIconSrc(item.id)" :alt="item.label" />
                <s-text>{{ item.label }}</s-text>
              </li>
            </ul>
          </div>
        </li>

        <li class="map-legend__group">
          <div class="map-legend__group-title">
            <s-text> Статусы объектов </s-text>
          </div>
          <div class="map-legend__group-content">
            <ul class="map-legend__group-items">
              <li class="map-legend__group-item" v-for="item in mapObjectsStatuses" :key="item.id">
                <div
                  class="map-legend__status-icon"
                  :style="{ backgroundColor: getObjectStatusColor(item.id) }"
                />
                <s-text>{{ item.label }}</s-text>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.map-legend {
  background-color: var(--secondary-bg);
  border-radius: 8px;
  border: 1px solid var(--main-bg);
  position: absolute;
  right: 16px;
  bottom: 16px;
  z-index: 10;

  .s-icon {
    transform: rotateX(180deg);
  }

  &--active {
    border: 1px solid var(--section-border-color);

    .s-icon {
      transform: rotateX(0);
    }

    .map-legend__toggle {
      width: 100%;
      justify-content: space-between;
      background-color: transparent;
    }
  }

  &__body {
    padding: 16px;
  }

  &__groups {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__group {
    &-title {
      margin-bottom: 12px;
    }
    &-items {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    &-item {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  &__toggle {
    cursor: pointer;
    border: none;
    background-color: var(--bg);
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    height: 40px;
    padding: 0 16px;
  }

  &__object-icon {
    width: 16px;
    height: 16px;
  }

  &__status-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
}
</style>
