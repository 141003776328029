import { createApp } from 'vue'
import { generateRandomID } from '@n966/soilbox_ui'
import cloneDeep from 'lodash.clonedeep'
import mapboxgl from 'mapbox-gl'
import distance from '@turf/distance'
import { point as turfPoint } from '@turf/helpers'

import { useEditorStore } from '@/stores/work-planning/editor'
import { useMapStore } from '@/stores/map'
import { useObjectsStore } from '@/stores/objects'

import { getDataLayerConfig } from '@/utils/map/data-points'
import { flyToFeatures } from '@/utils/map/fly'
import { getDataPointNameByType } from '@/utils/strings'
import { calculatePosition, calculateDistance } from '../../../helpers/index'
import { linesDistanceConfig, linesDistanceLabelsConfig } from '../../../config/distance'

import mapEditorPopup from '../map-editor-popup.vue'

const mapEditorPoints = 'map-editor-points'
const mapEditorLabels = 'map-editor-labels'
const mapEditorRecentPoints = 'map-editor-recent-points'
const mapEditorRecentLabels = 'map-editor-recent-labels'
const mapDistanceLines = 'map-distance-lines'
const mapDistanceLabels = 'map-distance-labels'

export class ViewController {
  constructor(mapgl, throttledFnMouseMove, editExcavation) {
    this.mapgl = mapgl
    this.currentStep = 0
    this.features = null
    this.activePointId = null
    this.isMoved = false
    this.throttledFnMouseMove = throttledFnMouseMove
    this.editExcavation = editExcavation
  }

  addDistanceAssets() {
    const distanceSource = this.mapgl.getSource(mapDistanceLines)
    if (!distanceSource) {
      this.mapgl.addSource(mapDistanceLines, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      })

      this.mapgl.addLayer({
        id: mapDistanceLines,
        source: mapDistanceLines,
        ...linesDistanceConfig
      })
    }
    const distanceLinesLayer = this.mapgl.getLayer(mapDistanceLabels)

    if (!distanceLinesLayer) {
      this.mapgl.addLayer({
        id: mapDistanceLabels,
        source: mapDistanceLines,
        ...linesDistanceLabelsConfig
      })
    }
  }

  updateDistanceLines(cursorPoint, nearbyFeatures) {
    const lineFeatures = nearbyFeatures.map((feature) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [cursorPoint.geometry.coordinates, feature.geometry.coordinates]
        },
        properties: {
          distance: calculateDistance([
            cursorPoint.geometry.coordinates,
            feature.geometry.coordinates
          ]).toFixed(2)
        }
      }
    })

    const data = {
      type: 'FeatureCollection',
      features: lineFeatures
    }

    const source = this.mapgl.getSource(mapDistanceLines)
    if (source) {
      source.setData(data)
    }
  }

  calculateCursorDistance = (e) => {
    if (!this.mapgl.getLayer('map-editor-points')) return

    const recentPointsSource = this.mapgl.getSource(mapEditorRecentPoints)
    let layers = []

    if (recentPointsSource) {
      layers.push(mapEditorRecentPoints)
    }

    layers.push(mapEditorPoints)

    const currentZoom = this.mapgl.getZoom()

    let boundingBoxDistance = 1000
    if (currentZoom > 16) {
      boundingBoxDistance = 2000
    }

    const bbox = [
      [e.point.x - boundingBoxDistance, e.point.y - boundingBoxDistance],
      [e.point.x + boundingBoxDistance, e.point.y + boundingBoxDistance]
    ]

    const features = this.mapgl.queryRenderedFeatures(bbox, {
      layers
    })

    const cursorPoint = turfPoint([e.lngLat.lng, e.lngLat.lat])

    const nearbyFeatures = features.filter((feature) => {
      const featurePoint = turfPoint(feature.geometry.coordinates)
      const dist = distance(cursorPoint, featurePoint, { units: 'meters' })
      return dist <= boundingBoxDistance
    })

    if (nearbyFeatures.length > 0) {
      this.addDistanceAssets()
      this.updateDistanceLines(cursorPoint, nearbyFeatures)
    } else {
      this.clearDistanceAssets()
    }
  }

  addPoints() {
    const mapStore = useMapStore()
    const activeMapTheme = mapStore.getActiveBaselayerName('data-map')

    if (!this.mapgl.getSource(mapEditorPoints)) {
      this.mapgl.addSource(mapEditorPoints, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      })

      this.mapgl.addLayer({
        id: mapEditorPoints,
        source: mapEditorPoints,
        ...getDataLayerConfig(activeMapTheme, 'icon')
      })

      this.mapgl.addLayer({
        id: mapEditorLabels,
        source: mapEditorPoints,
        ...getDataLayerConfig(activeMapTheme, 'label')
      })
    }
  }

  updatePoints() {
    const editorStore = useEditorStore()

    if (!editorStore?.cloneExcavationsList?.length) return
    const clone = cloneDeep(editorStore.cloneExcavationsList)

    this.features = clone.map((e) => {
      const { lon_plan, lat_plan } = e
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lon_plan, lat_plan]
        },
        properties: {
          title: e.title,
          h_plan: e.h_plan,
          id: e.id,
          lon_plan,
          lat_plan,
          type: getDataPointNameByType(e.type)
        }
      }
    })

    const source = this.mapgl.getSource(mapEditorPoints)

    if (source) {
      const data = {
        type: 'FeatureCollection',
        features: this.features
      }

      this.centerFeatures(data)
      source.setData(data)
    }
  }

  addRecentPoints() {
    const mapStore = useMapStore()
    const activeMapTheme = mapStore.getActiveBaselayerName('data-map')

    this.mapgl.addSource(mapEditorRecentPoints, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: []
      }
    })

    this.mapgl.addLayer({
      id: mapEditorRecentPoints,
      source: mapEditorRecentPoints,
      ...getDataLayerConfig(activeMapTheme, 'icon')
    })

    this.mapgl.addLayer({
      id: mapEditorRecentLabels,
      source: mapEditorRecentPoints,
      ...getDataLayerConfig(activeMapTheme, 'label')
    })
  }

  updateRecentPoints() {
    const editorStore = useEditorStore()

    const features = editorStore.recentChangesPoints.map((e) => {
      const { lon_plan, lat_plan } = e

      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [lon_plan, lat_plan]
        },
        properties: {
          title: e.title,
          h_plan: e.h_plan,
          id: e.id,
          lon_plan,
          lat_plan,
          type: 'excavation'
        }
      }
    })

    const source = this.mapgl.getSource(mapEditorRecentPoints)
    if (source) {
      source.setData({
        type: 'FeatureCollection',
        features
      })
    }
  }

  addMapAssets() {
    this.addClickMapHandler()
    this.addGrabHandler()
    this.addPoints()
    this.addClickPointHandler()
  }

  addClickMapHandler() {
    const editorStore = useEditorStore()
    const mapStore = useMapStore()

    this.mapgl.on('click', (e) => {
      if (mapStore.activeTool === 'map-ruler') return

      if (editorStore.currentPopup) {
        editorStore.setMapEditorPopupOpen(editorStore.currentPopup.isOpen())
        if (editorStore.mapEditorPopupOpen) {
          return
        }
      }

      if (!this.mapgl.getSource(mapEditorRecentPoints)) {
        this.addRecentPoints()
      }

      if (editorStore.createModeOn) {
        const uuid = generateRandomID(10)
        editorStore.pushChangedPoint({
          lon_plan: e.lngLat.lng,
          lat_plan: e.lngLat.lat,
          x: e.point.x,
          y: e.point.y,
          point_id: uuid
        })

        editorStore.setCurrentStep(2)

        if (this.mapgl.getSource(mapEditorPoints)) {
          this.showPopup(e.lngLat.lng, e.lngLat.lat, uuid)
          this.updateRecentPoints()
        }
      }
    })
  }

  addClickPointHandler() {
    const mapStore = useMapStore()

    this.mapgl.on('click', mapEditorPoints, (e) => {
      if (mapStore.activeTool === 'map-ruler') return

      const features = this.mapgl.queryRenderedFeatures(e.point, {
        layers: [mapEditorPoints]
      })

      if (features.length > 0) {
        const data = {}
        const lat = e.lngLat.lat
        const lng = e.lngLat.lng
        const id = features[0].properties.id
        data.h_plan = features[0].properties.h_plan
        data.title = features[0].properties.title

        this.showPopup(lng, lat, id, data)
      }
    })
  }

  showPopup(lng, lat, id, data) {
    const editorStore = useEditorStore()

    const popupElement = document.createElement('div')

    const popupApp = createApp(mapEditorPopup, {
      id,
      data
    })

    popupApp.mount(popupElement)

    if (editorStore.currentPopup) {
      editorStore.currentPopup.remove()
    }

    const anchor = calculatePosition(this.mapgl, lng, lat)

    const currentPopup = new mapboxgl.Popup({
      offset: 14,
      anchor,
      closeButton: false,
      closeOnClick: false,
      focusAfterOpen: true
    })
      .setLngLat([lng, lat])
      .setDOMContent(popupElement)
      .addTo(this.mapgl)

    currentPopup.id = id
    editorStore.setPopup(currentPopup)
    editorStore.setMapEditorPopupOpen(true)

    this.removeMouseMoveHandler()
    this.clearDistanceAssets()
  }

  clearDistanceAssets() {
    const existDistanceLinesLayer = this.mapgl.getLayer(mapDistanceLines)
    if (existDistanceLinesLayer) {
      this.mapgl.removeLayer(mapDistanceLines)
      this.mapgl.removeLayer(mapDistanceLabels)
      this.mapgl.removeSource(mapDistanceLines)
    }
  }

  removeMouseMoveHandler() {
    this.mapgl.off('mousemove', this.throttledFnMouseMove)
  }

  addGrabHandler() {
    const mapStore = useMapStore()
    this.mapgl.on('mousedown', mapEditorPoints, (e) => {
      if (this.currentPopup) return
      if (mapStore.activeTool === 'map-ruler') return

      if (e.features.length > 0) {
        this.activePointId = e.features[0].properties.id
        e.preventDefault()
        this.mapgl.on('mousemove', this.onMove)
        this.mapgl.once('mouseup', this.onUp)
      }
    })
  }

  onUp = (e) => {
    const editorStore = useEditorStore()

    this.mapgl.off('mousemove', this.onMove)
    if (!this.isMoved) return

    editorStore.recentChangesPoints.forEach((point) => {
      if (point.id === this.activePointId) {
        point.lat_plan = e.lngLat.lat
        point.lon_plan = e.lngLat.lng
      }
    })

    editorStore.cloneExcavationsList.forEach((point) => {
      if (point.id === this.activePointId) {
        point.lat_plan = e.lngLat.lat
        point.lon_plan = e.lngLat.lng

        this.checkIsAlreadyAdded(point)
      }
    })

    this.turnOnEditor()

    this.activePointId = null
    this.isMoved = false
  }

  centerFeatures(data) {
    flyToFeatures(this.mapgl, data)
  }

  onMove = (e) => {
    const editorStore = useEditorStore()

    this.isMoved = true
    if (editorStore.createModeOn) return

    const coords = e.lngLat
    const featureIndex = this.features.findIndex((feature) => {
      return feature.properties.id === this.activePointId
    })

    if (featureIndex !== -1) {
      this.features[featureIndex].geometry.coordinates = [coords.lng, coords.lat]

      this.mapgl.getSource(mapEditorPoints).setData({
        type: 'FeatureCollection',
        features: this.features
      })
    }
  }

  checkIsAlreadyAdded(point) {
    const editorStore = useEditorStore()

    const isAlreadyChanged = editorStore.recentChangesPoints.find((item) => item.id === point.id)
    if (!isAlreadyChanged) {
      editorStore.pushChangedPoint(point)
    }
  }

  resetEditor() {
    const editorStore = useEditorStore()
    const mapStore = useMapStore()
    const objectsStore = useObjectsStore()

    editorStore.setCurrentStep(0)
    editorStore.setCreateMode(false)
    mapStore.setActiveTool(null)
    const clone = cloneDeep(objectsStore?.excavationsList)
    editorStore.setClonePoints(clone)
    editorStore.clearChangedPoints()
    this.updateRecentPoints()
    this.updatePoints()
  }

  turnOnEditor() {
    const mapStore = useMapStore()
    const editorStore = useEditorStore()

    if (mapStore.activeTool === 'map-editor') return
    mapStore.setActiveTool('map-editor')
    editorStore.setCurrentStep(2)
  }
}
