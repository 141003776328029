import db from '@/libs/db'

import { useRequests } from '@/composables'
import { useMainStore } from '@/stores/main'

import { getAllChanges } from '@/utils/data-sync'
import appGlobalProperties from '@/main'
import { getDeleteMessageItem, deleteMessagesConfig } from '@/configs/global'

const delete_urls = {
  groundwater: (id) => {
    return `groundwater/${id}/`
  },
  soils: (id) => {
    return `soils/${id}/`
  },
  coreboxes: (id) => {
    return `coreboxes/${id}/`
  },
  samples: (id) => {
    return `samples/${id}/`
  },
  boring: (id) => {
    return `borepass/${id}/`
  }
}

export const deleteObject = async (item, table = 'default', callback) => {
  if (!table) throw new Error('Не указана таблица')

  const { deleteRequest } = useRequests()
  const mainStore = useMainStore()

  const title = deleteMessagesConfig[table]?.title || deleteMessagesConfig.default?.title
  const message = deleteMessagesConfig[table]?.message || deleteMessagesConfig.default?.message

  appGlobalProperties
    .$confirm(message, title, {
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отменить',
      confirmButtonClass: 'error'
    })
    .then(async () => {
      try {
        const url = delete_urls[table]?.(item.server_id)
        const excavIdb = item.excavation_id?.includes('idb')
        const excavId = excavIdb
          ? Number(item.excavation_id?.replace('idb_', ''))
          : Number(item.excavation_id)
        const excavation = await db.excavations
          .where({ [excavIdb ? 'id' : 'server_id']: excavId })
          .first()

        if (mainStore.isOnline && item?.server_id && !mainStore.noSyncMode) {
          await deleteRequest(url)
          await db.deleteObject(table, item.id)
        } else if (item?.server_id) {
          await db.deleteObject(table, item.id)
          await db.deleted.add({
            table,
            item_id: item.server_id,
            date: new Date(),
            delete_url: url,
            object_id: excavation.object_id,
            title: excavation.title,
            excavation_id: item.excavation_id
          })
          getAllChanges()
        } else if (!item?.server_id) {
          const createdItem = await db.created.where({ item_id: item.id }).first()
          await db.deleteObject(table, item.id)
          await db.created.delete(createdItem.id)

          getAllChanges()
        }

        if (callback) {
          callback()
        }

        const message =
          deleteMessagesConfig[table]?.success || deleteMessagesConfig.default?.success
        appGlobalProperties.$notify({
          title,
          message,
          type: 'success'
        })
      } catch (e) {
        const title =
          deleteMessagesConfig[table]?.errorTitle || deleteMessagesConfig.default?.errorTitle
        const message = deleteMessagesConfig[table]?.error || deleteMessagesConfig.default?.error
        appGlobalProperties.$notify({
          title,
          message,
          type: 'error'
        })
      }
    })
    .catch(() => {})
}

export const deleteHandler = async (item, type = 'default', callback, dataType = 'excavations') => {
  const mainStore = useMainStore()
  const { deleteRequest } = useRequests()

  const title = deleteMessagesConfig[type]?.title || deleteMessagesConfig.default?.title
  const message = deleteMessagesConfig[type]?.message || deleteMessagesConfig.default?.message

  appGlobalProperties
    .$confirm(message, title, {
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отменить',
      confirmButtonClass: 'error'
    })
    .then(async () => {
      try {
        if (mainStore.isOnline && item?.server_id && !mainStore.noSyncMode) {
          const url = dataType === 'excavations' ? 'excavations' : 'reconnaissance'
          await deleteRequest(`${url}/${item.server_id}/`)
          await db.deleteObject(dataType, item.id)
        } else if (item?.server_id) {
          await db.deleteObject(dataType, item.id)
          await db.deleted.add({
            table: dataType,
            item_id: item?.server_id,
            date: new Date(),
            object_id: item.object_id,
            title: item.title
          })
          getAllChanges()
        } else if (!item?.server_id) {
          const existCreated = await db.created.get({ item_id: Number(item.id) })
          if (existCreated) {
            db.created.delete(existCreated.id)
          }
          await db.deleteObject(dataType, item.id)
          getAllChanges()
        }

        if (callback) {
          callback()
        }

        const message = deleteMessagesConfig[type]?.success || deleteMessagesConfig.default?.success
        appGlobalProperties.$notify({
          title,
          message,
          type: 'success'
        })
      } catch (e) {
        const title =
          deleteMessagesConfig[type]?.errorTitle || deleteMessagesConfig.default?.errorTitle
        const message = deleteMessagesConfig[type]?.error || deleteMessagesConfig.default?.error
        appGlobalProperties.$notify({
          title,
          message,
          type: 'error'
        })
      }
    })
    .catch(() => {})
}

export const deleteItem = async (url, type = 'default', callback, customMessage) => {
  let messageItem
  if (type === 'custom') {
    messageItem = customMessage
  } else {
    messageItem = getDeleteMessageItem(type)
  }

  const { title, message, success, errorTitle, error } = messageItem

  const { deleteRequest } = useRequests()

  appGlobalProperties
    .$confirm(message, title, {
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Отменить',
      confirmButtonClass: 'error'
    })
    .then(async () => {
      try {
        await deleteRequest(url)

        if (callback) {
          callback()
        }

        appGlobalProperties.$notify({
          title,
          message: success,
          type: 'success'
        })
      } catch (e) {
        appGlobalProperties.$notify({
          title: errorTitle,
          message: error,
          type: 'error'
        })
      }
    })
    .catch(() => {})
}
