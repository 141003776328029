import router from '@/router'

import { useMapStore } from '@/stores/map'
import { useObjectsStore } from '@/stores/objects'
import { useExcavationStore } from '@/stores/excavation'
import { useReconStore } from '@/stores/recon'
import { useServicesStore } from '@/stores/services'

import { getGeojson } from '@/utils/map/common'
import { clusterProperties, updateClusterMarkers } from '@/utils/map/data-clusters'
import { getDataLayerConfig, removeDataLayers } from '@/utils/map/data-points'
import { flyToFeatures } from '@/utils/map/fly'
import { getDataPointNameByType } from '@/utils/strings'
import { centerMapOnActiveFeatures } from '../helpers'

export class DataPointsController {
  constructor(mapgl, route, popupSettings) {
    this.mapgl = mapgl
    this.mapStore = useMapStore()
    this.objectsStore = useObjectsStore()
    this.excavationStore = useExcavationStore()
    this.reconStore = useReconStore()
    this.servicesStore = useServicesStore()
    this.router = router
    this.route = route
    this.popupSettings = popupSettings

    this.layerId = 'data-points'
    this.markers = {
      all: {},
      onScreen: {}
    }

    this.clusterRenderHandler = this.clusterRenderHandler.bind(this)
    this.mousemoveHandler = this.mousemoveHandler.bind(this)
    this.mouseleaveHandler = this.mouseleaveHandler.bind(this)
    this.clickHandler = this.clickHandler.bind(this)
    this.processesMousemoveHandler = this.processesMousemoveHandler.bind(this)
    this.processesMouseleaveHandler = this.processesMouseleaveHandler.bind(this)
  }

  getDataPoints() {
    const { excavationsList, reconsList } = this.objectsStore
    const excavs = excavationsList || []
    const recons = reconsList || []
    const activeExcav = this.excavationStore.active
    const activeRecon = this.reconStore.active
    let dataList

    if (activeExcav) {
      dataList = [...excavs.filter((excav) => excav.id !== activeExcav.id), activeExcav]
    } else if (activeRecon) {
      dataList = [...recons.filter((recon) => recon.id !== activeRecon.id), activeRecon]
    } else {
      switch (this.route.query.type) {
        case 'excavations':
          dataList = excavs
          break
        case 'recon':
          dataList = recons
          break
        default:
          dataList = [...excavs, ...recons]
      }
    }

    return getGeojson(
      dataList.map((data) => ({
        ...data,
        x: data.lat || data.lat_plan,
        y: data.lon || data.lon_plan,
        type: getDataPointNameByType(data.type)
      }))
    )
  }

  showDataPoints(type) {
    const data = this.getDataPoints()

    if (!data) return

    this.removeDataPoints()

    const { settings } = this.mapStore

    if (settings.clusters) {
      this.addPointsWithClusters(data)
    } else {
      this.addPointsWithoutClusters(data)
    }

    this.mapgl.on('mousemove', `${this.layerId}-icon`, this.mousemoveHandler)
    this.mapgl.on('mousemove', `${this.layerId}-label`, this.mousemoveHandler)
    this.mapgl.on('mouseleave', `${this.layerId}-icon`, this.mouseleaveHandler)
    this.mapgl.on('mouseleave', `${this.layerId}-label`, this.mouseleaveHandler)
    this.mapgl.on('click', `${this.layerId}-icon`, this.clickHandler)
    this.mapgl.on('click', `${this.layerId}-label`, this.clickHandler)

    if (settings.processes) {
      this.addProcesses()
    }

    if (type === 'without-fly-to') return

    if (this.excavationStore.active || this.reconStore.active) {
      centerMapOnActiveFeatures('data-points', this.mapgl)
    } else {
      flyToFeatures(this.mapgl, data)
    }
  }

  addPointsWithClusters(data) {
    const activeMapTheme = this.mapStore.getActiveBaselayerName('data-map')

    this.mapgl.addSource(this.layerId, {
      type: 'geojson',
      cluster: true,
      clusterRadius: 40,
      clusterProperties,
      data
    })

    this.mapgl.addLayer({
      id: `${this.layerId}-icon`,
      source: this.layerId,
      filter: ['!=', 'cluster', true],
      ...getDataLayerConfig(activeMapTheme, 'icon')
    })
    this.mapgl.addLayer({
      id: `${this.layerId}-label`,
      source: this.layerId,
      filter: ['!=', 'cluster', true],
      ...getDataLayerConfig(activeMapTheme, 'label')
    })

    this.mapgl.on('render', this.clusterRenderHandler)
  }

  addPointsWithoutClusters(data) {
    const activeMapTheme = this.mapStore.getActiveBaselayerName('data-map')

    this.mapgl.addSource(this.layerId, {
      type: 'geojson',
      data
    })

    this.mapgl.addLayer({
      id: `${this.layerId}-icon`,
      source: this.layerId,
      ...getDataLayerConfig(activeMapTheme, 'icon')
    })
    this.mapgl.addLayer({
      id: `${this.layerId}-label`,
      source: this.layerId,
      ...getDataLayerConfig(activeMapTheme, 'label')
    })
  }

  clusterRenderHandler() {
    updateClusterMarkers(this, 'data-map')
  }

  addProcesses() {
    const activeMapTheme = this.mapStore.getActiveBaselayerName('data-map')

    this.mapgl.addLayer({
      id: `${this.layerId}-processes`,
      source: this.layerId,
      filter: [
        'all',
        ['has', 'processes'],
        ['!=', ['length', ['get', 'processes']], 0],
        ['!', ['has', 'cluster']]
      ],
      ...getDataLayerConfig(activeMapTheme, 'processes')
    })

    this.mapgl.on('mousemove', `${this.layerId}-processes`, this.processesMousemoveHandler)
    this.mapgl.on('mouseleave', `${this.layerId}-processes`, this.processesMouseleaveHandler)
  }

  processesMousemoveHandler(e) {
    const { features, point } = e
    const pointProcesses = JSON.parse(features[0].properties.processes)

    this.mapgl.getCanvas().style.cursor = 'pointer'

    this.popupSettings.show = true
    this.popupSettings.x = point.x + 8
    this.popupSettings.y = point.y + 8
    this.popupSettings.title = 'ИГ процессы'
    this.popupSettings.description = pointProcesses.map((id) => {
      const { types } = this.servicesStore.processes

      return types.find((t) => t.id === id).title
    })
  }

  processesMouseleaveHandler() {
    this.mapgl.getCanvas().style.cursor = ''

    this.popupSettings.show = false
  }

  removeProcesses() {
    this.mapgl.removeLayer(`${this.layerId}-processes`)

    this.mapgl.off('mousemove', `${this.layerId}-processes`, this.processesMousemoveHandler)
    this.mapgl.off('mouseleave', `${this.layerId}-processes`, this.processesMouseleaveHandler)
  }

  mousemoveHandler() {
    this.mapgl.getCanvas().style.cursor = 'pointer'
  }

  mouseleaveHandler() {
    this.mapgl.getCanvas().style.cursor = ''
  }

  clickHandler(e) {
    const { features } = e

    if (!features.length) return

    const { server_id, id, type } = features[0].properties

    const dataId = server_id || `idb_${id}`
    const url = `/app/data/${type}?id=${dataId}`

    this.router.push(url)
  }

  removeDataPoints() {
    this.mapgl.off('mousemove', `${this.layerId}-icon`, this.mousemoveHandler)
    this.mapgl.off('mousemove', `${this.layerId}-label`, this.mousemoveHandler)
    this.mapgl.off('mouseleave', `${this.layerId}-icon`, this.mouseleaveHandler)
    this.mapgl.off('mouseleave', `${this.layerId}-label`, this.mouseleaveHandler)
    this.mapgl.off('click', `${this.layerId}-icon`, this.clickHandler)
    this.mapgl.off('click', `${this.layerId}-label`, this.clickHandler)

    removeDataLayers(this.mapgl, this.layerId)

    this.removeClusters()
  }

  removeClusters() {
    this.mapgl.off('render', this.clusterRenderHandler)

    for (const id in this.markers.all) {
      this.markers.all[id].remove()
    }

    this.markers.all = {}
    this.markers.onScreen = {}
  }
}
