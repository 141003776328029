<script setup>
import { onMounted, watch, onUnmounted, inject, ref } from 'vue'
import { ViewController } from './controllers'

import { useRulerStore } from '@/stores/work-planning/ruler'
import { useMapStore } from '@/stores/map'

import { getDataFromLS } from '@/utils/browser'

import RulerConfirm from './ruler-confirm.vue'

const props = defineProps({
  mapgl: {
    type: Object,
    required: true
  }
})

const rulerStore = useRulerStore()
const mapStore = useMapStore()

const viewController = ref(null)
const $notify = inject('$notify')

watch(
  () => rulerStore.isShowNotify,
  (newValue) => {
    if (newValue) {
      const message = 'Значение длины скопировано в буфер обмена'
      $notify({
        message,
        type: 'success',
        duration: 3000
      })

      rulerStore.setShowNotify(false)
    }
  }
)

watch(
  () => rulerStore.clearAssets,
  (newValue) => {
    if (newValue) {
      viewController.value.clearMapAssets()
      rulerStore.setClearAssets(false)
      rulerStore.setTotalDistance(0)
    }
  }
)

watch(
  () => rulerStore.isRulerOn,
  (newValue) => {
    if (newValue) {
      viewController.value.addPoints()
      viewController.value.addLines()
    } else {
      viewController.value.clearMapAssets()
      mapStore.setActiveTool(null)
      rulerStore.setTotalDistance(0)
    }
  }
)

watch(
  () => rulerStore.points,
  (newValue, oldValue) => {
    if (newValue.length < 1) return
    if (newValue.length < oldValue.length) {
      viewController.value.updatePoints()
      viewController.value.updateLines()
      viewController.value.showPopup(rulerStore.lastPointData)
    }
  },
  { deep: true }
)

const toggleRuler = () => {
  if (!rulerStore.lastPointData && rulerStore.isRulerOn) {
    rulerStore.toggleRuler()
    return
  }

  if (mapStore.activeTool && mapStore.activeTool !== 'map-ruler') return

  if (!rulerStore.isRulerOn) {
    mapStore.setActiveTool('map-ruler')
    rulerStore.toggleRuler()
    return
  }

  const isShowedRulerModal = getDataFromLS('dont-show')

  if (!isShowedRulerModal) {
    rulerStore.setIsShowRulerModal(true)
    return
  }

  const lastItem = rulerStore.points[rulerStore.points.length - 1]
  rulerStore.setLastPoint(lastItem)
  viewController.value.clearMapAssets()
  rulerStore.toggleRuler()
}

const turnOffRuler = () => {
  rulerStore.toggleRuler()
  rulerStore.setIsShowRulerModal(false)
  viewController.value.clearMapAssets()
}

onMounted(() => {
  viewController.value = new ViewController(props.mapgl)

  viewController.value.addClickHandler()
})

onUnmounted(() => {
  viewController.value.clearMapAssets()
})
</script>

<template>
  <div class="s-ruler" @click="toggleRuler" :class="{ active: rulerStore.isRulerOn }">
    <s-icon name="ruler-horizontal" />
    <s-text>Линейка</s-text>
  </div>
  <ruler-confirm @closeModal="turnOffRuler" :isVisible="rulerStore.isShowRulerModal" />
</template>

<style lang="scss">
.s-ruler {
  gap: 0.5rem;
  background: var(--bg);
  border-radius: var(--border-radius);
  border: 1px solid var(--main-bg);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 40px;
  color: var(--text);
  cursor: pointer;

  &.active {
    background: var(--primary);
  }
}
</style>
