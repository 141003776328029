<script setup>
import { ref, computed, onMounted, inject, watch, reactive } from 'vue'
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { useRoute } from 'vue-router'
import { useMq } from 'vue3-mq'

import db from '@/libs/db'

import { useVuelidate } from '@vuelidate/core'
import { SNumberInputValidator } from '@/validators'

import { useExcavationStore } from '@/stores/excavation'
import { useMainStore } from '@/stores/main'
import { useRitmDate, useRequests } from '@/composables'

import { getSteps, checkDepth } from './helpers'
import { getUuid } from '@/utils/browser'
import { layerTemplate, getLayerInfoFromData } from '@/utils/soils'
import { getExcavationsChanges } from '@/utils/data-sync/excavation'

import ProgressComp from '../../excavation-start/components/start-progress.vue'
import WikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import CreateSoilInfo from './components/soil-info.vue'
import DepthComp from './components/soil-depth.vue'
import SoilTypeComp from './components/soil-type.vue'
import ColorSelectComp from './components/soil-color-select.vue'
import SelectComp from './components/soil-select-comp.vue'
import SoilWaterComp from './components/soil-water-comp.vue'
import GroupSelectComp from './components/soil-group-select-comp.vue'
import InclusionsComp from './components/soil-inclusions.vue'
import InterlayersComp from './components/soil-interlayers.vue'
import AdditionalComp from './components/soil-additional.vue'
import SampleInfoComp from './components/soil-sample-info.vue'
import TextPfrostComp from './components/text-pfrost.vue'
import IceContentComp from './components/ice-content.vue'
import StatePfrostComp from './components/state-pfrost.vue'

const props = defineProps({
  excavation: {
    type: Object,
    default: () => null
  },
  isVisible: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['toggle'])

const mq = useMq()
const route = useRoute()
const rDate = useRitmDate()
const $notify = inject('$notify')
const { postRequest } = useRequests()
const mainStore = useMainStore()
const excavationStore = useExcavationStore()

const loading = ref(false)
const layerTemplateLocal = ref(cloneDeep(layerTemplate()))
const initSource = ref(null)
const activeStep = ref(0)
const showAdditional = ref(false)
const soilCreated = ref(false)
const allDone = ref(false)
const changed = ref(false)
const soilType = ref('')
const soilTypeBak = ref(null)
const idbDataLocal = ref(null)

const hasChanges = computed(() => {
  return !isEqual(initSource.value, layerTemplateLocal.value)
})

const excavationId = computed(() => {
  return route.query.id
})

const disabled = computed(() => {
  switch (activeStep.value) {
    case 0:
      return !checkDepth(layerTemplateLocal.value.foot_d, prevDepth.value)
    case 1:
      return !layerTemplateLocal.value.soil_type
    default:
      return false
  }
})

const prevDepth = computed(() => {
  const depth = layerTemplateLocal.value.foot_d
  const index = sectionData.value.findIndex((e) => e.foot_d > depth)
  const length = sectionData.value.length
  const prevDepth =
    index <= 0 && !sectionData.value.length
      ? 0
      : index < 0 && sectionData.value.length
        ? sectionData.value[length - 1]?.foot_d
        : sectionData.value[index - 1]?.foot_d

  return prevDepth || 0
})

const sectionData = computed(() => {
  const data = soilsList.value.map(({ foot_d }) => {
    return {
      foot_d
    }
  })

  if (!data?.length) return []

  return (
    data.sort((a, b) => {
      if (a.foot_d > b.foot_d) return 1
      if (a.foot_d < b.foot_d) return -1
      return 0
    }) || []
  )
})

const soilsList = computed(() => {
  return excavationStore.soilsList || []
})

const filteredSteps = computed(() => {
  return getSteps(soilType.value, showAdditional.value, soilTypeBak.value)
})

const activeComp = computed(() => {
  return filteredSteps.value?.find((e, i) => i === activeStep.value) || null
})

const createTrigger = computed(() => {
  return activeComp.value?.createTrigger || (soilType.value === 'prs' && activeStep.value === 1)
})

const lastStep = computed(() => {
  return filteredSteps.value?.length - 1 === activeStep.value
})

const additionalTrigger = computed(() => {
  return activeComp.value?.additionalTrigger || (soilType.value === 'prs' && activeStep.value === 1)
})

const componentSubtitle = computed(() => {
  return activeComp.value?.subtitle || ''
})

const compMap = {
  depth: DepthComp,
  'soil-type': SoilTypeComp,
  'color-select': ColorSelectComp,
  'soil-water': SoilWaterComp,
  'group-select': GroupSelectComp,
  select: SelectComp,
  inclusions: InclusionsComp,
  interlayers: InterlayersComp,
  additional: AdditionalComp,
  'text-pfrost': TextPfrostComp,
  'ice-content': IceContentComp,
  'state-pfrost': StatePfrostComp,
  'sample-info': SampleInfoComp
}

const componentName = computed(() => {
  return compMap[activeComp.value?.type] || ''
})

const componentId = computed(() => {
  return activeComp.value?.id || ''
})

const buttonName = computed(() => {
  switch (componentId.value) {
    case 'depth':
    case 'soil_type':
      if (soilType.value === 'prs') return 'Завершить создание слоя'
      return 'Далее'
    default:
      if (createTrigger.value) {
        if (additionalTrigger.value || (!changed.value && !lastStep.value))
          return 'Пропустить и завершить'
        return 'Завершить создание слоя'
      }
      if (!changed.value) return 'Пропустить'
      return 'Далее'
  }
})

const buttonType = computed(() => {
  if (createTrigger.value) {
    return 'success'
  } else {
    return 'primary'
  }
})

const soilInterval = computed(() => {
  const depth = layerTemplateLocal.value.foot_d

  return {
    from: prevDepth.value || 0,
    to: depth || 0
  }
})

const setSoilType = (type) => {
  soilType.value = type
}

const setSoilTypeBak = (type) => {
  soilTypeBak.value = type
}

const clickHandler = async () => {
  const result = await v$.value.$validate()

  if (!result) {
    return
  }

  const index = filteredSteps.value?.findIndex((e) => e.id === componentId.value)

  if (index + 1 === filteredSteps.value?.length) {
    createSoil()
  } else {
    const element = document.getElementById('createguidebody')
    if (element.scrollTop !== 0) {
      element.scrollTop = 0
    }
    activeStep.value++
    changed.value = false
  }
}

const createSoil = async () => {
  loading.value = true
  soilCreated.value = false
  idbDataLocal.value = null
  allDone.value = false

  try {
    const id = props.excavation?.server_id || `idb_${props.excavation.id}`
    const data = cloneDeep(layerTemplateLocal.value)
    const inclusions = cloneDeep(data.inclusions)
    const interlayers = cloneDeep(data.interlayers)
    data.uuid = getUuid()
    data.description = getLayerInfoFromData(layerTemplateLocal.value) || ''
    data.date_front = rDate(new Date()).format('iso')
    data.excavation_id = id

    let idbData

    if (mainStore.isOnline && props.excavation?.server_id && !mainStore.noSyncMode) {
      delete data.inclusions
      delete data.interlayers

      const soil = await postRequest(`excavations/${props.excavation?.server_id}/soils/`, data)
      soilCreated.value = true
      idbData = soil
      idbData.server_id = cloneDeep(soil.id)
      idbData.excavation_id = String(id)
      delete idbData.id
      idbDataLocal.value = idbData

      if (inclusions?.length) {
        await Promise.all(
          inclusions.map(async (e) => {
            e.date_front = new Date()
            const inclusion = await postRequest(`soils/${idbData.server_id}/inclusions/`, e)
            inclusions.forEach((n) => {
              if (n.id === e.id) n.server_id = inclusion.id
            })
          })
        )
        idbData.inclusions = inclusions
      }
      if (interlayers?.length) {
        await Promise.all(
          interlayers.map(async (e) => {
            e.date_front = new Date()
            const interlayer = await postRequest(`soils/${idbData.server_id}/interlayers/`, e)
            interlayers.forEach((n) => {
              if (n.id === e.id) n.server_id = interlayer.id
            })
          })
        )
        idbData.interlayers = interlayers
      }
    } else {
      idbData = data
      idbData.excavation_id = String(id)
    }
    const filter = { field: 'excavation_id', value: excavationId.value }

    const idbResponse = await db.addObject('soils', cloneDeep(idbData), filter)

    if (!mainStore.isOnline || mainStore.noSyncMode) {
      await db.created.add({
        table: 'soils',
        date: new Date(),
        item_id: idbResponse
      })
    }

    if (inclusions?.length) {
      await Promise.all(
        inclusions.map(async (e) => {
          e.soil_server_id = idbData.server_id || null
          e.soil_id = idbResponse
          e.server_id = e.server_id || null
          if (e.id) delete e.id

          const inclusionId = await db.addObject('inclusions', cloneDeep(e))

          if (!mainStore.isOnline || mainStore.noSyncMode) {
            await db.created.add({
              table: 'inclusions',
              date: new Date(),
              item_id: inclusionId
            })
          }
        })
      )
    }
    if (interlayers?.length) {
      await Promise.all(
        interlayers.map(async (e) => {
          e.soil_server_id = idbData.server_id || null
          e.soil_id = idbResponse
          e.server_id = e.server_id || null
          if (e.id) delete e.id

          const interlayersId = await db.addObject('interlayers', cloneDeep(e))

          if (!mainStore.isOnline || mainStore.noSyncMode) {
            await db.created.add({
              table: 'interlayers',
              date: new Date(),
              item_id: interlayersId
            })
          }
        })
      )
    }

    if (!mainStore.isOnline || mainStore.noSyncMode) {
      getExcavationsChanges()
    }

    const title = 'Создание'
    const message = `Слой успешно создан`

    $notify({
      title,
      message,
      type: 'success'
    })
    allDone.value = true
    emits('toggle')
  } catch (e) {
    console.log(e)
    $notify({
      message: `Произошла ошибка при создании слоя. ${e}`,
      type: 'error'
    })
  } finally {
    loading.value = false

    if (soilCreated.value && !allDone.value) {
      const filter = { field: 'excavation_id', value: excavationId.value }

      await db.addObject('soils', cloneDeep(idbDataLocal.value), filter)

      const title = 'Создание'
      const message = `Слой был создан, без прослоев/включений`

      $notify({
        title,
        message,
        type: 'warning'
      })
      emits('toggle')
    }
  }
}

const startAdditional = () => {
  showAdditional.value = true
  activeStep.value++
  changed.value = false
}

const isWarning = ref(false)

const rules = reactive({
  foot_d: SNumberInputValidator('Глубина подошвы')
})

const v$ = useVuelidate(rules, layerTemplateLocal)

watch(
  () => v$.value.$errors,
  (newValue) => {
    if (newValue.length === 0) {
      isWarning.value = false
      return
    }

    isWarning.value = true
  }
)

const back = () => {
  activeStep.value--
  v$.value.$reset()
}

onMounted(() => {
  initSource.value = cloneDeep(layerTemplateLocal.value)
})
</script>

<template>
  <s-modal
    centerTitle
    title="Создание слоя"
    :show="isVisible"
    :fullscreen="mq.current !== 'lg'"
    :back-button="activeStep !== 0"
    :confirm-condition="hasChanges"
    confirm-on-cancel
    @back="back"
    @close="emits('toggle')"
  >
    <div id="createguidebody" class="create-guide">
      <progress-comp :active="activeStep + 1" :steps="filteredSteps" />
      <create-soil-info
        v-if="activeStep !== 0 && layerTemplateLocal.foot_d"
        :data="layerTemplateLocal"
        :soil-interval="soilInterval"
      />
      <div class="create-guide-header">
        <div class="create-guide-header__wiki-tip">
          <s-title type="small">
            {{ activeComp.title }}
          </s-title>
          <wiki-tip v-if="activeComp.wikiTipData" :data="activeComp.wikiTipData">
            <s-icon name="fa-circle-question" :color="`var(--${activeComp.wikiTipData.color})`" />
          </wiki-tip>
        </div>
        <s-text v-if="componentSubtitle" type="secondary">
          {{ componentSubtitle }}
        </s-text>
      </div>
      <component
        :is="componentName"
        :source="layerTemplateLocal"
        :soil-interval="soilInterval"
        :field="activeComp"
        @set-soil-type="setSoilType"
        @set-soil-type-bak="setSoilTypeBak"
        @trigger-changed="changed = $event"
        :vuelidate="v$"
      />
    </div>
    <template #footer>
      <div class="create-guide-footer">
        <s-button v-if="additionalTrigger" :disabled="disabled" @click="startAdditional">
          Продолжить расширенное описание
        </s-button>
        <s-button
          :type="buttonType"
          :loading="loading"
          :disabled="disabled || loading || isWarning"
          @click="clickHandler"
        >
          {{ buttonName }}
        </s-button>
      </div>
    </template>
  </s-modal>
</template>

<style lang="scss">
.create-guide {
  display: grid;
  gap: 1rem;
  overflow: auto;
  height: 100%;
  align-content: start;

  &-header {
    display: grid;
    gap: 0.25rem;

    &__wiki-tip {
      display: flex;
      justify-content: space-between;
    }
  }

  &-footer {
    display: grid;
    gap: 1rem;
  }
}
</style>
