import { useRitmDate } from '@/composables/useRitmDate.js'

const ritmDate = useRitmDate()

export const fieldsTypeConfig = {
  attrs: {
    id: 'integer',
    title: 'string',
    title_short: 'string',
    foot_d: 'number',
    method: 'number',
    diam: 'number',
    casing: 'number',
    casing_meters: 'number',
    tcr_length: 'number',
    tcr_value: 'number',
    scr_length: 'number',
    scr_value: 'number',
    rqd_length: 'number',
    rqd_value: 'number',
    soil_type: 'integer',
    color_main: 'integer',
    color_optional: 'integer',
    color_comments: 'string',
    filler: 'integer',
    filler_comments: 'string',
    cement: 'integer',
    cement_comments: 'string',
    state: 'integer',
    state_pfrost: 'integer',
    struct_text_pfrost: 'integer',
    struct_text_comments: 'string',
    composition_comments: 'string',
    alter_mud: 'integer',
    alter_argillization: 'integer',
    alter_organic: 'integer',
    alter_peat: 'integer',
    alter_decomposition: 'integer',
    alter_integrity: 'integer',
    alter_gran_composition: 'integer',
    alter_weathering: 'integer',
    alter_salt: 'integer',
    alter_heaving: 'integer',
    alter_swell: 'integer',
    alter_techno: 'integer',
    alter_mother_soil: 'integer',
    alter_subsidence: 'integer',
    alter_comments: 'string',
    water_saturation: 'integer',
    water_saturation_comments: 'string',
    density: 'integer',
    density_comments: 'string',
    compaction: 'integer',
    compaction_comments: 'string',
    strength: 'integer',
    strength_comments: 'string',
    description: 'string',
    d0: 'number',
    d1: 'number',
    pr1: 'integer',
    pr2: 'integer',
    pr3: 'integer',
    comments: 'string',
    comment: 'string',
    date: 'date',
    text: 'string',
    specific: 'integer',
    struct_text_thaw: 'integer',
    struct_texts_thaw_weight: 'integer',
    ord_num: 'integer',
    num: 'string',
    date_front: 'date',
    field_number: 'integer',
    sample_type: 'integer',
    selection_from_d: 'number',
    selection_to_d: 'number',
    status: 'integer',
    excav_type_tool: 'integer',
    excav_type_casing: 'integer',
    excav_type_backfill: 'integer',
    excav_type: 'string',
    lon_plan: 'number',
    lon: 'number',
    lat_plan: 'number',
    lat: 'number',
    abs: 'number',
    abs_plan: 'number',
    h: 'number',
    h_plan: 'number',
    diam1: 'integer',
    diam2: 'integer',
    date_from: 'date',
    date_to: 'date',
    geologist_short_name: 'string',
    geologist: 'integer',
    bore_machine_title: 'string',
    bore_machine: 'integer',
    bore_master_title: 'string',
    bore_master: 'integer',
    geomorph_id: 'integer',
    geomorph_title: 'string',
    geomorph_comments: 'string',
    natural_f: 'string',
    artificial_f: 'string',
    type: 'integer',
    archived: 'boolean',
    site: 'string',
    client: 'integer',
    client_title: 'string',
    contractor: 'integer',
    contractor_title: 'string',
    date_start: 'date',
    date_end: 'date',
    geologists: 'integer',
    bore_masters: 'integer',
    bore_machines: 'integer',
    level_d: 'number',
    fixed_d: 'number',
    appear_date: 'date',
    fixed_date: 'date',
    foot_d0: 'number',
    foot_d1: 'number',
    alteration_comments: 'string',
    alteration_fractures_comments: 'string',
    spelled: 'boolean',
    stamp: 'boolean',
    is_admin: 'boolean',
    email: 'string',
    first_name: 'string',
    last_name: 'string',
    debris_round: 'integer',
    debris_composition: 'integer',
    debris_comments: 'string',
    ice_content_comments: 'string',
    soil_type_comments: 'string',
    processes_comments: 'string',
    exposures: 'string',
    processes: 'array',
    geobotany: 'string',
    polls: 'string',
    waterfronts: 'string',
    inspections: 'string',
    location: 'string',
    office_tasks: 'string',
    office_notes: 'string',
    sync_status: 'number',
    sync_progress: 'array'
  },
  correctFalseValue: {
    number: null,
    integer: null,
    string: '',
    date: null,
    boolean: false
  }
}

export const parseFieldTypes = (data) => {
  for (const key in data) {
    if (key === 'type') {
      data[key] = data[key] || 101
    } else {
      const type = fieldsTypeConfig.attrs[key]

      if (!data[key] && data[key] !== 0) {
        data[key] = type ? fieldsTypeConfig.correctFalseValue[type] : null
      } else if (type === 'date') {
        parseDateFields(data, key)
      }
    }
  }
}

const parseDateFields = (data, key) => {
  const dateExclusions = ['date_from', 'date_to', 'date_end', 'date_start']
  const value = dateExclusions?.includes(key)
    ? ritmDate(data[key]).format('YYYY-MM-DD')
    : ritmDate(data[key]).format('iso')

  data[key] = value
}
