<script setup>
import { useRitmDate } from '@/composables'
import { computed } from 'vue'

const props = defineProps({
  type: {
    type: String,
    default: 'video'
  },
  title: {
    type: String,
    default: 'видео'
  },
  metadata: {
    type: Object,
    required: true
  },
  activeItem: {
    type: Object,
    required: true
  }
})

const $rDate = useRitmDate()

const metadataMap = {
  fps: 'Кадры / сек',
  size: 'Размер',
  width: 'Ширина',
  height: 'Высота',
  bitrate: 'Битрейт',
  duration: 'Длительность',
  original_location: 'Геотег',
  original_time: 'Дата создания',
  coords: 'Геотег',
  date_front: 'Дата создания'
}

const getValue = (key, value) => {
  switch (key) {
    case 'size':
      return `${(props.metadata.size / (1024 * 1024)).toFixed(2)} МБ`
    case 'duration':
      return `${props.metadata.duration.toFixed(1)} с`
    case 'bitrate':
      return `${(props.metadata.bitrate / 1024).toFixed(0)} КБ/с`
    case 'fps':
      return `${props.metadata.fps.toFixed(0)}`
    case 'original_time':
      return `${$rDate(props.metadata.original_time).format('DD-MM-YYYY')}`
    case 'rotation':
      return

    default:
      return value || 'Отсутствует'
  }
}

const deviceMetadata = computed(() => {
  const field = `${props.type}_coords`

  return {
    coords: props.activeItem[field],
    date_front: `${$rDate(props.activeItem.date_front).format('DD-MM-YYYY')}`
  }
})
</script>

<template>
  <div class="metadata-viewer" v-if="metadata">
    <div class="metadata-viewer__title">
      <s-title type="small">Параметры {{ title }}</s-title>
    </div>
    <div class="metadata-viewer__table-wrapper">
      <div class="metadata-viewer__table">
        <template v-for="(value, key) in metadata" :key="key">
          <div v-if="metadataMap[key]" class="metadata-viewer__table-row">
            <div class="metadata-viewer__table-cell metadata-viewer__table-key">
              <s-text type="placeholder">{{ metadataMap[key] }}</s-text>
            </div>
            <div class="metadata-viewer__table-cell metadata-viewer__table-value">
              <s-text>{{ getValue(key, value) }}</s-text>
            </div>
          </div>
        </template>
      </div>
      <div class="metadata-viewer__column">
        <s-title type="small"> Устройство </s-title>
        <div class="metadata-viewer__table">
          <div class="metadata-viewer__table-row" v-for="(value, key) in deviceMetadata" :key="key">
            <div class="metadata-viewer__table-cell metadata-viewer__table-key">
              <s-text type="placeholder">{{ metadataMap[key] }}</s-text>
            </div>
            <div class="metadata-viewer__table-cell metadata-viewer__table-value">
              <s-text>{{ getValue(key, value) }}</s-text>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.metadata-viewer {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &__column {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__table {
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    border: 1px solid var(--bg);

    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    &-row {
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid var(--bg);

      &:first-child {
        border-radius: var(--border-radius) var(--border-radius) 0 0;
      }

      &:last-child {
        border-radius: 0 0 var(--border-radius) var(--border-radius);
        border-bottom: none;
      }
    }

    &-cell {
      flex: 1;
    }

    &-key,
    &-value {
      padding: 8px;
    }

    &-key {
      color: var(--placeholder);
      border-right: 1px solid var(--bg);
    }

    &-value {
      color: var(--text);
    }
  }
}
</style>
