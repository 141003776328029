import components from './components'

export default (app) => {
  components.forEach(({ name, type, component }) => {
    switch (type) {
      case 'plugin':
        app.use(component)
        break
      case 'directive':
        app.directive(name.toLowerCase(), component)
        break
      default:
        app.component(name, component)
        break
    }
  })
}
