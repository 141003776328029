import { useServicesStore } from '@/stores/services'
import { useMainStore } from '@/stores/main'
import { useRequests } from '@/composables'
import clonedeep from 'lodash.clonedeep'
import db from '@/libs/db'
import appGlobalProperties from '@/main'

const services = [
  'bore_machines',
  'bore_masters',
  'clients',
  'contractors',
  'companies',
  'positions',
  'users'
]

const getDicts = async (store) => {
  const { getRequest } = useRequests()

  const dicts = await getRequest('dicts/')
  Object.keys(dicts).forEach((e) => {
    store.setService([e, dicts[e]])
  })
}

const getMetadata = async (store) => {
  const { getRequest } = useRequests()

  await Promise.all(
    services.map(async (e) => {
      const response = await getRequest(`${e}/`)

      if (e === 'users') {
        store.setService([
          e,
          response.map((e) => {
            e.title = e.short_name || e.username

            return e
          })
        ])
      } else {
        store.setService([e, response])
      }
    })
  ).catch((e) => {
    throw new Error(e)
  })
}

export const getServices = async () => {
  const mainStore = useMainStore()
  const servicesStore = useServicesStore()
  if (mainStore.isOnline) {
    try {
      await getDicts(servicesStore)
      await getMetadata(servicesStore)
    } catch (e) {
      const message = `Произошла ошибка при инициализации приложения. ${e}`
      appGlobalProperties.$notify({
        message,
        type: 'error'
      })
    } finally {
      const storeData = clonedeep(JSON.stringify(servicesStore.$state))
      const response = await db.dictionary.toArray()
      if (!response.length) {
        await db.dictionary.add({
          services: storeData
        })
      } else {
        await db.dictionary.put({
          id: response[0]?.id,
          services: storeData
        })
      }
    }
  } else {
    const idbData = await db.dictionary.toArray()
    if (!idbData.length) return
    const services = JSON.parse(idbData[0]?.services)

    Object.keys(services).forEach((e) => {
      servicesStore.setService([e, services[e]])
    })
  }
}
