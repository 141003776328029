import axios from 'axios'
import * as Sentry from '@sentry/vue'
import posthog from 'posthog-js'

import { API_HOST, POSTHOG_TOKEN } from '@/configs/posthog'
import { BASE_URL } from '@/configs/urls'

import { useMainStore } from '@/stores/main'

const { MODE, VITE_APP_RELEASE_VERSION } = import.meta.env

export default async (app) => {
  const mainStore = useMainStore()

  try {
    const { data } = await axios.get(`${BASE_URL}/api/config`)
    const {
      sentry_url,
      deploy_env,
      sentry_deployment_tag_name,
      sentry_deployment_id,
      license,
      max_users,
      max_upload_size,
      posthog_enabled,
      version,
      app_config
    } = data

    mainStore.setLicenseAndUsersCount(license, max_users)
    mainStore.setMaxUploadSize(max_upload_size)
    mainStore.setAppConfig(app_config || {})

    if (version) {
      mainStore.setVersion(version)
    }

    if (MODE === 'development') return

    // SENTRY INIT
    if (sentry_url) {
      Sentry.init({
        app,
        dsn: sentry_url,
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: ['localhost', /^\//]
          }),
          Sentry.replayIntegration()
        ],
        tracesSampleRate: 1.0,
        environment: deploy_env,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: VITE_APP_RELEASE_VERSION || window.location.host
      })

      Sentry.setTag(sentry_deployment_tag_name, sentry_deployment_id)
    }

    // POSTHOG INIT
    if (posthog_enabled) {
      posthog.init(POSTHOG_TOKEN, {
        api_host: API_HOST
      })

      app.config.globalProperties.$posthog = posthog
    }
  } catch (error) {
    console.error('Не удалось загрузить конфиг и запустить сервисы мониторинга', error)
  }
}
