<script setup>
import { computed, ref, toRaw } from 'vue'
import { useMainStore } from '@/stores/main'
import { checkIsWrongExtension, showErrorNotify } from '@/utils/files'

import SAttachmentsVideo from '@/components/s-attachments-video/s-attachments-video.vue'

const props = defineProps({
  videos: {
    type: Array,
    required: true
  },
  files: {
    type: Array,
    required: true
  },
  activeId: {
    type: [Number, String],
    default: null
  }
})

const emits = defineEmits(['change', 'changed-video-files'])

const mainStore = useMainStore()

const additionalText = 'Рекомендуется загружать видео в форматах mp4, mov'
const ACCEPTED_VIDEO_FORMATS = ['mp4', 'mov']

const MAX_UPLOAD_VIDEO_SIZE_BYTES = mainStore.maxUploadSize
const MAX_UPLOAD_VIDEO_SIZE_MB = MAX_UPLOAD_VIDEO_SIZE_BYTES / (1024 * 1024)

const changeActiveCollapse = (id) => {
  emits('change', id)
}

const loading = ref(false)

const initFiles = ref(props.files || [])
const initVideos = ref(props.videos || [])
const tempList = ref([])

const allVideos = computed(() => {
  return [...initVideos.value, ...initFiles.value] || []
})

const hasFiles = computed(() => !!allVideos.value.length)

const handleFiles = async (_, update = false) => {
  if (!uploadRef.value) return

  loading.value = true

  const { files } = uploadRef.value
  const fileList = [...files]

  if (!update) {
    const edittedFiles = fileList.reduce((acc, file) => {
      const fileExtension = file.name.split('.').pop()
      const isWrongExtension = checkIsWrongExtension(ACCEPTED_VIDEO_FORMATS, fileExtension)

      if (isWrongExtension) {
        const message = `Недопустимый формат файла. ${file.name}`
        showErrorNotify(message)
        return acc
      }

      if (MAX_UPLOAD_VIDEO_SIZE_BYTES < file.size) {
        const message = `Размер файла превышает допустимый лимит. Пожалуйста, загрузите файл размером не более ${MAX_UPLOAD_VIDEO_SIZE_MB}MB. ${file.name}`
        showErrorNotify(message)
        return acc
      }

      const newFile = new File([file], `${Date.now()}-${file.name}`, { type: file.type })
      acc.push(newFile)
      return acc
    }, [])

    if (edittedFiles.length === 0) {
      loading.value = false
      uploadRef.value.value = ''
      return
    }

    const dt = new DataTransfer()
    const allFiles = [...tempList.value, ...edittedFiles]

    tempList.value = allFiles.filter((f, i, a) => {
      return a.findIndex((v) => v.name === f.name) === i
    })

    tempList.value.forEach((file) => dt.items.add(file))

    uploadRef.value.files = dt.files
  } else {
    tempList.value = fileList
  }

  const newSource = tempList.value.map((file) => ({
    title: file.name,
    file,
    preview: URL.createObjectURL(file)
  }))

  newSource.forEach((n) => {
    const index = initFiles.value.findIndex((is) => {
      return toRaw(is).title === toRaw(n).title
    })
    if (index === -1) {
      initFiles.value.push(n)
    }
  })

  initFiles.value = initFiles.value.filter((n) => newSource.some((is) => is.title === n.title))
  emits('changed-video-files', initFiles.value)
  loading.value = false
}

const remove = (file) => {
  if (file.id) {
    const index = initVideos.value.findIndex((f) => f.id === file.id)
    initVideos.value.splice(index, 1)
  } else {
    const index = tempList.value.findIndex((f) => f.title === file.title)
    tempList.value.splice(index, 1)
    const dt = new DataTransfer()
    tempList.value.forEach((f) => dt.items.add(f))
    uploadRef.value.files = dt.files

    handleFiles('', true)
  }
}

const uploadRef = ref(null)

const openUploader = () => {
  uploadRef.value.click()
}
</script>

<template>
  <s-collapse-item
    isBeta
    @change="changeActiveCollapse"
    :active="activeId"
    id="videos"
    title="Видеоматериалы"
  >
    <template v-slot:header-stats>
      <s-text> {{ videos.length }}</s-text>
    </template>
    <div v-if="hasFiles" class="attachments-list">
      <s-attachments-video @remove="remove" type="editor" :items="allVideos" />
    </div>
    <div>
      <div v-if="!loading" class="attachments-uploader" @click.stop="openUploader">
        <input
          id="attachments-support"
          ref="uploadRef"
          type="file"
          accept=".mp4,.mov"
          :multiple="true"
          @change="handleFiles"
        />
        <s-button v-if="!hasFiles" class="attachments-button" stretch>
          <s-div>
            <s-title type="small"> Загрузка видео </s-title>
            <div class="attachments-button__text">
              <s-text type="secondary" wrap center> Добавьте видео с вашего устройства </s-text>
              <s-text v-if="additionalText" type="secondary" wrap center>
                {{ additionalText }}
              </s-text>
            </div>
          </s-div>
        </s-button>
        <s-button v-else stretch> Добавить видео </s-button>
      </div>
    </div>
  </s-collapse-item>
</template>

<style lang="scss" scoped>
.attachments-uploader {
  & > .s-upload-container {
    display: none;
  }
}
</style>
