<script setup>
import { computed, ref } from 'vue'
import { useServicesStore } from '@/stores/services'
import { soilType } from '../../helpers'
import { schliereValidator } from '../../validator'
import useVuelidate from '@vuelidate/core'

import SWikiTip from '@/components/s-wiki-tip/s-wiki-tip.vue'
import SInputRenderer from '@/components/s-input-renderer/s-input-renderer.vue'
import ErrorValidatorText from '@/components/error-validator-text.vue'

const props = defineProps({
  source: {
    type: Object,
    required: true
  },
  field: {
    type: Object,
    required: true
  }
})

const initSource = ref(props.source)

const servicesStore = useServicesStore()

const services = computed(() => {
  let soilId = soilType(props.source)?.id
  let soilClass = soilType(props.source)?.soil_class
  let soilGroup = soilType(props.source)?.soil_group
  let soilTypeLocal = soilType(props.source)?.soil_type

  let service
  let fillerWaterSaturation

  if (props.field.service === 'filler_saturations') {
    const fillerType = servicesStore?.soil?.fillers_types?.find((e) => {
      return e.id === initSource.value.filler_type
    })
    const apiName = fillerType?.api_dict
    fillerWaterSaturation = fillerType?.filler_water_saturation

    service = servicesStore?.soil?.[apiName]

    soilId = fillerType?.id
    soilClass = fillerType?.soil_class
    soilGroup = fillerType?.soil_group
    soilTypeLocal = fillerType?.soil_type
  } else {
    service = servicesStore?.soil?.[props.field.service]
  }

  return {
    [props.field.service]:
      service?.filter((s) => {
        if (props.field.filterValue) {
          if (props.field.filterValue?.includes(';')) {
            const [from, to] = props.field.filterValue.split(';')

            return s.id >= Number(from) && s.id <= Number(to)
          } else {
            return props.field.filterValue?.includes(s.id)
          }
        }

        if (!s.soil_type && !s.soil_group && !s.soil_class && !s.soil_id) {
          return true
        }

        if (fillerWaterSaturation) {
          return s.filler_water_saturation === fillerWaterSaturation
        }

        const suits = s.soil_type
          ? s.soil_class === soilClass &&
            s.soil_group === soilGroup &&
            s.soil_type === soilTypeLocal
          : s.soil_group
            ? s.soil_class === soilClass && s.soil_group === soilGroup
            : s.soil_class
              ? s.soil_class === soilClass
              : false

        if (s.soil_id?.includes(';')) {
          const [from, to] = s.soil_id.split(';')

          const between = soilId >= Number(from) && soilId <= Number(to)

          return (between || suits) && s.id
        } else {
          return (s.soil_id?.includes(String(soilId)) || suits) && s.id
        }
      }) || []
  }
})

const wikiTip = {
  color: 'placeholder',
  title: 'Шлиры',
  description:
    'Включения льда в мерзлом грунте, представленные прожилками, прослойками, линзами разной ориентировки, образующие криогенные текстуры. Максимальная толщина шлиров условно принимается равной 0.3-0.5 м; при большей толщине образования следует называть ледяными пластами или линзами, которые уже не входят в понятие криогенной текстуры',
  source:
    'Рекомендации по методике изучения подземных льдов и криогенного строения многолетнемерзлых грунтов, 1986'
}

const rules = computed(() => {
  return {
    ...schliereValidator(initSource.value)
  }
})

const v$ = useVuelidate(rules, initSource)
</script>

<template>
  <div class="soil-select" v-if="services?.[field?.service]?.length">
    <s-input-renderer :field="field" :source="source" :services="services" />
  </div>
  <div
    class="create-guide-text-pfrost__item"
    v-if="field.id === 'text_pfrost' && !field.rock && initSource[field.id]"
  >
    <div class="create-guide-text-pfrost__item-title">
      <s-title type="small"> Укажите информацию о шлирах </s-title>
      <s-wiki-tip :data="wikiTip">
        <s-icon name="fa-circle-question" :color="`var(--${wikiTip.color})`" />
      </s-wiki-tip>
    </div>
    <s-text> Ширина шлиров, мм </s-text>
    <div class="create-guide-text-pfrost__item-row">
      <div class="create-guide-text-pfrost__item-input">
        <s-number-input
          v-model="initSource.ice_schliere_width_from"
          :no-blur="true"
          :no-between-error="true"
          placeholder="От"
        />
        <error-validator-text
          v-if="v$?.ice_schliere_width_from?.$error"
          :errorText="v$?.ice_schliere_width_from.$errors[0].$message"
        />
      </div>
      <s-text class="create-guide-text-pfrost__item-dash">-</s-text>

      <div class="create-guide-text-pfrost__item-input">
        <s-number-input
          v-model="initSource.ice_schliere_width_to"
          :no-blur="true"
          :no-between-error="true"
          placeholder="До"
        />
        <error-validator-text
          v-if="v$?.ice_schliere_width_to?.$error"
          :errorText="v$?.ice_schliere_width_to.$errors[0].$message"
        />
      </div>
    </div>
    <s-text> Расстояние между шлирами, мм </s-text>
    <div class="create-guide-text-pfrost__item-row">
      <div class="create-guide-text-pfrost__item-input">
        <s-number-input
          v-model="initSource.ice_schliere_interval_from"
          :no-blur="true"
          :no-between-error="true"
          placeholder="От"
        />
        <error-validator-text
          v-if="v$?.ice_schliere_interval_from?.$error"
          :errorText="v$?.ice_schliere_interval_from.$errors[0].$message"
        />
      </div>

      <s-text class="create-guide-text-pfrost__item-dash">–</s-text>

      <div class="create-guide-text-pfrost__item-input">
        <s-number-input
          v-model="initSource.ice_schliere_interval_to"
          :no-blur="true"
          :no-between-error="true"
          placeholder="До"
        />
        <error-validator-text
          v-if="v$?.ice_schliere_interval_to?.$error"
          :errorText="v$?.ice_schliere_interval_to.$errors[0].$message"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.create-guide-text-pfrost {
  &__item {
    &-title {
      display: flex;
      justify-content: space-between;
    }

    &-input {
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
    }

    &-row {
      position: relative;
    }

    &-dash {
      position: absolute;
      top: 20px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.soil-select {
  display: grid;
  grid-gap: 1rem;
}
</style>
