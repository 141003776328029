<script setup>
import { computed } from 'vue'

const props = defineProps({
  color: {
    type: String,
    validator: (value) => ['primary', 'text', 'caption'].includes(value),
    default: 'primary'
  }
})

const colorValue = computed(() => `var(--${props.color})`)
</script>

<template>
  <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle class="outer-circle" cx="16" cy="16" r="15" stroke-width="2" :stroke="colorValue" />
    <circle class="inner-circle" cx="16.0001" cy="16.0001" r="6.4" :fill="colorValue" />
  </svg>
</template>
