<script setup>
import { onBeforeRouteLeave, RouterView } from 'vue-router'
import { useMainStore } from '@/stores/main'

onBeforeRouteLeave((_, from, next) => {
  const mainStore = useMainStore()

  mainStore.setLastRoutePath(['lastObjectPath', from.fullPath])
  next()
})
</script>

<template>
  <router-view />
</template>

<style></style>
